import { Row, Col, Form, Button, Tabs, Tab } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";
import Base from "Views/base.js";
import CalendarHoc from "./Calendar/CalendarHoc";
import Preloader from "Common/Preloder/Preloader";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Formik } from "formik";
import { withRouter, Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { MultiSelect } from "react-multi-select-component";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  displayMiddleName,
  urlActions,
  getValidDateStringForGraphQL,
  concatAllErrors,
} from "Common/helpers";
import {
  myAppointmentDetailsVar,
  appFromCalVar,
  eventsDataVar,
  patientBadgesVar,
  displayLoaderVar,
} from "cache/cache";
import SelectPatientModal from "Views/SearchForPatient/selectPatientModal";
import { useTranslation } from "react-i18next";
import { useReactiveVar, useMutation } from "@apollo/client";
import { appLanguageVar } from "cache/cache";
import { urlBackend } from "index";
import * as Sentry from "@sentry/browser";

export const REQUEST_DOCTOR = gql`
  query {
    doctors(active: true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          doctorId
          arabicName
          unavailableDates {
            edges {
              node {
                startTime
                endTime
                date
                leaveRequest {
                  description
                  leaveType
                }
              }
            }
          }
          recurringEvents {
            edges {
              node {
                startDate
                startTime
                endTime
                title
                description
                everyday
                days
                dateUntil
              }
            }
          }
          eventType {
            edges {
              node {
                id
                title {
                  name
                }
                duration
                buffer
                price
                eventType {
                  id
                  name
                }
                eventLocation {
                  id
                  title
                }
              }
            }
          }
          availability {
            edges {
              node {
                id
                day
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MY_APPOINTMENT_DETAILS = gql`
  query {
    myAppointmentDetails @client
  }
`;
const REQUEST_LOCATION = gql`
  query {
    listEventLocation {
      edges {
        node {
          title
          id
          titleEn
        }
      }
    }
  }
`;
const REQUEST_EVENTS = gql`
  query (
    $doctors: [String]
    $search: String
    $patient_Identifier: String
    $status: String
    $active: Boolean!
    $location: ID
    $start: Date
    $end: Date
  ) {
    events(
      doctors: $doctors
      search: $search
      patient_Identifier: $patient_Identifier
      status: $status
      active: $active
      location_Id_Icontains: $location
      start: $start
      end: $end
    ) {
      edges {
        node {
          title
          id
          start
          end
          identifier
          groupStartDate
          groupEndDate
          groupDays
          children {
            edges {
              node {
                id
                start
                end
                eventId
              }
            }
          }
          doctorEventType {
            groupSession

            color {
              hexCode
            }
          }
          doctor {
            id
            firstName
            lastName
            arabicName
            identifier
          }
        }
      }
    }
  }
`;

const REQUEST_PATIENT_EVENTS = gql`
  query (
    $doctors: [String]
    $search: String
    $patient_Identifier: String
    $status: String
    $active: Boolean!
    $location: ID
    $start: Date
    $end: Date
  ) {
    patientAppointments(
      doctors: $doctors
      search: $search
      patient_Identifier: $patient_Identifier
      status: $status
      active: $active
      location_Id_Icontains: $location
      start: $start
      end: $end
    ) {
      edges {
        node {
          title
          id
          start
          end
          identifier
          groupStartDate
          groupEndDate
          groupDays
          payments {
            edges {
              node {
                paid
                amount
              }
            }
          }
          children {
            edges {
              node {
                id
                start
                end
                eventId
              }
            }
          }
          doctorEventType {
            groupSession

            color {
              hexCode
            }
          }
          doctor {
            id
            firstName
            lastName
            arabicName
            identifier
          }
        }
      }
    }
  }
`;

function MyAppointments(props) {
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { i18n, t } = useTranslation();
  const { data } = useQuery(USER_DETAILS);
  const fullCalendarRef = useRef();
  var userDetails = data.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }
  const isStaff = isUserStaff();
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const searchPatientRef = useRef();
  const [selectedPatientFullName, setSelectedPatientFullName] = useState("");
  const [radio, setRadio] = useState("new");
  const [showPatientSearch, setShowPatientSearch] = useState(false);
  // const [calendarActiveMonth, setCalendarActiveMonth] = useState(1);
  const [forceFilterApply, setForceFilterApply] = useState(false); // just a state used to rerender / trigger useeffect
  const [dateStart, setDateStart] = useState(new Date());
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [active, setActive] = useState(!isStaff ? true : false);
  const [variablesToBeFetched, setVariablesToBeFetched] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedPatientFileNumber, setSelectedPatientFileNumber] =
    useState("");
  const [appointmets, setAppointments] = useState([]);
  const [selectedDocList, setSelectedDocList] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const appLanguage = useReactiveVar(appLanguageVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const [patientDataObj, setPatientDataObj] = useState(null);
  const [applyDoctorList, setApplyDoctorList] = React.useState([]);
  const patientBadgesObject = useReactiveVar(patientBadgesVar);

  const [GetMyAppointments, { loading: myAppointmentDetailsLoading }] =
    useLazyQuery(MY_APPOINTMENT_DETAILS, {
      fetchPolicy: "network-only",
      onCompleted: ({ myAppointmentDetails }) => {
        let appDetailsToAccess = myAppointmentDetails;
        if (
          appDetailsToAccess.selectedDocList ||
          appDetailsToAccess.selectedPatientFullName ||
          appDetailsToAccess.selectedStatus ||
          appDetailsToAccess.forceFilterApply
        ) {
          setForceFilterApply(false);
          // setSelectedDoctorIdentifier(appDetailsToAccess.selectedDoctorIdentifier);
          if (appDetailsToAccess.selectedPatientFullName) {
            setSelectedPatientFullName(
              appDetailsToAccess.selectedPatientFullName
            );
            formikRef.current.setFieldValue(
              "patient",
              appDetailsToAccess.selectedPatientFullName
            );
          }
          // formikRef.current.setFieldValue('doctor', appDetailsToAccess.selectedDocList);

          setPatientDataObj(appDetailsToAccess.patientDataObj);
          setSelectedStatus(appDetailsToAccess.selectedStatus);

          // if (appDetailsToAccess.selectedStatus === "active") {
          //   setActive(true);
          // }
          // else {
          //   setActive(false);
          // }

          let variables = {};
          let dataToPass = appFromCal ? appFromCal : {};

          if (appDetailsToAccess?.patientDataObj) {
            dataToPass["patientData"] = appDetailsToAccess.patientDataObj;
            setPatientDataObj(appDetailsToAccess.patientDataObj);
            variables["patient_Identifier"] =
              appDetailsToAccess.patientDataObj.identifier;
          } else {
            dataToPass["patientData"] = "";
            variables["patient_Identifier"] = "";
          }
          if (appDetailsToAccess.selectedDocList?.length > 0) {
            setSelectedDocList(
              docOptions.filter((i) =>
                appDetailsToAccess.selectedDocList.some(
                  (j) => j.doctorId === i.doctorId
                )
              )
            );
            dataToPass["doctors"] = appDetailsToAccess.selectedDocList.map(
              (i) => i.doctorId + ""
            );
            variables["doctors"] = appDetailsToAccess.selectedDocList.map(
              (i) => i.doctorId + ""
            );
          }

          if (appDetailsToAccess.selectedStatus === "") {
            setActive(false);
            variables["status"] = "";
            variables["active"] = true;
          } else if (appDetailsToAccess.selectedStatus === "cancelled") {
            setActive(false);
            variables["status"] = "cancelled";
            variables["active"] = false;
          } else if (appDetailsToAccess.selectedStatus === "active") {
            setActive(true);
            variables["status"] = "";
            variables["active"] = true;
          }

          appFromCalVar(dataToPass);
          let calendarView = fullCalendarRef?.current?.getApi()?.view;
          if (calendarView) {
            let dtSt = new Date(calendarView.activeStart);
            let dtEn = new Date(calendarView.activeEnd);
            dtEn.setDate(dtEn.getDate() - 1);
            setDateStart(dtSt);
            setDateEnd(dtEn);
            if (dtSt && dtEn) {
              variables["start"] = getValidDateStringForGraphQL(dtSt);
              variables["end"] = getValidDateStringForGraphQL(dtEn);
            }

            setVariablesToBeFetched(variables);
          }
        }
      },
      onError: ({ networkError, message }, e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("GetMyAppointments error " + networkError);
        }
        if (message) {
          openSnackbar(message, [SNACK_DURATION]);
        }
        if (networkError.result) {
          openSnackbar(
            networkError.result?.errors.map((error, index) => {
              return error.message;
            }),
            [SNACK_DURATION]
          );
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    });

  const defaultStrings = {
    selectSomeItems: t("multiSelectDoc.select"),
    search: t("multiSelectDoc.search"),
  };

  const { data: doctorsList } = useQuery(REQUEST_DOCTOR, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    onError: ({ networkError }, e) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", networkError?.statusCode);
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + networkError);
      }
      if (networkError.result) {
        openSnackbar(
          networkError.result?.errors.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const GENERTE_EVENT_PRINT_CODE = gql`
    mutation eventPrintCode($generate: Boolean) {
      eventPrintCode(generate: $generate) {
        code
      }
    }
  `;

  const REQUEST_PATIENT_BADGES = gql`
    query {
      patientBadges
    }
  `;

  const [getPatientBadges, { data: patientBadges }] = useLazyQuery(
    REQUEST_PATIENT_BADGES,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ patientBadges }) => {
        patientBadgesVar(patientBadges);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_BADGES error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const POST_PATIENTS_EVENTS_READ = gql`
    mutation eventReadView($events: [ID]!) {
      eventReadView(input: { events: $events }) {
        updated
      }
    }
  `;

  // EVENT PRINT CODE
  const [postEventReadView] = useMutation(POST_PATIENTS_EVENTS_READ, {
    onCompleted: ({ eventReadView }) => {
      getPatientBadges();
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("POST_PATIENTS_EVENTS_READ error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // EVENT PRINT CODE
  const [generateEventPrintCode] = useMutation(GENERTE_EVENT_PRINT_CODE, {
    onCompleted: ({ eventPrintCode }) => {
      let oneTimeCode = eventPrintCode.code;
      let doctorArrStr = urlActions(window.location.href, "get", "doctor");
      let status = urlActions(window.location.href, "get", "status");
      let patientId = urlActions(window.location.href, "get", "patientId");
      let docListArr = doctorArrStr.length > 0 ? doctorArrStr?.split("__") : [];
      let calendarView = fullCalendarRef.current?.getApi()?.view;
      let date = getValidDateStringForGraphQL(calendarView.currentStart);
      let urlForEventPrint =
        urlBackend + "pdf/events/" + oneTimeCode + "/?date=" + date;
      if (docListArr.length > 0) {
        urlForEventPrint += "&doctors=" + docListArr.join(",");
      }
      if (patientId) {
        urlForEventPrint += "&patient=" + patientId;
      }
      if (status === "no_show" || status === "cancelled") {
        urlForEventPrint += "&status=" + status;
      } else if (status) {
        urlForEventPrint += "&status=" + status + "&active=" + true;
      } else {
        urlForEventPrint += "&active=" + true;
      }
      setTimeout(() => {
        window.open(urlForEventPrint);
      }, 300);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("GENERTE_EVENT_PRINT_CODE error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  var EVENT_REQUEST_QUERY = isStaff ? REQUEST_EVENTS : REQUEST_PATIENT_EVENTS;

  const [
    GetEvents,
    { data: eventsList, loading: eventsLoading, startPolling, stopPolling },
  ] = useLazyQuery(EVENT_REQUEST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      active:
        selectedStatus === "no_show" || selectedStatus === "cancelled"
          ? false
          : true,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 16000,
    onCompleted: (data) => {
      setAppointments(data.patientAppointments.edges.map((i) => i.node));
      setPageLoading(false);
    },
    onError: ({ networkError }, e) => {
      Sentry.setContext("error", networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENTS error " + networkError);
      }
      if (networkError?.result) {
        openSnackbar(
          networkError.result?.errors.map((error, index) => {
            return error.message;
          }),
          [SNACK_DURATION]
        );
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });
  useEffect(() => {
    GetEvents({
      variables: {
        start: getValidDateStringForGraphQL(new Date()),
        end: getValidDateStringForGraphQL(
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        ),
        active: true,
      },
    });
  }, []);
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    displayLoaderVar(true);
    GetMyAppointments();
    // Specify how to clean up after this effect:
    return () => {
      if (props?.history?.location?.pathname?.indexOf("/appointment") < 0) {
        myAppointmentDetailsVar({});
      }
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    if (userDetails?.patient && !patientDataObj && fullCalendarRef?.current) {
      onSelectPatient(userDetails?.patient, fullCalendarRef);
    }
  }, [userDetails, patientDataObj, fullCalendarRef]);

  const onFocus = () => {
    if (startPolling) {
      startPolling(16000);
    }
  };
  const onBlur = () => {
    if (stopPolling) {
      stopPolling();
    }
  };

  const doctors =
    doctorsList && doctorsList?.doctors ? doctorsList?.doctors?.edges : [];

  const onSelectPatient = (patientData, fullCalendarRefFromParam) => {
    formikRef.current.setFieldValue(
      "patient",
      `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
        patientData.lastName
      }`
    );
    let fullCalendarView =
      fullCalendarRefFromParam?.current?.getApi()?.view?.type;
    let newUrlParams = urlActions(
      window.location.href,
      "set",
      "calendarInitView",
      fullCalendarView
    );

    if (patientData) {
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "patient",
        patientData.identifier
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "patient_name",
        `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
          patientData.lastName
        }`
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "patientId",
        `${patientData.patientId}`
      );
    } else {
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "delete",
        "patient"
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "delete",
        "patient_name"
      );
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "delete",
        "patientId"
      );
    }
    window.history.pushState("", "", "?" + newUrlParams);

    setSelectedPatientFullName(
      `${patientData.firstName} ${displayMiddleName(patientData.middleName)}${
        patientData.lastName
      }`
    );
    setSelectedPatientFileNumber(`${patientData.identifier}`);
    setPatientDataObj(patientData);
    let dataToPass = appFromCal ? appFromCal : {};
    dataToPass["patientData"] = patientData;
    appFromCalVar(dataToPass);
  };

  const { data: location = [] } = useQuery(REQUEST_LOCATION, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENTS error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (
      active ||
      selectedStatus ||
      selectedDocList ||
      selectedPatientFullName ||
      forceFilterApply
    ) {
      // let docObj = doctors.find(i=>i.node.identifier===selectedDoctorIdentifier);

      let myAppDetailsObj = {
        selectedDocList,
        selectedPatientFullName,
        selectedStatus,
        // docObj,
        patientDataObj,
        active,
        forceFilterApply,
      };
      myAppointmentDetailsVar(myAppDetailsObj);
    }
    // For each change in filters, this useeffect is triggered which saves the selected filter in cache (myAppointmentDetailsVar) which automatically triggers GetMyAppointments lazy query. in the oncompleted, getEvents with correct values is triggered.
  }, [
    selectedPatientFullName,
    selectedDocList,
    selectedStatus,
    patientDataObj,
    active,
    forceFilterApply,
  ]);

  useEffect(() => {
    if (active) {
      if (isStaff) {
        setSelectedStatus("active");
      }
    }
  }, [active]);

  // Create a USEEFFECT that will check if the eventslist is done loading, and set PAGE_LOADER to FALSE, only for the first time.
  useEffect(() => {
    if (!eventsLoading && firstTime) {
      setPageLoading(false);
      setFirstTime(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsLoading]);

  const initialValues = {
    doctor: "",
    patient: "",
    status: "",
  };

  let fullCalendarView = fullCalendarRef?.current?.getApi()?.view;
  let fullCalendarViewTitle = fullCalendarView?.title;

  useEffect(() => {
    if (fullCalendarView) {
      let newUrlParams = urlActions(
        window.location.href,
        "set",
        "calendarInitView",
        fullCalendarView.type
      );
      let dateToPush = new Date(fullCalendarView.activeStart);
      dateToPush.setDate(dateToPush.getDate() + 1);
      newUrlParams = urlActions(
        window.location.protocol +
          "//" +
          window.location.host +
          "?" +
          newUrlParams,
        "set",
        "calendarInitDate",
        dateToPush.toISOString().split("T")[0]
      );
      window.history.pushState("", "", "?" + newUrlParams);
    }
  }, [fullCalendarView, fullCalendarViewTitle]);

  const locationFilterChanged = (e) => {
    setSelectedLocation(e.target.value);
    let newUrlParams = "";
    if (e.target.value) {
      let locationParams = location.listEventLocation.edges.find(
        (i) => i.node.id === e.target.value
      );
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "location",
        locationParams?.node?.titleEn
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "location");
    }
    window.history.pushState("", "", "?" + newUrlParams);
  };

  const handleChangeStatus = (e) => {
    let newUrlParams = "";
    if (e.target.value) {
      newUrlParams = urlActions(
        window.location.href,
        "set",
        "status",
        e.target.value
      );
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "status");
    }
    window.history.pushState("", "", "?" + newUrlParams);

    if (e.target.value) {
      setSelectedStatus(e.target.value);
      if (e.target.value === "active") {
        setActive(true);
      } else {
        setActive(false);
      }
    } else {
      setSelectedStatus("");
      setActive(false);
      setForceFilterApply(true); // incase all selectedstatus and active is set back to default, the get events from myappVar is not triggered so we call the getevents here incase there is no select value passed
      // GetEvents();
    }
  };

  // Event Status
  const eventStatus = {
    pending: t("status.pending"),
    confirmed: t("status.confirmed"),
    no_show: t("status.noShow"),
    cancelled: t("status.cancelled"),
    completed: t("status.completed"),
  };
  var is_my_appointments = false;
  var has_add_permission = getPermissionForAction("appointment", "add");
  const createNewApp = () => {
    localStorage.setItem("appointment_flow", true);
  };
  const resetPatient = () => {
    formikRef.current.setFieldValue("patient", "");
    setSelectedPatientFullName("");
    setSelectedPatientFileNumber("");
    setPatientDataObj(null);
    let dataToPass = appFromCal ? appFromCal : {};
    dataToPass["patientData"] = null;
    appFromCalVar(dataToPass);
    setForceFilterApply(true);
    let newUrlParams = urlActions(window.location.href, "delete", "patient");
    newUrlParams = urlActions(
      window.location.protocol +
        "//" +
        window.location.host +
        "?" +
        newUrlParams,
      "delete",
      "patient_name"
    );
    newUrlParams = urlActions(
      window.location.protocol +
        "//" +
        window.location.host +
        "?" +
        newUrlParams,
      "delete",
      "patientId"
    );
    window.history.pushState("", "", "?" + newUrlParams);
  };

  const getDocAsResources = () => {
    if (isStaff) {
      return doctors.map((doctor) => {
        return {
          id: doctor.node.id,
          doctorId: doctor.node.doctorId,
          title: doctor.node.firstName + " " + doctor.node.lastName,
          identifier: doctor.node.identifier,
        };
      });
    } else {
      return [];
    }
  };
  function getDaysUntilAppointment(appointmentDate) {
    if (!appointmentDate) return 0;

    const currentDate = new Date();
    const appointment = new Date(appointmentDate);

    // Set both dates to midnight to avoid time differences within a day
    currentDate.setHours(0, 0, 0, 0);
    appointment.setHours(0, 0, 0, 0);

    const differenceInTime = appointment.getTime() - currentDate.getTime();

    // Calculate the difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
  }
  var docOptions = doctors?.map((doctor) => {
    return {
      label:
        i18n.language === "en"
          ? doctor.node.firstName + " " + doctor.node.lastName
          : doctor.node.arabicName,
      value: doctor.node.identifier,
      doctorId: doctor.node.doctorId,
    };
  });
  docOptions = docOptions.sort((a, b) => a?.label?.localeCompare(b?.label));

  if (docOptions.length === 1) {
    docOptions.push({
      label: t("multiSelectDoc.selectDoc"),
      value: "0",
      placeholder: "",
      disabled: true,
      doctorId: "00",
    });
  }

  const handleChangeDoctor = (selectedDoc) => {
    let docs = [];
    docs.push(...selectedDoc);
    setSelectedDocList(docs);
    let newUrlParams = window.location.search;
    if (docs.length > 0) {
      let doc_list = docs.map((i) => i.doctorId + "").join("__");
      if (doc_list) {
        newUrlParams = urlActions(
          window.location.href,
          "set",
          "doctor",
          doc_list
        );
      }
    } else {
      newUrlParams = urlActions(window.location.href, "delete", "doctor");
    }
    window.history.pushState("", "", "?" + newUrlParams);
  };

  useEffect(() => {
    let doctorArrStr = urlActions(window.location.href, "get", "doctor");
    let status = urlActions(window.location.href, "get", "status");
    let patient_name = urlActions(window.location.href, "get", "patient_name");
    let patient = urlActions(window.location.href, "get", "patient");
    let locationParamUrl = urlActions(window.location.href, "get", "location");
    let locationParams = location?.listEventLocation?.edges?.find(
      (i) => i?.node?.titleEn === locationParamUrl
    );
    setSelectedLocation(locationParams?.node?.id);
    let docListArr = doctorArrStr?.split("__");
    let docArr = docOptions.filter((i) =>
      docListArr.some((j) => j === i.doctorId + "")
    );
    if (docArr.length > 0) {
      setSelectedDocList(docArr);
    }
    if (status) {
      formikRef.current.setFieldValue("status", status);
      setSelectedStatus(status);
    }
    if (patient_name) {
      formikRef.current.setFieldValue("patient", patient_name);
      setSelectedPatientFileNumber(patient);
    }
    if (
      docListArr.length === 1 &&
      docListArr[0] === "" &&
      docOptions.length === 2
    ) {
      let selDoc = docOptions.find((i) => !i.disabled);

      if (selDoc) {
        handleChangeDoctor([selDoc]);
      }
    }
    if (doctors.length > 0) {
      applyFilterToEvents();
    }
  }, [formikRef, doctors, location]);

  const printDayView = () => {
    let variables = {
      generate: true,
    };
    generateEventPrintCode({ variables });
  };

  const applyFilterToEvents = () => {
    displayLoaderVar(true);
    let allVarsToBeFetched = variablesToBeFetched;
    let locationFromUrl = urlActions(window.location.href, "get", "location");
    let selectedLocationId = location?.listEventLocation?.edges?.find(
      (i) => i?.node?.titleEn === locationFromUrl
    );
    if (selectedLocationId) {
      selectedLocationId = selectedLocationId?.node?.id;
      allVarsToBeFetched["location"] = selectedLocationId;
    }
    // GetEvents({ variables: allVarsToBeFetched });
    let doctorArr = allVarsToBeFetched?.doctors;
    if (doctorArr) {
      let doctorObjArr = doctors?.filter((i) =>
        doctorArr.some((j) => i?.node?.doctorId + "" === j + "")
      );
      let doctorSelectedArr = doctorObjArr?.map((doc) => {
        return {
          label: doc.node.firstName + doc.node.lastName,
          value: doc.node.identifier,
          doctorId: doc.node.doctorId,
        };
      });
      setApplyDoctorList(doctorSelectedArr);
    } else {
      setApplyDoctorList([]);
    }
  };
  const renderAppointmentDetails = (appointments) => {
    return appointments.map((appointment, index) => (
      <Col className="mb-4" key={index} xs={12} sm={12} md={6} lg={4} xl={4}>
        <div
          className="card"
          style={{
            height: "10rem",
            backgroundColor: "white",
            border: 0,
          }}
        >
          <div className="card-body">
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5
                className="card-title"
                style={{
                  color: "#0096FF",
                }}
              >
                {i18n.language === "en"
                  ? `In ${getDaysUntilAppointment(appointment?.start)} days`
                  : `بعد ${getDaysUntilAppointment(appointment?.start)} يوم`}
              </h5>
              <div>
                {new Date(appointment?.start).toLocaleDateString(
                  i18n.language === "en" ? "en-US" : "ar-EG",
                  {
                    month: "short",
                    day: "numeric",
                  }
                )}
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted">
              {t("with")}{" "}
              {i18n.language == "en"
                ? "Dr. " +
                  appointment.doctor.firstName +
                  " " +
                  appointment.doctor.lastName
                : appointment.doctor.arabicName}
            </h6>
            <p className="card-text">{appointment?.title?.split("-")[1]}</p>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a href="#" className="card-link">
                <b>
                  {appointment?.payments.edges[0]?.node?.amount}{" "}
                  {i18n.language == "en" ? "KWD" : "دينار "}
                </b>

                {appointment?.payments.edges[0]?.node?.paid ? (
                  <></>
                ) : (
                  <cite
                    style={{
                      color: "#BDBDBD",
                      marginLeft: "10px",
                    }}
                    title="Source Title"
                  >
                    {t("patientDashboard.unpaidAppointemnt")}
                  </cite>
                )}
              </a>
              <Link
                to={`/patientPortal/appointment/detail/${appointment?.id}`}
                className="card-link"
              >
                <button
                  style={{
                    backgroundColor: "white",
                    borderRadius: "40%",
                    padding: "5px",
                    color: "#0096FF",
                    marginBottom: "40px",
                    border: 0,
                  }}
                >
                  <i className="fa fa-arrow-right "></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    ));
  };
  return (
    <Base
      title={
        is_my_appointments
          ? " My Appointments "
          : t("patientAppointments.appointments")
      }
      showHeader={true}
      isPatientPortal={!isStaff}
      rightChild={
        <>
          {fullCalendarRef?.current?.getApi()?.view?.type ===
          "resourceTimeGridDay" ? (
            <Button
              variant="primary"
              className="back mx-2 mb-2 create-group-app-btn"
              onClick={() =>
                printDayView(fullCalendarRef?.current?.getApi()?.view)
              }
            >
              {" "}
              <i className="fa fa-print mr-2" aria-hidden="true"></i> Print{" "}
            </Button>
          ) : null}
          <Link
            to={
              has_add_permission && isStaff
                ? "/search/patient"
                : "/patientPortal/create/appointment"
            }
            className="create-app-btn"
          >
            {!isStaff || (has_add_permission && isStaff) ? (
              <Button
                variant="primary"
                className="back mb-2 mr-2"
                onClick={createNewApp}
              >
                {" "}
                <i
                  className="fa fa-plus-circle mr-2"
                  aria-hidden="true"
                ></i>{" "}
                {t("patientAppointments.newAppointment")}{" "}
              </Button>
            ) : null}
          </Link>
          {has_add_permission && isStaff ? (
            <Link to={"/create/appointment/?group=true"}>
              {!isStaff || (has_add_permission && isStaff) ? (
                <Button
                  variant="primary"
                  className="back mb-2 create-group-app-btn"
                  onClick={createNewApp}
                >
                  {" "}
                  <i
                    className="fa fa-plus-circle mr-2"
                    aria-hidden="true"
                  ></i>{" "}
                  {t("patientAppointments.newGroupAppointment")}{" "}
                </Button>
              ) : null}
            </Link>
          ) : null}
        </>
      }
    >
      <Row className="mt-3">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mx-auto mb-2"
          style={{ minHeight: "114vh" }}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ values, handleChange }) => (
              <Form autoComplete="off" className="mb-4">
                <Row className="filter-block">
                  {!is_my_appointments ? (
                    <Col
                      md={12}
                      xl={4}
                      className={appLanguage === "en" ? "ml-auto" : "mr-auto"}
                    >
                      <Form.Group
                        as={Row}
                        className={
                          "multiSelectContainer " +
                          "docOptions_" +
                          docOptions.length
                        }
                      >
                        <Form.Label
                          column
                          sm={3}
                          md={3}
                          xl={4}
                          className="text-left"
                        >
                          {t("patientAppointments.practitioner")}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <MultiSelect
                            options={docOptions}
                            value={selectedDocList}
                            required
                            ClearIcon=""
                            hasSelectAll={false}
                            placeholder="{t('patientAppointments.selectPractitioner')}"
                            onChange={(doc) => {
                              handleChangeDoctor(doc);
                            }}
                            labelledBy="Select"
                            overrideStrings={defaultStrings}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  ) : null}
                  {isStaff ? (
                    <Col md={12} xl={4}>
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}>
                          {t("patientAppointments.patient")}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8} ref={searchPatientRef}>
                          <Form.Control
                            placeholder="Select Patient"
                            autoComplete="off"
                            type="text"
                            name="patient"
                            // disabled={is_my_appointments?false: (selectedDoctorIdentifier ? false : true)}
                            value={values.patient}
                            onChange={handleChange}
                            className="mr-sm-2 pr-0 float-left pr-5"
                          />
                          <span
                            onClick={() => setShowPatientSearch(true)}
                            className="search-icon"
                          >
                            <i className="fa fa-search"></i>
                          </span>
                        </Col>
                        <Col md={12} className="d-flex justify-content-end">
                          <Button
                            variant="link"
                            className="back fontSmall"
                            onClick={resetPatient}
                          >
                            Clear Patient
                          </Button>
                        </Col>
                      </Form.Group>
                    </Col>
                  ) : null}
                  <Col md={12} xl={4}>
                    {isStaff ? (
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}>
                          Status
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="status"
                            // disabled={is_my_appointments?false: (selectedDoctorIdentifier ? false : true)}
                            value={selectedStatus}
                            onChange={handleChangeStatus}
                          >
                            <option value="">Select Status</option>
                            {Object.entries(eventStatus).map(
                              ([key, value], index) => {
                                return (
                                  <option value={key} key={index}>
                                    {value}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    ) : (
                      <Form.Group as={Row} className="text-left text-xl-right">
                        <Form.Label column sm={3} md={3} xl={4}>
                          {t("patientAppointments.location")}
                        </Form.Label>
                        <Col sm={12} md={9} xl={8}>
                          <Form.Control
                            autoComplete="off"
                            as="select"
                            name="location"
                            // disabled={is_my_appointments?false: (selectedDoctorIdentifier ? false : true)}
                            value={selectedLocation}
                            onChange={(e) => locationFilterChanged(e)}
                          >
                            <option value="">
                              {t("patientAppointments.selectLocation")}
                            </option>
                            {location && location.listEventLocation
                              ? location.listEventLocation?.edges.map(
                                  (item, index) => {
                                    return (
                                      <option
                                        value={item.node.id}
                                        key={item.node.titleEn}
                                      >
                                        {item.node.title}
                                      </option>
                                    );
                                  }
                                )
                              : null}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    )}
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={applyFilterToEvents}
                        type="button"
                        variant="primary"
                      >
                        {" "}
                        {t("createEventForm.submit")}{" "}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <div
            className="btn-group"
            role="group"
            aria-label="Basic radio toggle button group"
            onChange={(e) => {
              let k = e.target.value;
              setAppointments([]);
              if (k === "new") {
                setRadio("new");
                GetEvents({
                  variables: {
                    start: getValidDateStringForGraphQL(new Date()),
                    end: getValidDateStringForGraphQL(
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() + 1)
                      )
                    ),
                    active: true,
                  },
                });
              } else {
                setRadio("previous");
                GetEvents({
                  variables: {
                    start: getValidDateStringForGraphQL(
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 1)
                      )
                    ),
                    end: getValidDateStringForGraphQL(new Date()),
                    active: false,
                  },
                });
              }
            }}
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              autocomplete="off"
              value="new"
            />
            <label
              className={
                "btn  " +
                (radio === "new" ? "btn-primary" : "btn-outline-primary")
              }
              for="btnradio1"
            >
              {t("patientDashboard.upcomingAppointments")}
            </label>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
              autocomplete="off"
              value="previous"
            />
            <label
              className={
                "btn btn-outline-primary " +
                (radio === "previous" ? "btn-primary" : "btn-outline-primary")
              }
              for="btnradio2"
            >
              {t("patientDashboard.previousAppointments")}
            </label>
          </div>

          <Row spacing={3}>{renderAppointmentDetails(appointmets)}</Row>
        </Col>
      </Row>
      <SelectPatientModal
        showPatientSearch={showPatientSearch}
        setShowPatientSearch={setShowPatientSearch}
        onSelectPatientFunc={onSelectPatient}
      />
    </Base>
  );
}
export default withRouter(MyAppointments);
