import React, { useState } from "react";
import style from "./LoginForm.module.css";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { isLoggedInVar, userDetailsVar } from "../../../cache/cache.js";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../../Common/helpers.js";
import { useReactiveVar } from "@apollo/client";
import { appLanguageVar, onBoardingToFillVar } from "../../../cache/cache";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

const LoginForm = (props) => {
  const { setLangChangeLoader } = props;
  const [openSnackbarError] = useSnackbar(error_options);
  const appLanguage = useReactiveVar(appLanguageVar);
  const [isUsername, setIsUsername] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [submitToken, setSubmitToken] = useState(false);

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  //=======================LOGIN==========================//

  const LOGIN_EMAIL = gql`
    mutation tokenAuth($email: String!, $password: String!, $otpToken: String) {
      tokenAuth(
        input: { email: $email, password: $password, otpToken: $otpToken }
      ) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          email
          username
          isStaff
          verified
          pk
          isSuperuser
          leaveRequests {
            edges {
              node {
                id
                startDate
                endDate
                totalDays
                status
                leaveType
                created
                description
                user {
                  firstName
                  lastName
                  phone
                  id
                  hr {
                    position {
                      name
                    }
                    department {
                      name
                    }
                    numberAnnualLeaves
                    consumedSickLeaves
                    remainingAnnualLeaves
                  }
                }
              }
            }
          }
          canEditAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          canViewAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          doctor {
            id
            identifier
            doctorId
          }
          patient {
            firstName
            middleName
            lastName
            id
            identifier
            email
            civilId
            displayGender
            phone
            address
            profileComplete
            discount
            patientId
            intakeForms {
              edges {
                node {
                  dynamicForm {
                    patientOnboarding
                  }
                  id
                  submitted
                }
              }
            }
          }
        }
      }
    }
  `;

  const LOGIN_USERNAME = gql`
    mutation tokenAuth(
      $username: String!
      $password: String!
      $otpToken: String
    ) {
      tokenAuth(
        input: { username: $username, password: $password, otpToken: $otpToken }
      ) {
        success
        errors
        unarchiving
        token
        refreshToken
        unarchiving
        user {
          id
          username
          isStaff
          pk
          leaveRequests {
            edges {
              node {
                id
                startDate
                endDate
                totalDays
                status
                leaveType
                created
                description
                user {
                  firstName
                  lastName
                  phone
                  id
                  hr {
                    position {
                      name
                    }
                    department {
                      name
                    }
                    numberAnnualLeaves
                    consumedSickLeaves
                    remainingAnnualLeaves
                  }
                }
              }
            }
          }
          canEditAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          canViewAppointments {
            edges {
              node {
                identifier
              }
            }
          }
          patient {
            firstName
            middleName
            lastName
            id
            identifier
            email
            civilId
            displayGender
            phone
            address
            profileComplete
            discount
          }
        }
      }
    }
  `;

  const LOGIN = isUsername ? LOGIN_USERNAME : LOGIN_EMAIL;
  const [tokenAuth, { loading }] = useMutation(LOGIN, {
    onCompleted({ tokenAuth }) {
      if (tokenAuth.success === false) {
        setEmailValue("");
        setPasswordValue("");
        let errors = tokenAuth?.errors.nonFieldErrors;
        setSubmitToken(false);
        for (let i in errors) {
          let error = errors[i];
          openSnackbarError(error.message, [SNACK_DURATION]);
        }
      } else if (tokenAuth.success === true) {
        setSubmitToken(true);
        const { history } = props;
        localStorage.setItem("token", tokenAuth.token);
        localStorage.setItem("refreshToken", tokenAuth.refreshToken);
        localStorage.setItem("user", JSON.stringify(tokenAuth.user));
        const loginTokenExpiry = new Date(Date.now() + 1000 * 5 * 60);
        localStorage.setItem("loginTokenExpiry", loginTokenExpiry);
        const newExpiration = new Date(Date.now() + 1000 * 19 * 60);
        localStorage.setItem("sessionExpiration", newExpiration);
        userDetailsVar(JSON.stringify(tokenAuth.user));
        isLoggedInVar(true);
        if (tokenAuth.user.isStaff) {
          localStorage.setItem("is_staff", true);
          if (appLanguage === "ar") {
            setLangChangeLoader(true);
            document.body.lang = "en";
            document.body.dir = "ltr";
            i18n.changeLanguage("en");
            localStorage.setItem("appLanguage", "en");
            setTimeout(() => {
              window.location = window.location.href;
            }, 500);
          }
          if (
            props.location &&
            props.location.state &&
            props.location.state.from
          ) {
            history.push(props.location.state.from.pathname);
          } else {
            history.push("/dashboard");
          }
        } else if (!tokenAuth.user.isStaff) {
          localStorage.setItem("isPatientPortal", true);
          if (
            tokenAuth.user.patient &&
            tokenAuth.user.patient.profileComplete
          ) {
            const ONBOARDING_FORM_TO_FILL = tokenAuth.user.patient.intakeForms
              ? tokenAuth.user.patient.intakeForms.edges.find(
                  (i) =>
                    i.node.dynamicForm.patientOnboarding && !i.node.submitted
                )
              : null;
            if (ONBOARDING_FORM_TO_FILL) {
              onBoardingToFillVar(true);
              props.history.push(
                "/patientPortal/intakeform/" + ONBOARDING_FORM_TO_FILL.node.id
              );
            } else {
              if (
                props.location &&
                props.location.state &&
                props.location.state.from
              ) {
                history.push(props.location.state.from.pathname);
              } else {
                history.push("/patientPortal/dashboard");
              }
            }
          } else {
            history.push("/registration/complete/form");
          }
          localStorage.setItem("is_staff", false);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      // Sentry.setContext('ERROR CODE statusCode ', {statusCode:{code:e?.networkError?.statusCode}});
      Sentry.setTag(
        "ERROR CODE statusCode",
        { code: e?.networkError?.statusCode } + ""
      );
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("tokenAuth error " + e);
      }
      setSubmitToken(false);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbarError(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const initialValues = {
    email: "",
    password: "",
    otpToken: "",
  };

  const isEmpty = (str) => {
    return !str || str.length === 0;
  };

  const onSubmit = (values, { resetForm }) => {
    if (isEmpty(emailValue.trim())) {
      alert("Email required.");
      return;
    }
    if (isEmpty(passwordValue.trim())) {
      alert("Password required.");
      return;
    }

    let token = {};
    if (values.otpToken) {
      token = {
        otpToken: values.otpToken,
      };
    }

    if (window.location.hostname.startsWith("patient") || submitToken) {
      if (emailValue.includes("@")) {
        // tokenAuth({ variables: { email: values.email, password: values.password, ...token } })
        tokenAuth({
          variables: { email: emailValue, password: passwordValue, ...token },
        });
      } else {
        setIsUsername(true);
        setTimeout(() => {
          // tokenAuth({ variables: { username: values.email, password: values.password, ...token } })
          tokenAuth({
            variables: { email: emailValue, password: passwordValue, ...token },
          });
        }, 300);
      }
    } else {
      setSubmitToken(true);
    }

    resetForm({ values: "" });
  };

  // const validate = (values) => {
  //   let errors = {};
  //   if (!values.username) {
  //     errors.username = 'Please enter a email.';
  //   }
  //   else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)){
  //     errors.username = 'Please enter a valid email.';
  //   }
  //   if (!values.password) {
  //     errors.password = 'Please enter a password.';
  //   }
  //   return errors;
  // };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const handleChangeEmail = (event) => {
    setEmailValue(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPasswordValue(event.target.value);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Col xs={12} md={11} lg={11}>
        <div className={!submitToken ? "" : "d-none"}>
          <Form.Group as={Row} className="mb-4">
            <Form.Label
              column
              xs={12}
              sm={3}
              className={"text-white text-right"}
            >
              {t("patientLogin.email")}
            </Form.Label>
            <Col xs={12} sm={9}>
              <Form.Control
                type="text"
                name="email"
                // onChange={formik.handleChange}
                // value={formik.values.email}
                onChange={handleChangeEmail}
                value={emailValue}
                autoComplete="username"
                id="register-login-email-test"
                // required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              xs={12}
              sm={3}
              className={"text-white text-right"}
            >
              {t("patientLogin.password")}
            </Form.Label>
            <Col xs={12} sm={9}>
              <Form.Control
                autoComplete="new-password"
                type="password"
                name="password"
                id="register-login-password-test"
                // onChange={formik.handleChange}
                // value={formik.values.password}
                onChange={handleChangePassword}
                value={passwordValue}
                // required
              />
            </Col>
          </Form.Group>
          <Button
            variant="link"
            onClick={() => props.history.push("/register")}
            block
            className="forgot_password"
            id="register-test"
          >
            {" "}
            {t("patientLogin.register")}
          </Button>
          <Button
            variant="link"
            onClick={() => props.history.push("/forgot/password")}
            block
            className="forgot_password"
            id="register-forgot-pass"
          >
            {" "}
            {t("patientLogin.forgotPassword")}
          </Button>
          <Form.Group as={Row}></Form.Group>
        </div>
        <div className={submitToken ? "" : "d-none"}>
          <Form.Group as={Row} className="mb-4">
            <Form.Label
              column
              xs={12}
              sm={3}
              className={"text-white text-right"}
            >
              {t("patientLogin.otpForm")}
            </Form.Label>
            <Col xs={12} sm={9}>
              <Form.Control
                type="text"
                name="otpToken"
                onChange={formik.handleChange}
                value={formik.values.otpToken}
                autoComplete="username"
                // required
              />
            </Col>
          </Form.Group>
        </div>

        <Form.Group as={Row}>
          <Col className={style.login_form__submit}>
            <Button
              disabled={loading}
              className="login_form__button"
              type="submit"
              id="register-login-submit-test"
            >
              {loading ? "Loading ..." : t("patientLogin.submit")}
            </Button>
          </Col>
        </Form.Group>
      </Col>
    </Form>
  );
};

export default withRouter(LoginForm);
