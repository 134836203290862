import React, { useState, useRef, useEffect } from "react";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import CreateEventForm from "./CreateEventForm";
import Preloader from "../../../Common/Preloder/Preloader";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  isUserStaff,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
} from "../../../Common/helpers.js";
import { withRouter } from "react-router-dom";
import {
  dataPatientInVar,
  dateEventInVar,
  potentialAppointmentSlotVar,
} from "../../../cache/cache";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../../../Common/helpers.js";

const CREATE_EVENT = gql`
  mutation createEvent(
    $doctor: ID!
    $patient: ID
    $parent: ID
    $start: DateTime!
    $title: String!
    $description: String
    $doctorEventType: ID!
    $require_payment: Boolean!
    $mainSlot: Boolean!
    $overrideValidation: Boolean!
    $discount: Float
    $waitinglist: ID
    $referral: ID
    $shouldBeSeen: DateTime
    # $onlineLink: String
    $slotsAvailable: Int
    $groupStartDate: Date
    $groupEndDate: Date
    $groupDays: [String]
    $blockedDates: String
    $paymentTransferId: String
  ) {
    createEvent(
      input: {
        doctor: $doctor
        patient: $patient
        parent: $parent
        start: $start
        title: $title
        description: $description
        doctorEventType: $doctorEventType
        requirePayment: $require_payment
        mainSlot: $mainSlot
        overrideValidation: $overrideValidation
        discount: $discount
        waitinglist: $waitinglist
        referral: $referral
        shouldBeSeen: $shouldBeSeen
        slotsAvailable: $slotsAvailable
        groupStartDate: $groupStartDate
        groupEndDate: $groupEndDate
        groupDays: $groupDays
        # onlineLink:$onlineLink
        blockedDates: $blockedDates
        paymentTransferId: $paymentTransferId
      }
    ) {
      errors {
        field
        messages
      }
      obj {
        start
        end
        title
        description
        id
        patient {
          id
          identifier
          firstName
          middleName
          lastName
        }
        doctorEventType {
          groupSession
        }
        parent {
          id
        }
        paymentSources {
          edges {
            node {
              paymentLink
            }
          }
        }
      }
    }
  }
`;

export const REQUEST_DOCTOR = gql`
  query {
    doctors(form: true, active: true) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
        }
      }
    }
  }
`;

export const REQUEST_DOCTOR_LEAVES = gql`
  query leaves($doctor_identifier: String!) {
    leaves(doctor_Identifier_Icontains: $doctor_identifier) {
      edges {
        node {
          id
          date
          startTime
          endTime
        }
      }
    }
  }
`;

export const WAITING_LISTS = gql`
  query {
    listWaitinglist {
      edges {
        node {
          id
          name
          priority
        }
      }
    }
  }
`;

export const SERVICE_LIST = gql`
  query {
    services {
      edges {
        node {
          id
          name
          doctorServices(isActive: true) {
            edges {
              node {
                buffer
                id
                title {
                  name
                }
                eventLocation {
                  id
                  title
                }
                eventType {
                  id
                  name
                  color {
                    hexCode
                    name
                    colorId
                  }
                }
                groupSession
                notification {
                  downPaymentNotificationCutoff
                }
                duration
                price
                availability {
                  edges {
                    node {
                      day
                      shifts {
                        edges {
                          node {
                            startTime
                            endTime
                          }
                        }
                      }
                    }
                  }
                }
                doctor {
                  firstName
                  lastName
                  id
                  email
                  identifier

                  recurringEvents {
                    edges {
                      node {
                        startDate
                        startTime
                        endTime
                        title
                        description
                        everyday
                        days
                        dateUntil
                      }
                    }
                  }
                  availability {
                    edges {
                      node {
                        id
                        day
                        shifts {
                          edges {
                            node {
                              startTime
                              endTime
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SELECTED_PATIENT_DATA = gql`
  query receivePatientData {
    dataPatient @client
  }
`;

const REFERRAL_OBJECT = gql`
  query receiveReferralData {
    referralObj @client
  }
`;

const SELECTED_DATE_EVENT = gql`
  query receiveDate {
    dateEvent @client
  }
`;

export const REQUEST_PATIENT = gql`
  query GetPatients($searchTerm: String) {
    patients(searchText_Icontains: $searchTerm) {
      edges {
        node {
          firstName
          middleName
          lastName
          id
          email
          identifier
          phone
        }
      }
    }
  }
`;
export const REQUEST_DOCTORS_FROM_SERVICE = gql`
  query GetPatients($eventType_Title: ID) {
    doctors(eventType_Title: $eventType_Title) {
      edges {
        node {
          firstName
          lastName
          id
          email
          identifier
          eventType {
            edges {
              node {
                id
                title {
                  name
                }
                duration
                buffer
                price
                notification {
                  downPaymentNotificationCutoff
                }
                eventType {
                  id
                  name
                  color {
                    hexCode
                    name
                    colorId
                  }
                }
                eventLocation {
                  id
                  title
                }
                availability {
                  edges {
                    node {
                      day
                      shifts {
                        edges {
                          node {
                            startTime
                            endTime
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          recurringEvents {
            edges {
              node {
                startDate
                startTime
                endTime
                title
                description
                everyday
                days
                dateUntil
              }
            }
          }
          availability {
            edges {
              node {
                id
                day
                shifts {
                  edges {
                    node {
                      startTime
                      endTime
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const CALENDAR_SLOTS = gql`
  query receiveDate {
    calendarSlots @client
  }
`;

const CreateEventFormHoc = (props) => {
  const {
    setSelectedDoctor,
    selectedDoctor,
    setDoctorLeavesList,
    fullCalendarRef,
    setSelectedSession,
    selectedSession,
    history,
    GetEvents,
    setIsAppointmentSubmitting,
    isAppointmentSubmitting,
    settings_obj,
    activeDocShiftArr,
    setReqPrevNextData,
    setActiveDocShiftArr,
  } = props;
  const { data: patientDataOfSearchPage = null } = useQuery(
    SELECTED_PATIENT_DATA
  );
  const { data: referralObjectData = null } = useQuery(REFERRAL_OBJECT);
  const { data: selectedDateEvent = [] } = useQuery(SELECTED_DATE_EVENT);
  const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const formikRef = useRef();
  const isStaff = isUserStaff();
  const [doctorsListFromService, setDoctorsListFromService] = useState({});
  const [sessionName, setSessionName] = useState(null);
  const [doctors, setDoctors] = useState([]);

  const getDoctorsListFromService = (data = null) => {
    if (data?.name) {
      setSessionName(data.name);
      let reduced_list = data?.doctorServices?.edges.map((item, index) => {
        return { node: item.node.doctor };
      });
      let tempDoctors = { doctors: { edges: [] } };
      tempDoctors.doctors["edges"] = [...reduced_list];
      setDoctorsListFromService(tempDoctors);
    }
  };
  const [getDoctors, { data: rawDoctors = [], loading: loadingDoctors }] =
    useLazyQuery(REQUEST_DOCTOR, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setDoctors(deepCopy(data));
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_DOCTOR error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    });

  // sort doctor
  doctors?.doctors?.edges.sort((a, b) =>
    a?.node?.firstName?.localeCompare(b?.node?.firstName)
  );

  const { data: waitingListObj = null } = useQuery(WAITING_LISTS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      Sentry.captureException("WAITING_LISTS error " + e);

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [
    getServices,
    { data: servicesListObj = null, loading: serviceLoading },
  ] = useLazyQuery(SERVICE_LIST, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("SERVICE_LIST error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    if (isStaff) {
      // only get doctors
      getDoctors();
    } else {
      getServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getPatients, { data: patientList }] = useLazyQuery(REQUEST_PATIENT, {
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_PATIENT error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [doctorLeaves] = useLazyQuery(REQUEST_DOCTOR_LEAVES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDoctorLeavesList(data);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR_LEAVES error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  var waitingList = [];
  if (waitingListObj) {
    waitingList = waitingListObj.listWaitinglist.edges;
  }

  var serviceList = [];
  if (servicesListObj) {
    serviceList = servicesListObj.services.edges;
  }

  const [createEvent] = useMutation(CREATE_EVENT, {
    onCompleted: ({ createEvent }) => {
      if (createEvent && createEvent.obj && createEvent.obj.patient) {
        let alert_msg =
          "Appointment created for " +
          createEvent.obj.patient.firstName +
          " " +
          displayMiddleName(createEvent.obj.patient.middleName) +
          createEvent.obj.patient.lastName;
        let sms_error_msg = " but SMS was not sent.";
        if (createEvent?.errors && createEvent?.errors?.length === 1) {
          let sms_error = createEvent?.errors[0].messages[0];
          if (sms_error.indexOf("Insufficient") > -1) {
            alert_msg = alert_msg + sms_error_msg;
          }
        }
        setIsAppointmentSubmitting(false);
        if (isStaff) {
          if (createEvent?.obj?.doctorEventType?.groupSession) {
            if (createEvent.obj?.parent?.id) {
              history.push({
                pathname: "/appointment/detail/" + createEvent.obj.parent.id,
                search: "passDateInURL=" + createEvent.obj.start,
              });
            } else {
              history.push({
                pathname: "/appointment/detail/" + createEvent.obj.id,
                search: "passDateInURL=" + createEvent.obj.start,
              });
            }
          } else {
            let patient_url = "/appointment/detail/" + createEvent.obj.id;
            history.push(patient_url);
          }
        } else {
          if (createEvent.obj.paymentSources.edges.length > 0) {
            window.location.href =
              createEvent.obj.paymentSources.edges[0].node.paymentLink;
          } else {
            history.push("/");
          }
        }
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        if (formikRef && formikRef.current) {
          formikRef.current.handleReset();
        }
        setSelectedPatient([]);
        dataPatientInVar(null);
        dateEventInVar([]);
      } else if (createEvent?.errors?.length === 0) {
        let alert_msg = "Group Appointment Created ";
        openSnackbarSuccess(alert_msg, [SNACK_DURATION]);
        if (createEvent?.obj?.doctorEventType?.groupSession) {
          if (createEvent.obj?.parent?.id) {
            history.push({
              pathname: "/appointment/detail/" + createEvent.obj.parent.id,
              search: "passDateInURL=" + createEvent.obj.start,
            });
          } else {
            history.push({
              pathname: "/appointment/detail/" + createEvent.obj.id,
              search: "passDateInURL=" + createEvent.obj.start,
            });
          }
        } else {
          let patient_url = "/appointment/detail/" + createEvent.obj.id;
          history.push(patient_url);
        }
      } else if (createEvent?.errors && createEvent?.errors?.length > 0) {
        setIsAppointmentSubmitting(false);
        let error_messages_string = getErrorMessage(createEvent?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      }
    },
    onError: (error) => {
      Sentry.setContext("error", error?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: error });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: error?.networkError?.statusCode,
      });
      if (error?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("createEvent error " + error);
      }
      setIsAppointmentSubmitting(false);
      if (error.message) {
        openSnackbar(error.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(error?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [createNewEvent] = useMutation(CREATE_EVENT, {
    onCompleted: ({ createEvent }) => {
      if (createEvent?.errors && createEvent?.errors?.length > 0) {
        setIsAppointmentSubmitting(false);
        let error_messages_string = getErrorMessage(createEvent?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      }
    },
    onError: (error) => {
      Sentry.setContext("error", error?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: error });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: error?.networkError?.statusCode,
      });
      if (error?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("createEvent error " + error);
      }
      setIsAppointmentSubmitting(false);
      if (error.message) {
        openSnackbar(error.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(error?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  useEffect(() => {
    return () => {
      setSelectedPatient(null);
      dataPatientInVar(null);
      dateEventInVar([]);
      potentialAppointmentSlotVar([]);
    };
  }, []);

  if (loadingDoctors) return <Preloader />;
  if (serviceLoading) return <Preloader />;

  return (
    <CreateEventForm
      createEvent={createEvent}
      patientList={patientList}
      doctorList={!isStaff ? doctorsListFromService : doctors}
      setReqPrevNextData={setReqPrevNextData}
      patientDataOfSearchPage={patientDataOfSearchPage}
      selectedDateEvent={selectedDateEvent}
      getPatients={getPatients}
      setSelectedDoctor={setSelectedDoctor}
      selectedDoctor={selectedDoctor}
      doctorLeaves={doctorLeaves}
      fullCalendarRef={fullCalendarRef}
      setSelectedSession={setSelectedSession}
      setSelectedPatient={setSelectedPatient}
      selectedPatient={selectedPatient}
      selectedSession={selectedSession}
      formikRef={formikRef}
      waitingList={waitingList}
      calendarData={calendarData}
      GetEvents={GetEvents}
      serviceList={serviceList}
      getDoctorsListFromService={getDoctorsListFromService}
      referralObjectData={referralObjectData}
      sessionName={sessionName}
      setIsAppointmentSubmitting={setIsAppointmentSubmitting}
      isAppointmentSubmitting={isAppointmentSubmitting}
      settings_obj={settings_obj}
      setActiveDocShiftArr={setActiveDocShiftArr}
      activeDocShiftArr={activeDocShiftArr}
      createNewEvent={createNewEvent}
    />
  );
};

export default withRouter(CreateEventFormHoc);
