import React, { useRef } from "react";
import style from "./LoginForm/LoginForm.module.css";
import { Button, Form, Col, Row } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { isLoggedInVar, userDetailsVar } from "../../cache/cache";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../../Common/helpers.js";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

const RegisterForm = (props) => {
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  const { t } = useTranslation();

  const REGISTER = gql`
    mutation register(
      $firstName: String!
      $lastName: String!
      $middleName: String!
      $email: String!
      $password1: String!
      $password2: String!
      $username: String!
    ) {
      register(
        input: {
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          email: $email
          password1: $password1
          password2: $password2
          username: $username
        }
      ) {
        success
        errors
      }
    }
  `;

  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register.success) {
        // NOTE: since email validation is required we dont sign them in upon registration
        // const { history } = props;
        openSnackbarSuccess(t("patientRegister.successNeedVerifyEmail"));
        formikRef.current.resetForm();
        // localStorage.setItem("token", register.token);
        // localStorage.setItem("refreshToken", register.refreshToken);
        // userDetailsVar(JSON.stringify(register.token));
        // isLoggedInVar(true);
        // if (
        //   props.location &&
        //   props.location.state &&
        //   props.location.state.from
        // ) {
        //   history.push(props.location.state.from.pathname);
        // } else {
        //   history.push("/manage/intake/forms/form");
        // }
      } else {
        let errors = register?.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let emailErrors = errors.email;
        let password1Errors = errors.password1;
        let password2Errors = errors.password2;
        let firstNameErrors = errors.firstName;
        let middleNameErrors = errors.middleName;
        let lastNameErrors = errors.lastName;
        let usernameErrors = errors.username;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in emailErrors) {
          let e = emailErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in firstNameErrors) {
          let e = firstNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in lastNameErrors) {
          let e = lastNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in middleNameErrors) {
          let e = middleNameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in usernameErrors) {
          let e = usernameErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in password1Errors) {
          let e = password1Errors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        if (password2Errors) {
          let passwordError = [];

          for (let i = 0; i < password2Errors.length; i++) {
            passwordError = passwordError + "" + password2Errors[i].message;
          }
          openSnackbarError(passwordError, [SNACK_DURATION]);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("register error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    username: "",
    email: "",
    password1: "",
    password2: "",
  };

  const onSubmit = (values) => {
    register({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        username: values.email, // passing email as the default username for the patients
        email: values.email,
        password1: values.password1,
        password2: values.password2,
      },
    });
  };

  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    value = value.replace(/[^a-z '']/gi, "");
    formikRef.current.setFieldValue(fieldName, value);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      innerRef={formikRef}
    >
      {({ handleSubmit, handleChange, values, handleReset }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.firstName")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="firstName"
                onChange={onChangeEnglishInput}
                value={values.firstName}
                autoComplete="given-name"
                required
                id="firstName-test"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.middleName")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="middleName"
                required
                onChange={onChangeEnglishInput}
                value={values.middleName}
                autoComplete="family-name"
                id="middleName-test"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.lastName")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="text"
                name="lastName"
                onChange={onChangeEnglishInput}
                value={values.lastName}
                autoComplete="family-name"
                required
                id="lastName-test"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.email")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email}
                autoComplete="email"
                required
                id="email-test"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.password")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                name="password1"
                onChange={handleChange}
                value={values.password1}
                autoComplete="new-password"
                required
                id="pass1-test"
              />
              <ol className={"pass-text-white passReq"}>
                {t("passwordRequirement.mustContain")}
                <li>{t("passwordRequirement.8characters")}</li>
                <li>{t("passwordRequirement.1symbol")}</li>
                <li>{t("passwordRequirement.1numeric")}</li>
                <li>{t("passwordRequirement.1uppercase")}</li>
                <li>{t("passwordRequirement.1lowercase")}</li>
              </ol>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={4}
              className={"text-white text-right required-white"}
            >
              {t("patientRegister.confirmPassword")}
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                type="password"
                name="password2"
                onChange={handleChange}
                value={values.password2}
                autoComplete="new-password"
                required
                id="pass2-test"
              />
            </Col>
          </Form.Group>
          <Button
            variant="link"
            block
            className="forgot_password"
            id="register-back-test"
            onClick={() => props.history.push("/")}
          >
            {t("patientRegister.backToLogin")}
          </Button>
          <Form.Group as={Row}></Form.Group>
          <Form.Group as={Row}>
            <Col className={style.login_form__submit}>
              <Button
                disabled={loading}
                className="login_form__button"
                id="register-submit-test"
                type="submit"
              >
                {loading ? "Loading ..." : t("patientRegister.submit")}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(RegisterForm);
