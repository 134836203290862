import Base from "./base.js";
import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Button,
  DropdownButton,
  Dropdown,
  Card,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getDate,
  success_options,
  getErrorMessage,
  concatAllErrors,
  getDateKuwaitFormatted,
  getDateKuwait,
  convertDateKuwaitToStr,
} from "../Common/helpers";
import { Formik } from "formik";
import { useSnackbar } from "react-simple-snackbar";
import { Link } from "react-router-dom";
import CustomModals from "../component/Modal/modal";
import { userDetailsVar } from "../cache/cache.js";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import * as Yup from "yup";
import { deepCopy } from "../Common/helpers";

function UserLeaves(props) {
  const formikRef = useRef();
  // const [startDate, setStartDate] = useState('');
  const [openSnackbar] = useSnackbar(error_options);
  const [addLeaveRequestModal, setAddLeaveRequestModal] = useState(false);
  // const [leaveStart, setLeaveStart] = useState(null);
  const [editLeaveObject, setEditLeaveObject] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [leaveStartDate, setLeaveStartDate] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [leaveEndDate, setLeaveEndDate] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  // const [leaveEnd, setLeaveEnd] = useState(null);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const [startDate1, setStartDate1] = useState("");
  const [endDate1, setEndDate1] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userDetailsData, setUserDetailsData] = useState(null);

  const REQUEST_ACCOUNT_DETAILS = gql`
    query {
      me {
        id
        email
        username
        isStaff
        verified
        pk
        isSuperuser
        hr {
          position {
            name
          }
          department {
            name
          }
          numberAnnualLeaves
          consumedSickLeaves
          remainingAnnualLeaves
          shortLeaveMonthlyCap
          remainingShortLeaves
          consumedShortLeaves
          conferenceLeaves
          remainingConferenceLeaves
          maternityLeaveCount
        }
        leaveRequests {
          edges {
            node {
              id
              startDate
              endDate
              totalDays
              status
              displayStatus
              startTime
              endTime
              leaveType
              displayLeaveType
              created
              description
              approvedOneDate
              approvedTwoDate
              approvedOne
              approvedTwo
              user {
                firstName
                lastName
                phone
                id
                hr {
                  position {
                    name
                  }
                  department {
                    name
                  }
                  numberAnnualLeaves
                  consumedSickLeaves
                  remainingAnnualLeaves
                  shortLeaveMonthlyCap
                  remainingShortLeaves
                  consumedShortLeaves
                }
              }
            }
          }
        }
      }
    }
  `;

  const CANCEL_LEAVE_REQUEST = gql`
    mutation cancelLeaveRequest($id: ID!) {
      cancelLeaveRequest(id: $id) {
        obj {
          created
          id
          startDate
          endDate
          leaveType
          status
          displayStatus
          startTime
          endTime
        }
      }
    }
  `;

  const CREATE_LEAVE_REQUEST = gql`
    mutation createUpdateLeaveRequest(
      $id: ID
      $startDate: Date!
      $endDate: Date!
      $leaveType: String!
      $description: String!
      $startTime: Time
      $endTime: Time
    ) {
      createUpdateLeaveRequest(
        input: {
          id: $id
          startDate: $startDate
          endDate: $endDate
          leaveType: $leaveType
          description: $description
          startTime: $startTime
          endTime: $endTime
        }
      ) {
        obj {
          created
          id
          startDate
          endDate
          startTime
          endTime
          status
          displayStatus
          leaveType
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const [accountDetails] = useLazyQuery(REQUEST_ACCOUNT_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      userDetailsVar(JSON.stringify(data.me));
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_ACCOUNT_DETAILS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [cancelLeaveRequest] = useMutation(CANCEL_LEAVE_REQUEST, {
    onCompleted: ({ cancelLeaveRequest }) => {
      if (
        cancelLeaveRequest?.errors &&
        cancelLeaveRequest?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(cancelLeaveRequest?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        let status = cancelLeaveRequest.obj.displayStatus;
        accountDetails();
        openSnackbarSuccess("Leave Request " + status, [SNACK_DURATION]);
      }
    },

    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("CANCEL_LEAVE_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [createUpdateLeaveRequest] = useMutation(CREATE_LEAVE_REQUEST, {
    onCompleted: ({ createUpdateLeaveRequest }) => {
      if (
        createUpdateLeaveRequest?.errors &&
        createUpdateLeaveRequest?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdateLeaveRequest?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
        setAddLeaveRequestModal(true);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
        setDisableSubmitButton(false);
      } else {
        // eslint-disable-next-line no-lone-blocks
        {
          editLeaveObject
            ? openSnackbarSuccess("Leave Request updated", [SNACK_DURATION])
            : openSnackbarSuccess("Leave Request created", [SNACK_DURATION]);
        }
        accountDetails();
        setAddLeaveRequestModal(false);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
      }
    },

    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("CREATE_LEAVE_REQUEST error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
      setIsSubmitting(false);
    },
  });

  const { data: rawUserDetailsData, loading } = useQuery(
    REQUEST_ACCOUNT_DETAILS,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    setUserDetailsData(deepCopy(rawUserDetailsData));
  }, [rawUserDetailsData]);

  var userDetails = userDetailsData ? userDetailsData?.me : {};

  useEffect(() => {
    if (!addLeaveRequestModal) {
      // formikRef?.current?.handleReset();
      setEditLeaveObject(false);
      setLeaveStartDate(null);
      setLeaveEndDate(null);
      setStartDate1(null);
      setEndDate1(null);
      setDisableSubmitButton(false);
    }
  }, [addLeaveRequestModal, formikRef]);

  const REQUEST_SETTINGS = gql`
    query {
      settings
    }
  `;

  const { data: settings_obj = null } = useQuery(REQUEST_SETTINGS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_SETTINGS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  let leavesObjFilter = userDetails;
  const sickLeaveQuota =
    leavesObjFilter &&
    leavesObjFilter.leaveRequests &&
    leavesObjFilter.leaveRequests.edges &&
    leavesObjFilter.leaveRequests.edges.length > 0
      ? Math.ceil(
          leavesObjFilter.leaveRequests.edges[0].node.user.hr
            .consumedSickLeaves / 6
        )
      : 0;

  const cancelLeaveRequestFunc = (leaveObj) => {
    if (window.confirm("Cancel Leave Request?")) {
      let val = {};
      val["id"] = leaveObj.id;
      cancelLeaveRequest({ variables: val });
    }
  };

  const LeaveTypes = {
    leave: "Annual",
    unpaid: "Unpaid",
    sick: "Sick",
    haj: "Haj",
    maternity: "Maternity",
    conference: "Conference",
    short_leave: "Short Leave",
    half_day: "Half day",
  };

  const getStartTime = (date) => {
    setStartDate1(date);
    setStartTime(
      new Date(date).getHours() +
        ":" +
        String(new Date(date).getMinutes()).padStart(2, "0")
    );
  };
  const getEndTime = (date) => {
    setEndDate1(date);
    setEndTime(
      new Date(date).getHours() +
        ":" +
        String(new Date(date).getMinutes()).padStart(2, "0")
    );
  };

  useEffect(() => {
    if (editLeaveObject) {
      setLeaveStartDate(editLeaveObject?.startDate);
      setLeaveEndDate(editLeaveObject?.endDate);

      if (
        getDate(new Date(editLeaveObject?.startDate)) ===
          getDate(new Date(editLeaveObject?.endDate)) &&
        editLeaveObject.leaveType === "SHORT_LEAVE"
      ) {
        let startT = new Date();
        let strtArr = editLeaveObject?.startTime?.split(":");
        startT.setHours(strtArr[0]);
        startT.setMinutes(strtArr[1]);
        getStartTime(startT);

        let endArr = editLeaveObject?.endTime?.split(":");
        let endT = new Date();
        endT.setHours(endArr[0]);
        endT.setMinutes(endArr[1]);
        getEndTime(endT);
      }
    }
  }, [editLeaveObject]);

  const onSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);

    if (values.leaveType === "half_day") {
      values.endDate = values.startDate;
    }

    let val = {
      startDate: convertDateKuwaitToStr(values.startDate),
      endDate: convertDateKuwaitToStr(values.endDate),
      description: values.description,
      leaveType: values.leaveType,
    };

    if (editLeaveObject && editLeaveObject.id) {
      val["id"] = editLeaveObject.id;
    }
    if (
      getDate(new Date(values.startDate)) ===
        getDate(new Date(values.endDate)) &&
      values.leaveType === "short_leave"
    ) {
      if (startTime && endTime) {
        val["startTime"] = startTime;
        val["endTime"] = endTime;
      }
    }
    if (!disableSubmitButton) {
      createUpdateLeaveRequest({ variables: val });
    }
  };

  const setActionDate = (info, elemRef, fldName) => {
    elemRef.current.setFieldValue(fldName, info);
    if (fldName === "startDate") {
      setLeaveStartDate(info);
      const leaveTypeValue = elemRef.current.values.leaveType;

      if (leaveTypeValue === "half_day") {
        elemRef.current.setFieldValue("endDate", info);
        setLeaveEndDate(info);
      }
    }
    if (fldName === "endDate") {
      setLeaveEndDate(info);
    }
  };

  const setLeaveType = (e, elemRef, fldName) => {
    let leave_type = e.target.value;
    elemRef.current.setFieldValue(fldName, leave_type);
  };

  const editLeaveRequestFunc = (obj) => {
    setEditLeaveObject(obj);
    setAddLeaveRequestModal(true);
  };

  const initialValues = {
    startDate: "",
    endDate: "",
    description: "",
    leaveType: "",
  };

  if (editLeaveObject) {
    initialValues["startDate"] = new Date(editLeaveObject.startDate);
    initialValues["endDate"] = new Date(editLeaveObject.endDate);
    initialValues["leaveType"] = editLeaveObject.leaveType.toLowerCase();
    initialValues["description"] = editLeaveObject.description;

    if (
      getDate(new Date(editLeaveObject.startDate)) ===
        getDate(new Date(editLeaveObject.endDate)) &&
      editLeaveObject.leaveType === "SHORT_LEAVE"
    ) {
      initialValues["startTime"] = startTime;
      initialValues["endTime"] = endTime;
    }
  }

  leavesObjFilter?.leaveRequests?.edges?.sort(
    (a, b) => new Date(b.node.created) - new Date(a.node.created)
  );

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    leaveType: Yup.string().required("Leave Type is required"),
    description: Yup.string().required("Description is required"),
  });

  return (
    <Base
      title={"Annual and Sick Leaves"}
      showHeader={true}
      page_loading={loading}
    >
      <Row>
        <>
          <Col md={3}>
            <Card>
              <Card.Body className="d-flex align-items-center justify-content-center">
                <div>
                  <h6 className="text-center text-uppercase"> ANNUAL LEAVE </h6>
                  <h6 className="textPrimaryBold text-center">
                    {userDetails?.hr?.remainingAnnualLeaves}
                  </h6>
                  <h6 className="text-center"> Days Remaining </h6>
                </div>
              </Card.Body>
            </Card>
            {userDetails?.hr?.maternityLeaveCount > 0 ? (
              <Card>
                <Card.Body className="d-flex align-items-center justify-content-center">
                  <div>
                    <h6 className="text-center text-uppercase">
                      MATERNITY LEAVE
                    </h6>
                    <h6 className="textPrimaryBold text-center">
                      {userDetails?.hr?.maternityLeaveCount}
                    </h6>
                    <h6 className="text-center"> Days Remaining </h6>
                  </div>
                </Card.Body>
              </Card>
            ) : null}
          </Col>
          <Col md={3}>
            {settings_obj && settings_obj.settings ? (
              <Card>
                <Card.Body>
                  <h6 className="text-center text-uppercase"> SICK LEAVE </h6>
                  <h6 className="text-center ">
                    {" "}
                    Consumed:
                    <span className="textPrimaryBold ">
                      {" "}
                      {userDetails?.hr?.consumedSickLeaves}{" "}
                    </span>
                  </h6>
                  <h6
                    className={
                      "text-center " +
                      (sickLeaveQuota === 1 || sickLeaveQuota === 0
                        ? " border_primary "
                        : " ")
                    }
                  >
                    Full Pay:{" "}
                    <span className="textPrimaryBold ">
                      {settings_obj.settings.sick_full_pay}{" "}
                    </span>
                  </h6>
                  <h6
                    className={
                      "text-center" +
                      (sickLeaveQuota === 2 ? " border_primary " : " ")
                    }
                  >
                    3/4 Pay:{" "}
                    <span className="textPrimaryBold ">
                      {settings_obj.settings.sick_three_quaters_pay}{" "}
                    </span>
                  </h6>
                  <h6
                    className={
                      "text-center" +
                      (sickLeaveQuota === 3 ? " border_primary " : " ")
                    }
                  >
                    1/2 Pay:{" "}
                    <span className="textPrimaryBold ">
                      {settings_obj.settings.sick_half_pay}{" "}
                    </span>
                  </h6>
                  <h6
                    className={
                      "text-center" +
                      (sickLeaveQuota === 4 ? " border_primary " : " ")
                    }
                  >
                    1/4 Pay:{" "}
                    <span className="textPrimaryBold ">
                      {settings_obj.settings.sick_one_fourth_pay}{" "}
                    </span>
                  </h6>
                  <h6
                    className={
                      "text-center" +
                      (sickLeaveQuota === 5 ? " border_primary " : " ")
                    }
                  >
                    No Pay:{" "}
                    <span className="textPrimaryBold ">
                      {settings_obj.settings.sick_no_pay}{" "}
                    </span>
                  </h6>
                </Card.Body>
              </Card>
            ) : null}
          </Col>
        </>
        <Col md={3} className="ml-auto ">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              size="md"
              onClick={() => setAddLeaveRequestModal(true)}
              id="request-leave-test"
            >
              Request Leave
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <h6 className="py-3 textGrey">
          <b> REQUESTS </b>
        </h6>
        <Table responsive className=" table-borderless">
          <thead>
            <tr className="patient_table_row tableHeader">
              <th scope="col " className="text-uppercase">
                DATE OF REQUEST
              </th>
              <th scope="col " className="text-uppercase">
                LEAVE TYPE
              </th>
              <th scope="col " className="text-uppercase">
                START DATE
              </th>
              <th scope="col " className="text-uppercase">
                END DATE
              </th>
              <th scope="col " className="text-uppercase text-center">
                STATUS
              </th>
              <th scope="col " className="text-uppercase text-center"></th>
            </tr>
          </thead>
          <tbody>
            {leavesObjFilter &&
            leavesObjFilter.leaveRequests &&
            leavesObjFilter.leaveRequests.edges.length > 0 ? (
              leavesObjFilter.leaveRequests.edges.map((leave, index) => {
                let leaveObj = leave.node;
                return (
                  <tr className="patient_table_row" key={index}>
                    <td>
                      <Link to={"/leave/request/" + leaveObj.id}>
                        {" "}
                        {getDateKuwaitFormatted(leaveObj.created)}{" "}
                      </Link>{" "}
                    </td>
                    <td>
                      {leaveObj.leaveType === "LEAVE"
                        ? "Annual Leave"
                        : leaveObj.displayLeaveType}
                    </td>
                    <td>{getDateKuwaitFormatted(leaveObj.startDate)}</td>
                    <td>{getDateKuwaitFormatted(leaveObj.endDate)}</td>
                    <td className="text-center">
                      {leaveObj.status === "UNAPPROVED"
                        ? "Rejected"
                        : leaveObj.displayStatus}
                    </td>
                    <td className="justify-content-center">
                      <DropdownButton
                        id="dropdown-basic-button"
                        variant="link"
                        title="Manage"
                      >
                        <Dropdown.Item
                          as={Link}
                          to={"/leave/request/" + leaveObj.id}
                        >
                          <Button variant="link" className="px-0">
                            {" "}
                            View{" "}
                          </Button>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => editLeaveRequestFunc(leaveObj)}
                        >
                          <Button variant="link" className="px-0">
                            Edit
                          </Button>
                        </Dropdown.Item>
                        {leaveObj.status === "CANCELLED" ? null : (
                          <>
                            <Dropdown.Item
                              onClick={() => cancelLeaveRequestFunc(leaveObj)}
                            >
                              <Button variant="link" className="px-0">
                                {" "}
                                Cancel{" "}
                              </Button>
                            </Dropdown.Item>
                          </>
                        )}
                      </DropdownButton>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td> No Leave requests made </td>{" "}
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      <CustomModals
        showModal={addLeaveRequestModal}
        modalHeader={"ADD LEAVE REQUEST"}
        setShowModal={setAddLeaveRequestModal}
        dialogClassName="modal60h"
      >
        <Formik
          onSubmit={onSubmit}
          enableReinitialize
          initialValues={initialValues}
          innerRef={formikRef}
          validationSchema={validationSchema} // Add this line
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Row className="mb-5 mt-5">
                <Col xs={12} sm={12} md={12}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3}>
                      <span className=""> Leave Types</span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="select"
                        name="leaveType"
                        value={values.leaveType}
                        onChange={(e) =>
                          setLeaveType(e, formikRef, "leaveType")
                        }
                        required
                        id="leave-type-test"
                      >
                        <option value="">Select Leave Type</option>
                        {Object.entries(LeaveTypes).map(
                          ([key, value], index) => {
                            return (
                              <option value={key} key={index}>
                                {value}
                              </option>
                            );
                          }
                        )}
                      </Form.Control>
                      {errors.leaveType && touched.leaveType && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.leaveType}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={3} md={3}>
                      Start Date
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        selected={
                          values.startDate
                            ? new Date(getDateKuwait(values.startDate))
                            : ""
                        }
                        onChange={(info) =>
                          setActionDate(info, formikRef, "startDate")
                        }
                        name="startDate"
                        className="form-control"
                        autocomplete="off"
                        dateFormat="dd/MMM/yyyy"
                      />
                      {errors.startDate && touched.startDate && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.startDate}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  {/* {values.leaveType !== "half_day" && ( */}
                  <Form.Group
                    as={Row}
                    style={{
                      display: values.leaveType === "half_day" ? "none" : "",
                    }}
                  >
                    <Form.Label column sm={3} md={3}>
                      End Date
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <DatePicker
                        selected={
                          values.endDate
                            ? new Date(getDateKuwait(values.endDate))
                            : ""
                        }
                        onChange={(info) =>
                          setActionDate(info, formikRef, "endDate")
                        }
                        name="endDate"
                        className="form-control"
                        autocomplete="off"
                        dateFormat="dd/MMM/yyyy"
                      />
                      {errors.endDate && touched.endDate && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.endDate}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  {/* )} */}

                  {values.leaveType === "short_leave" ? (
                    <Form.Group as={Row}>
                      <Form.Label column sm={3} md={3}>
                        Start Time
                      </Form.Label>
                      <Col sm={3} md={3}>
                        <DatePicker
                          selected={startDate1 ? startDate1 : ""}
                          onChange={(date) => getStartTime(date)}
                          name="startTime"
                          className="form-control"
                          autocomplete="off"
                          showTimeSelect
                          showTimeSelectOnly
                          timeInterval={30}
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm "
                        />
                      </Col>
                      <Form.Label column sm={3} md={2}>
                        End Time
                      </Form.Label>
                      <Col sm={3} md={3}>
                        <DatePicker
                          selected={endDate1 ? endDate1 : ""}
                          onChange={(date) => getEndTime(date)}
                          name="endTime"
                          className="form-control"
                          autocomplete="off"
                          showTimeSelect
                          showTimeSelectOnly
                          timeInterval={30}
                          timeCaption="Time"
                          dateFormat="HH:mm "
                          timeFormat="HH:mm"
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3}>
                      {"Description"}
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={5}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        id="desc-test"
                      />
                      {errors.description && touched.description && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.description}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  className="d-flex flex-wrap justify-content-center justify-content-sm-between"
                >
                  <Button
                    variant="primary"
                    size="md"
                    className="mb-1"
                    active
                    onClick={() => setAddLeaveRequestModal(false)}
                    id="cancel-leave-test"
                  >
                    CANCEL
                  </Button>
                  {editLeaveObject ? (
                    <Button
                      variant="primary"
                      size="md"
                      className="ml-2 mr-2 mb-1"
                      disabled={isSubmitting}
                      onClick={() => (isSubmitting ? null : handleSubmit())}
                      id="leave-add-test"
                    >
                      {" "}
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      size="md"
                      className="ml-2 mr-2 mb-1"
                      disabled={isSubmitting}
                      onClick={() => (isSubmitting ? null : handleSubmit())}
                      id="leave-add-test"
                    >
                      {" "}
                      {isSubmitting ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Add"
                      )}
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CustomModals>
    </Base>
  );
}
export default UserLeaves;
