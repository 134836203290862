import React from "react";
// import style from './LogoutButton.module.css';
import { useApolloClient } from "@apollo/client";
import {
  isLoggedInVar,
  userDetailsVar,
  userPermissionsVar,
  patientDashboardBadgeObjectVar,
} from "../../../cache/cache";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { dataPatientInVar, appLanguageVar } from "../../../cache/cache.js";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";

const LogoutButton = (props) => {
  const client = useApolloClient();

  const { t, i18n } = useTranslation();

  const REVOKE_TOKEN = gql`
    mutation revokeToken($refreshToken: String!) {
      refreshToken(input: { refreshToken: $refreshToken }) {
        success
        errors
        token
        refreshToken
      }
    }
  `;

  const handleLogout = () => {
    const { history } = props;
    client.cache.evict({ fieldName: "me" });
    client.cache.gc();
    client.clearStore();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_permissions");
    localStorage.removeItem("isPatientPortal");
    localStorage.removeItem("sessionExpiration");
    localStorage.removeItem("loginTokenExpiry");
    isLoggedInVar(false);
    userDetailsVar(null);
    userPermissionsVar(null);
    dataPatientInVar(null);

    setTimeout(() => {
      patientDashboardBadgeObjectVar(null);
    }, 400);
    const refreshToken = localStorage.getItem("refreshToken");

    if (refreshToken) {
      client
        .mutate({
          mutation: REVOKE_TOKEN,
          variables: {
            refreshToken,
          },
        })
        .then((data) => {
          localStorage.removeItem("refreshToken");
          setTimeout(() => {
            localStorage.removeItem("is_staff");
          }, 300);
        })
        .catch((error) => {
          localStorage.removeItem("refreshToken");
          setTimeout(() => {
            localStorage.removeItem("is_staff");
          }, 300);
        });
    } else {
      localStorage.removeItem("refreshToken");
      setTimeout(() => {
        localStorage.removeItem("is_staff");
      }, 300);
    }

    history.push("/");
  };

  return (
    <>
      <Button
        variant="link"
        className="logout-btn d-flex justify-content-start text-uppercase"
        onClick={handleLogout}
      >
        {" "}
        <i className="fa fa-sign-out mx-2 " aria-hidden="true"></i>{" "}
        {t("patientLogoutButton.logout")}
      </Button>
    </>
  );
};

export default withRouter(LogoutButton);
