import { Row, Col, Button, Form, Container } from "react-bootstrap";
import React, { useRef } from "react";
import Base from "./base.js";
import { Formik } from "formik";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  isUserStaff,
  concatAllErrors,
} from "../Common/helpers.js";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

function ChangePassword(props) {
  const isStaff = isUserStaff();
  const [openSnackbarError] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const { t } = useTranslation();

  const formikRef = useRef();

  const CHANGE_PASSWORD = gql`
    mutation passwordChange(
      $oldPassword: String!
      $newPassword1: String!
      $newPassword2: String!
    ) {
      passwordChange(
        input: {
          oldPassword: $oldPassword
          newPassword1: $newPassword1
          newPassword2: $newPassword2
        }
      ) {
        success
        errors
        token
        refreshToken
      }
    }
  `;

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: ({ passwordChange }) => {
      if (passwordChange?.errors) {
        let errors = passwordChange?.errors;
        let nonFieldErrors = errors.nonFieldErrors;
        let newPassword2Errors = errors.newPassword2;
        let oldPasswordErrors = errors.oldPassword;
        for (let i in nonFieldErrors) {
          let e = nonFieldErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in newPassword2Errors) {
          let e = newPassword2Errors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
        for (let i in oldPasswordErrors) {
          let e = oldPasswordErrors[i];
          openSnackbarError(e.message, [SNACK_DURATION]);
        }
      } else if (passwordChange.success) {
        openSnackbarSuccess("Password Changed Successsfully.");
        if (formikRef && formikRef.current) {
          formikRef.current.handleReset();
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("changePassword error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const initialValues = {
    old_password: "",
    new_password1: "",
    new_password2: "",
  };

  const onSubmit = (values, { resetForm }) => {
    let val = {
      oldPassword: values.old_password,
      newPassword1: values.new_password1,
      newPassword2: values.new_password2,
    };

    changePassword({ variables: val });
  };

  return (
    <Base
      title={t("patientChangePassword.changePassword")}
      showHeader={true}
      isPatientPortal={!isStaff}
    >
      <Row>
        <Col xs={12} sm={12} md={7} lg={7} xl={5}>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Container>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0"
                    >
                      <span className="">
                        {t("patientChangePassword.oldPassword")}
                      </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="password"
                        name="old_password"
                        value={values.old_password}
                        onChange={handleChange}
                        required
                        id="old-pass-test"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0"
                    >
                      <span className="">
                        {t("patientChangePassword.newPassword")}{" "}
                      </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="password"
                        name="new_password1"
                        value={values.new_password1}
                        onChange={handleChange}
                        required
                        id="new-pass-1-test"
                      />
                      <ol className={"pass-text-grey passReq"}>
                        {t("passwordRequirement.newMustContain")}
                        <li>{t("passwordRequirement.8characters")}</li>
                        <li>{t("passwordRequirement.1symbol")}</li>
                        <li>{t("passwordRequirement.1numeric")}</li>
                        <li>{t("passwordRequirement.1uppercase")}</li>
                        <li>{t("passwordRequirement.1lowercase")}</li>
                      </ol>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm={4}
                      md={4}
                      className="text-right pr-0"
                    >
                      <span className="">
                        {t("patientChangePassword.reEnterNewPassword")}{" "}
                      </span>
                    </Form.Label>
                    <Col sm={8} md={8}>
                      <Form.Control
                        autoComplete="off"
                        as="input"
                        type="password"
                        name="new_password2"
                        value={values.new_password2}
                        onChange={handleChange}
                        required
                        id="new-pass-2-test"
                      />
                    </Col>
                  </Form.Group>
                  <div className="d-flex justify-content-between mt-5 pt-5 mb-2">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => props.history.goBack()}
                      id="cancel-pass-test"
                    >
                      {t("patientChangePassword.cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      type="submit"
                      id="change-pass-test"
                    >
                      {t("patientChangePassword.change")}
                    </Button>
                  </div>
                </Container>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Base>
  );
}
export default ChangePassword;
