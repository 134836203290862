import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { cache } from "./cache/cache";
import "./styles.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import { createUploadLink } from "apollo-upload-client";
import { ignoreErrors } from "sentryUtils";

// import i18n
import "./locales/i18n.js";

const ENV = process.env.REACT_APP_ENV;
export const urlBackend = process.env.REACT_APP_BACKEND_ENDPOINT;

export const httpLink = createUploadLink({
  // uri: urlBackend + 'graphql/',
  uri: (op) => urlBackend + "graphql/" + (op.variables["__uriExtra"] || ""),
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  // headers['Accept-Language'] = 'ar'
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
      "Accept-Language": localStorage.getItem("appLanguage"),
    },
  };
});

const corsOptions = {
  credentials: false,
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  cors: corsOptions,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});

if (ENV === "production") {
  Sentry.init({
    dsn: "https://ec839c05c8c14a489409ea9a3c6e1a1d@o4505582634729472.ingest.sentry.io/4505582659698688",
    environment: ENV,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    release: "mindwellfe@1.0.5",
    ignoreErrors: ignoreErrors,
  });
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
