import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import Logo from "../assets/images/img.png";
import { withRouter } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  urlActions,
  success_options,
  getErrorMessage,
  concatAllErrors,
} from "../Common/helpers";
import * as Sentry from "@sentry/browser";

const VerifyAccount = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const VERIFY_ACCOUNT = gql`
    mutation verifyAccount($token: String!) {
      verifyAccount(input: { token: $token }) {
        success
        errors
      }
    }
  `;

  // CREATE ENCOUNTER
  const [verifyAccount] = useMutation(VERIFY_ACCOUNT, {
    onCompleted: ({ verifyAccount }) => {
      if (
        verifyAccount?.errors &&
        verifyAccount?.errors.nonFieldErrors &&
        verifyAccount?.errors.nonFieldErrors.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          verifyAccount?.errors.nonFieldErrors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (verifyAccount.success) {
        openSnackbarSuccess("Account Verified");
        props.history.push("/");
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("VERIFY_ACCOUNT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const verifyAccountFunc = () => {
    let verify_token = urlActions(window.location.href, "get", "verify_token");
    let val = {
      token: verify_token,
    };
    verifyAccount({ variables: val });
  };
  return (
    <Container fluid>
      <Row className={"login_page varifyPage"}>
        <Col
          md="12"
          className={"login_page__logo d-flex flex-column align-items-center"}
        >
          <div className={"logotype navLogoColor"}>
            <img alt={"Logo"} className="img-fluid navLogo " src={Logo} />
          </div>
          <Row className="justify-content-center my-5">
            <Col xs={12} sm={12} md={12}>
              <Button
                variant="primary"
                block
                size="md"
                className="mr-5 btn-pg-low"
                onClick={verifyAccountFunc}
              >
                VERIFY ACCOUNT
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(VerifyAccount);
