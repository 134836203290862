import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  isUserStaff,
  getErrorMessage,
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
} from "../Common/helpers";
import drag from "../assets/images/drag.jpg";
import { useSnackbar } from "react-simple-snackbar";
import * as Sentry from "@sentry/browser";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";

const FileUpload = (props) => {
  const isStaff = isUserStaff();
  const {
    currentImage,
    setCurrentImage,
    setUploaded,
    imageForPatientEditCreate,
  } = props;
  const allowedEndings = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".webp"];
  const [openSnackbar] = useSnackbar(error_options);

  const UploadMutation = gql`
    mutation updatePatientSelfFile($file: Upload!) {
      updatePatientSelfFile(file: $file) {
        obj {
          idFile
        }
      }
    }
  `;

  const UPDATE_PATIENT_FILE = gql`
    mutation updatePatientFile($id: ID!, $file: Upload!) {
      updatePatientFile(id: $id, file: $file) {
        obj {
          idFile
        }
      }
    }
  `;

  // pass in the UploadMutation mutation we created earlier.
  const [uploadFile] = useMutation(UploadMutation, {
    onCompleted({ updatePatientSelfFile }) {
      if (
        updatePatientSelfFile?.errors &&
        updatePatientSelfFile?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          updatePatientSelfFile?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        setCurrentImage(updatePatientSelfFile.obj.idFile);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("uploadFile error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [updatePatientFile, { loading }] = useMutation(UPDATE_PATIENT_FILE, {
    onCompleted({ updatePatientFile }) {
      if (updatePatientFile?.errors && updatePatientFile?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(updatePatientFile?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        setCurrentImage(updatePatientFile.obj.idFile);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("updatePatientFile error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const file = acceptedFiles[0];
      // use the uploadFile variable created earlier
      if (file) {
        let val = {
          file: file,
        };
        if (isStaff) {
          updatePatientFile({
            variables: { ...val, id: props.patientId },
            onCompleted: () => {},
          });
        } else {
          uploadFile({
            // use the variables option so that you can pass in the file we got above
            variables: { ...val },
            onCompleted: () => {
              setUploaded(true);
            },
          });
        }
      }
    },
    // pass in uploadFile as a dependency
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const checkEnding = (string) => {
    let isEndingGood = false;
    let index = string.lastIndexOf(".");
    let stringEnding = string.slice(index);
    allowedEndings.forEach((item, index) => {
      if (item === stringEnding) {
        isEndingGood = true;
      }
    });
    return isEndingGood;
  };

  return (
    <>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive && "isActive"}`}
      >
        <input {...getInputProps()} type="file" id="reg-file-test" />
        {currentImage ? (
          checkEnding(currentImage) ? (
            <div
              className={
                "text-left " + imageForPatientEditCreate
                  ? "imageForPatientEditCreate"
                  : ""
              }
            >
              <div className={loading ? "opacityhalf" : "idFileWrapper"}>
                {loading ? (
                  <div className="spinnerParent">
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : null}
                <img src={currentImage} alt="" className={""} />
              </div>
              <div>
                <Button
                  variant="link"
                  className="px-0 mt-2 "
                  onClick={() => window.open(currentImage)}
                >
                  <i className="fa fa-external-link"></i>
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className={loading ? "opacityhalf" : "idFileWrapper"}>
                {loading ? (
                  <div className="spinnerParent">
                    <Spinner animation="border" size="sm" />
                  </div>
                ) : null}
                <i className="fa fa-file-image-o upload-icon"></i>
                <br />
                <small>Your file preview is unavailable</small>
              </div>
            </div>
          )
        ) : (
          <div className="text-left">
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className="dragPic">
                <img src={drag} alt="" />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default FileUpload;
