import React, { useState, useCallback, useRef } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useSnackbar } from "react-simple-snackbar";
import {
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  error_options,
  getErrorMessage,
  concatAllErrors,
} from "../Common/helpers";
import { gql, useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

function PatientFileUploadModal(props) {
  const { setShowModal, patient_id, encounter_id, setAllFiles } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const formikRef = useRef();
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const { t } = useTranslation();
  const [openSnackbarError] = useSnackbar(error_options);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const UPLOAD_FILE_MUTATION = gql`
    mutation patientFileUpload(
      $file: Upload!
      $patient: ID!
      $name: String!
      $description: String
    ) {
      patientFileUpload(
        file: $file
        patient: $patient
        name: $name
        description: $description
      ) {
        obj {
          files {
            edges {
              node {
                name
                id
                file
                created
                description
              }
            }
          }
        }
      }
    }
  `;

  // pass in the UPLOAD_MUTATION mutation we created earlier.
  const [patientFileUpload] = useMutation(UPLOAD_FILE_MUTATION, {
    onCompleted({ patientFileUpload }) {
      if (patientFileUpload?.errors && patientFileUpload?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(patientFileUpload?.errors);
        openSnackbarError(error_messages_string, [SNACK_DURATION]);
      } else {
        openSnackbarSuccess("File uploaded");
        setShowModal(false);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 2000);
        if (setAllFiles) {
          setAllFiles(patientFileUpload.obj.files.edges);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("UPLOAD_FILE_MUTATION error " + e);
      }
      if (e.message) {
        openSnackbarError(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbarError(msgToDisplay, [SNACK_DURATION]);
      }
      setIsSubmitting(false);
    },
  });

  const onSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);

    let val = {
      file: selectedFile,
      patient: patient_id,
      name: values.fileName,
      description: values.fileDescription,
    };
    if (encounter_id) {
      val["id"] = encounter_id;
    }
    patientFileUpload({
      // use the variables option so that you can pass in the file we got above
      variables: val,
    });
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      // select the first file from the Array of files
      const files = acceptedFiles[0];
      if (files) {
        setSelectedFile(files);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [patientFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const initialValues = {
    name: "",
    description: "",
  };
  return (
    <>
      <Row className="">
        <Col md={12} lg={12} className="mr-auto">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            innerRef={formikRef}
          >
            {({ handleSubmit, handleChange, handleReset, values }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} lg={6} xl={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        <span className="">
                          {t("patientFileUploadModal.file")}
                        </span>
                      </Form.Label>
                      <Col sm={8} md={8} className="nav-email-overlay-desktop">
                        {
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Button variant="link" id="file-upload-modal-test">
                              {selectedFile
                                ? selectedFile.name
                                : t("patientFileUploadModal.uploadFile")}{" "}
                            </Button>
                          </div>
                        }
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6} xl={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        <span className="">
                          {t("patientFileUploadModal.fileName")}{" "}
                        </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="input"
                          name="fileName"
                          value={values.fileName}
                          onChange={handleChange}
                          required
                          id="file-name-modal-test"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} lg={6} xl={6} sm={12}>
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm={4}
                        md={4}
                        className="text-right pr-0"
                      >
                        <span className="">
                          {t("patientFileUploadModal.fileDescription")}{" "}
                        </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="input"
                          type="input"
                          name="fileDescription"
                          value={values.fileDescription}
                          onChange={handleChange}
                          required
                          id="file-desc-modal-test"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12} xl={12} sm={12}>
                    <div className="d-flex justify-content-end submitPayment">
                      <Button
                        type="button"
                        variant="primary"
                        className="mx-3"
                        onClick={() => setShowModal(false)}
                        id="file-cancel"
                      >
                        {t("patientFileUploadModal.cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="mx-3"
                        disabled={isSubmitting}
                        onClick={() => (isSubmitting ? null : handleSubmit())}
                        id="file-submit"
                      >
                        {" "}
                        {isSubmitting ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          t("patientFileUploadModal.submit")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
export default PatientFileUploadModal;
