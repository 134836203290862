import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Alert,
  Spinner,
} from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import Base from "./base.js";
import { withRouter, Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  urlActions,
  isSuperUserFunc,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
  CopyToClipBoardButton,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { Formik } from "formik";
import Preloader from "../Common/Preloder/Preloader";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deepCopy } from "../Common/helpers";

function RequestReportCreate(props) {
  const patient_record_id = props.match.params.id;
  const patientId = props.match.params.patientId;
  const isUpdate = patient_record_id ? true : false;
  let doctorFill = urlActions(window.location.href, "get", "doctorFill");
  const [openSnackbar] = useSnackbar(error_options);
  const [paymentLink, setPaymentLink] = useState(null);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  const formikRef = useRef();
  const [fillRepEdit, setFillRepEdit] = useState(false);
  const [editSingleReport, setEditSingleReport] = useState(null);
  const isStaff = isUserStaff();
  const isSuperUser = isSuperUserFunc();
  const returnAllSectionPermissions = true;
  const [doctors_obj, setDoctorObj] = useState(null);

  const patient_report_permission = getPermissionForAction(
    "patient_report",
    "",
    returnAllSectionPermissions
  );
  const { t } = useTranslation();

  const CREATE_UPDATE_PATIENT_REPORT = gql`
    mutation createUpdatePatientReport(
      $id: ID
      $doctor: ID!
      $patient: ID!
      $reason: String!
      $reportType: ID!
      $languageEn: Boolean!
    ) {
      createUpdatePatientReport(
        input: {
          id: $id
          doctor: $doctor
          patient: $patient
          reason: $reason
          languageEn: $languageEn
          reportType: $reportType
        }
      ) {
        obj {
          created
          id
          reason
          paymentSources {
            edges {
              node {
                paymentLink
                id
              }
            }
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const DOCTOR_UPDATE_PATIENT_REPORT = gql`
    mutation doctorUpdatePatientReport(
      $id: ID!
      $clinicalSummary: String!
      $treatmentRecommendation: String!
      $notes: String
      $daignosis: String!
      $languageEn: Boolean
    ) {
      doctorUpdatePatientReport(
        input: {
          id: $id
          clinicalSummary: $clinicalSummary
          treatmentRecommendation: $treatmentRecommendation
          notes: $notes
          daignosis: $daignosis
          languageEn: $languageEn
        }
      ) {
        obj {
          created
          id
          reason
          paymentSources {
            edges {
              node {
                paymentLink
                id
              }
            }
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const DOCTOR_UPDATE_PATIENT_REPORT_DRAFT = gql`
    mutation doctorUpdatePatientReportDraft(
      $id: ID!
      $clinicalSummary: String!
      $treatmentRecommendation: String!
      $notes: String
      $daignosis: String!
      $languageEn: Boolean
      $status: String!
    ) {
      doctorUpdatePatientReportDraft(
        input: {
          id: $id
          clinicalSummary: $clinicalSummary
          treatmentRecommendation: $treatmentRecommendation
          notes: $notes
          daignosis: $daignosis
          languageEn: $languageEn
          status: $status
        }
      ) {
        obj {
          created
          id
          reason
          paymentSources {
            edges {
              node {
                paymentLink
                id
              }
            }
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const REQUEST_DOCTOR = gql`
    query {
      doctors(forReport: true, active: true) {
        edges {
          node {
            id
            firstName
            lastName
          }
        }
      }
    }
  `;

  const REQUEST_PATIENT_REPORTS = gql`
  query {
    patientReport(id:"${patient_record_id}"){
            id
            status
            reason
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            hide
            patient{
              firstName
              middleName
              lastName
              id
              identifier
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
  }
`;

  const REQUEST_REPORT_TYPES = gql`
    query {
      reportTypes {
        edges {
          node {
            id
            name
            description
            price
          }
        }
      }
    }
  `;

  const DELETE_PATIENT_REPORT = gql`
    mutation deletePatientReport($id: ID!) {
      deletePatientReport(id: $id) {
        deleted
      }
    }
  `;

  const APPROVE_PATIENT_REPORT = gql`
    mutation approvePatientReport(
      $id: ID!
      $clinicalSummary: String!
      $treatmentRecommendation: String!
      $notes: String
      $daignosis: String!
      $languageEn: Boolean
    ) {
      approvePatientReport(
        input: {
          id: $id
          clinicalSummary: $clinicalSummary
          treatmentRecommendation: $treatmentRecommendation
          notes: $notes
          daignosis: $daignosis
          languageEn: $languageEn
        }
      ) {
        obj {
          created
          id
          reason
          reportType {
            name
            description
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  // delete form instance
  const [deletePatientReport] = useMutation(DELETE_PATIENT_REPORT, {
    onCompleted: ({ deletePatientReport }) => {
      if (
        deletePatientReport?.errors &&
        deletePatientReport?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          deletePatientReport?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (deletePatientReport.deleted) {
        openSnackbarSuccess(t("patientReportRequest.reqDeleted"));
        props.history.goBack();
      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_REPORTS, fetchPolicy: "network-only" },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_PATIENT_REPORT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const [approvePatientReport, { loading: approveLoading }] = useMutation(
    APPROVE_PATIENT_REPORT,
    {
      onCompleted: ({ approvePatientReport }) => {
        if (
          approvePatientReport?.errors &&
          approvePatientReport?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            approvePatientReport?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else if (approvePatientReport.deleted) {
          openSnackbarSuccess(t("patientReportRequest.reqDeleted"));
          props.history.goBack();
        } else {
          openSnackbarSuccess(t("patientReportRequest.approved"));
          props.history.goBack();
        }
      },
      refetchQueries: [
        { query: REQUEST_PATIENT_REPORTS, fetchPolicy: "network-only" },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("APPROVE_PATIENT_REPORT error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // GET DOCTOR DATA
  const { data: rawDoctorObj = null } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    setDoctorObj(deepCopy(rawDoctorObj));
  }, [rawDoctorObj]);

  doctors_obj?.doctors?.edges.sort((a, b) =>
    a?.node?.firstName?.localeCompare(b?.node?.firstName)
  );

  // GET REQUEST_REPORT_TYPES DATA
  const { data: report_type_obj = null } = useQuery(REQUEST_REPORT_TYPES, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_REPORT_TYPES error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null, loading } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_REPORTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const onSubmit = (values) => {
    let val = { ...values };
    if (patient_reports_obj && patient_reports_obj.patientReport) {
      val["id"] = patient_reports_obj.patientReport.id;
    }
    if (patientId) val["patient"] = patientId;
    if (isUpdate && doctorFill) {
      doctorUpdatePatientReport({ variables: val });
    } else {
      createUpdatePatientReport({ variables: val });
    }
  };

  const onSaveDraft = (values) => {
    let val = { ...values };
    val["status"] = "draft";

    if (patient_reports_obj && patient_reports_obj.patientReport) {
      val["id"] = patient_reports_obj.patientReport.id;
    }

    if (patientId) val["patient"] = patientId;
    if (isUpdate && doctorFill) {
      doctorUpdatePatientReportDraft({ variables: val });
    }
  };

  const [createUpdatePatientReport] = useMutation(
    CREATE_UPDATE_PATIENT_REPORT,
    {
      onCompleted: ({ createUpdatePatientReport }) => {
        if (
          createUpdatePatientReport?.errors &&
          createUpdatePatientReport?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            createUpdatePatientReport?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          if (isUpdate) {
            setEditSingleReport(null);
            props.history.goBack();
          } else if (
            createUpdatePatientReport.obj.paymentSources.edges.length > 0
          ) {
            let paymentLink =
              createUpdatePatientReport.obj.paymentSources.edges[0].node
                .paymentLink;
            if (patientId) setPaymentLink(paymentLink);
            else window.location = paymentLink;
          } else {
            let alert_msg = t("patientReportRequest.redirectPaymentError");
            openSnackbar(alert_msg, [SNACK_DURATION]);
          }
        }
      },
      refetchQueries: [
        { query: REQUEST_PATIENT_REPORTS, fetchPolicy: "network-only" },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("CREATE_UPDATE_PATIENT_REPORT error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const [doctorUpdatePatientReport] = useMutation(
    DOCTOR_UPDATE_PATIENT_REPORT,
    {
      onCompleted: ({ doctorUpdatePatientReport }) => {
        if (
          doctorUpdatePatientReport?.errors &&
          doctorUpdatePatientReport?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            doctorUpdatePatientReport?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          if (editSingleReport) {
            setEditSingleReport(null);
          }
          openSnackbarSuccess("Patient Report Filled");
          props.history.goBack();
        }
      },
      refetchQueries: [
        { query: REQUEST_PATIENT_REPORTS, fetchPolicy: "network-only" },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("DOCTOR_UPDATE_PATIENT_REPORT error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const [doctorUpdatePatientReportDraft, { loading: draftLoading }] =
    useMutation(DOCTOR_UPDATE_PATIENT_REPORT_DRAFT, {
      onCompleted: ({ doctorUpdatePatientReportDraft }) => {
        if (
          doctorUpdatePatientReportDraft?.errors &&
          doctorUpdatePatientReportDraft?.errors?.length > 0
        ) {
          let error_messages_string = getErrorMessage(
            doctorUpdatePatientReportDraft?.errors
          );
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          if (editSingleReport) {
            setEditSingleReport(null);
          }
          openSnackbarSuccess("Patient Report Draft Saved");
        }
      },
      refetchQueries: [
        { query: REQUEST_PATIENT_REPORTS, fetchPolicy: "network-only" },
      ],
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("DOCTOR_UPDATE_PATIENT_REPORT error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    });
  const initialValues = {
    doctor: "",
    reason: "",
    languageEn: true,
    reportType: "",
    clinicalSummary: "",
    treatmentRecommendation: "",
    notes: "",
    daignosis: "",
  };

  if (patient_reports_obj && patient_reports_obj.patientReport) {
    initialValues["doctor"] = patient_reports_obj.patientReport.doctor.id;
    initialValues["reportType"] =
      patient_reports_obj.patientReport.reportType.id;
    initialValues["reason"] = patient_reports_obj.patientReport.reason;
    initialValues["languageEn"] = patient_reports_obj.patientReport.languageEn;
  }

  if (patient_reports_obj && patient_reports_obj.patientReport && fillRepEdit) {
    initialValues["clinicalSummary"] =
      patient_reports_obj.patientReport.clinicalSummary;
    initialValues["treatmentRecommendation"] =
      patient_reports_obj.patientReport.treatmentRecommendation;
    initialValues["notes"] = patient_reports_obj.patientReport.notes;
    initialValues["daignosis"] = patient_reports_obj.patientReport.daignosis;
  }

  useEffect(() => {
    if (isUpdate && patient_reports_obj && patient_reports_obj.patientReport) {
      setEditSingleReport(patient_reports_obj.patientReport);
    }
    if (doctorFill) {
      setFillRepEdit(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, doctorFill]);

  const getReportTypeDetails = (report, values) => {
    if (report && report_type_obj) {
      let selected_report_type = report_type_obj.reportTypes.edges.find(
        (i) => i.node.id === report
      );
      if (selected_report_type) {
        return (
          <>
            {!isStaff ? (
              <Form.Control.Feedback type="valid" className="doctor_session">
                {t("patientReportRequest.price")}:{" "}
                {selected_report_type.node.price}
              </Form.Control.Feedback>
            ) : null}
            <Form.Control.Feedback type="valid" className="doctor_session">
              {t("patientReportRequest.description")}:{" "}
              {selected_report_type.node.description}
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  const deleteReportFunc = () => {
    if (patient_reports_obj && patient_reports_obj.patientReport) {
      let val = {
        id: patient_reports_obj.patientReport.id,
      };
      let message = "Delete Report Request?";

      if (patient_reports_obj.patientReport.hide) {
        message = "Restore Report Request?";
      }

      if (window.confirm(message)) {
        deletePatientReport({ variables: val });
      }
    }
  };

  const approveReportFunc = () => {
    if (patient_reports_obj && patient_reports_obj.patientReport) {
      let val = {
        id: patient_reports_obj.patientReport.id,
        clinicalSummary: formikRef.current.values.clinicalSummary,
        treatmentRecommendation:
          formikRef.current.values.treatmentRecommendation,
        notes: formikRef.current.values.notes,
        daignosis: formikRef.current.values.daignosis,
        languageEn: formikRef.current.values.languageEn,
      };

      if (
        !val.treatmentRecommendation ||
        !val.daignosis ||
        !val.clinicalSummary
      ) {
        openSnackbar("Please fill up all required fields.", [SNACK_DURATION]);
        return;
      }

      if (window.confirm("Approve Report Request?")) {
        approvePatientReport({ variables: val });
      }
    }
  };

  const changeLanguage = (values) => {
    formikRef.current.setFieldValue("languageEn", !values.languageEn);
  };

  return (
    <Base
      title={t("patientrequestReport.clinicalReportRequest")}
      isPatientPortal={!isStaff}
      showHeader={true}
    >
      {paymentLink ? (
        <Row>
          <Alert variant={"success"}>Successfully Created Report Request</Alert>
          <Col xs={12} className="d-flex align-items-center no-print">
            <h6 className="mb-0 mt-1 ">
              <b>Payment Link :</b>
            </h6>

            <Button variant="link">
              <a
                href={paymentLink}
                target="_blank"
                rel="noreferrer"
                className="payment-link-icon pl-2"
              >
                <FontAwesomeIcon icon={faLink} />
              </a>
            </Button>
            <CopyToClipBoardButton
              textValue={paymentLink}
              variant="link"
              className="pl-1"
            />
          </Col>
        </Row>
      ) : loading || !doctors_obj ? (
        <Preloader />
      ) : (
        <Row>
          <Col xs={12} xl={12}>
            {patient_reports_obj && patient_reports_obj.patientReport ? (
              <Form.Group as={Row}>
                <Form.Label column sm={4} md={3} className="text-right pr-0">
                  <span className="">
                    {t("patientReportRequest.patientName")}:{" "}
                  </span>
                </Form.Label>
                <Col sm={8} md={8}>
                  <Link
                    to={`/patient/record/${
                      patient_reports_obj.patientReport.patient.id +
                      "/?identifier=" +
                      patient_reports_obj.patientReport.patient.identifier
                    }`}
                  >
                    <Button variant="link" style={{ display: "inline-block" }}>
                      {patient_reports_obj.patientReport.patient.firstName +
                        " " +
                        displayMiddleName(
                          patient_reports_obj.patientReport.patient.middleName
                        ) +
                        patient_reports_obj.patientReport.patient.lastName}
                    </Button>
                  </Link>
                </Col>
              </Form.Group>
            ) : null}
            {!isStaff && doctors_obj?.doctors?.edges?.length === 0 ? (
              <div> {t("patientReportRequest.noAppForReport")} </div>
            ) : (
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                enableReinitialize
                innerRef={formikRef}
              >
                {({ handleSubmit, handleBlur, handleChange, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Container>
                      {report_type_obj && report_type_obj.reportTypes ? (
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={4}
                            md={3}
                            className="text-right pr-0 required"
                          >
                            <span className="">
                              {t("patientReportRequest.reportType")}{" "}
                            </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="select"
                              name="reportType"
                              disabled={doctorFill ? true : false}
                              value={values.reportType}
                              onChange={handleChange}
                              required
                              id="report-type-test"
                            >
                              <option value="">
                                {t("patientReportRequest.selectReportType")}
                              </option>
                              {report_type_obj.reportTypes.edges.map(
                                (type, index) => {
                                  return (
                                    <option value={type.node.id} key={index}>
                                      {type.node.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                            {getReportTypeDetails(values.reportType, values)}
                          </Col>
                        </Form.Group>
                      ) : null}
                      {/* {doctors_obj && doctors_obj.doctors && report_type_obj? <Form.Group as={Row}>
                      <Form.Label column sm={4} md={3} className="text-right pr-0 required">
                        <span className="">{t('patientReportRequest.reportType')} </span>
                      </Form.Label>
                      <Col sm={8} md={8}>
                        <Form.Control
                          autoComplete="off"
                          as="select"
                          name="reportType"
                          disabled={doctorFill ? true : false}
                          value={values.reportType}
                          name="doctor"
                          value={values.doctor}
                          disabled={doctorFill ? true : false}
                          onChange={handleChange}
                          required>
                          <option value="">{t('patientReportRequest.selectReportType')}</option>
                          {report_type_obj.reportTypes.edges.map((type, index) => {
                            return (
                              <option value={type.node.id} key={index}>
                                {type.node.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        {getReportTypeDetails(values.reportType, values)}
                      </Col>
                    </Form.Group>
                    : null} */}
                      {doctors_obj && doctors_obj.doctors ? (
                        <Form.Group as={Row}>
                          <Form.Label
                            column
                            sm={4}
                            md={3}
                            className="text-right pr-0 required"
                          >
                            <span className="">
                              {t("patientReportRequest.doctor")}
                            </span>
                          </Form.Label>
                          <Col sm={8} md={8}>
                            <Form.Control
                              autoComplete="off"
                              as="select"
                              name="doctor"
                              value={values.doctor}
                              disabled={doctorFill ? true : false}
                              onChange={handleChange}
                              required
                              id="report-prac-test"
                            >
                              <option value="">
                                {t("patientReportRequest.selectDoctor")}
                              </option>
                              {doctors_obj &&
                                doctors_obj.doctors.edges.map(
                                  (doctor, index) => {
                                    return (
                                      <option
                                        value={doctor.node.id}
                                        key={index}
                                      >
                                        {doctor.node.firstName}{" "}
                                        {doctor.node.lastName}
                                      </option>
                                    );
                                  }
                                )}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      ) : null}
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={4}
                          md={3}
                          className="text-right pr-0"
                        >
                          <span className="">
                            {" "}
                            {t("patientReportRequest.reportInArabic")}{" "}
                          </span>
                        </Form.Label>
                        <Col
                          sm={8}
                          md={8}
                          className="d-flex align-items-center"
                        >
                          <span style={{ marginRight: "16px" }}>AR</span>
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              name="languageEn"
                              value={values.languageEn}
                              // disabled={doctorFill ? true : false}
                              checked={values.languageEn}
                              onChange={() => changeLanguage(values)}
                              id="report-ar-test"
                            />
                            <span className="custom-toggle-slider"></span>
                          </label>
                          <span style={{ marginLeft: "16px" }}>EN</span>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          sm={12}
                          md={3}
                          className="text-right pr-0 required"
                        >
                          <span className="">
                            {t("patientReportRequest.reason")}
                          </span>
                        </Form.Label>
                        <Col sm={12} md={8}>
                          <Form.Control
                            autoComplete="off"
                            as="textarea"
                            rows={2}
                            onBlur={handleBlur}
                            name="reason"
                            value={values.reason}
                            disabled={doctorFill ? true : false}
                            onChange={handleChange}
                            required
                            id="report-reason-test"
                          />
                        </Col>
                      </Form.Group>
                      {doctorFill ? (
                        <>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              md={3}
                              className="text-right pr-0 required"
                            >
                              <span className="">
                                {t("patientReportRequest.clinicalSummary")}
                              </span>
                            </Form.Label>
                            <Col sm={12} md={8}>
                              <Form.Control
                                autoComplete="off"
                                as="textarea"
                                rows={10}
                                onBlur={handleBlur}
                                name="clinicalSummary"
                                value={values.clinicalSummary}
                                onChange={handleChange}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              md={3}
                              className="text-right pr-0 required"
                            >
                              <span className="">
                                {t("patientReportRequest.diagnosis")}
                              </span>
                            </Form.Label>
                            <Col sm={12} md={8}>
                              <Form.Control
                                autoComplete="off"
                                as="textarea"
                                rows={2}
                                onBlur={handleBlur}
                                name="daignosis"
                                value={values.daignosis}
                                onChange={handleChange}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              md={3}
                              className="text-right pr-0 required"
                            >
                              <span className="">
                                {t("patientReportRequest.treatmentRec")}
                              </span>
                            </Form.Label>
                            <Col sm={12} md={8}>
                              <Form.Control
                                autoComplete="off"
                                as="textarea"
                                rows={4}
                                onBlur={handleBlur}
                                name="treatmentRecommendation"
                                value={values.treatmentRecommendation}
                                onChange={handleChange}
                                required
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Form.Label
                              column
                              sm={12}
                              md={3}
                              className="text-right pr-0 "
                            >
                              <span className="">
                                {t("patientReportRequest.notes")}
                              </span>
                            </Form.Label>
                            <Col sm={12} md={8}>
                              <Form.Control
                                autoComplete="off"
                                as="textarea"
                                rows={2}
                                onBlur={handleBlur}
                                name="notes"
                                value={values.notes}
                                onChange={handleChange}
                              />
                            </Col>
                          </Form.Group>
                        </>
                      ) : null}
                      {console.log(patient_reports_obj)}
                      <div className="d-flex justify-content-end mt-4 mb-4">
                        {(isSuperUser ||
                          (patient_report_permission &&
                            patient_report_permission.includes("delete"))) &&
                        patient_reports_obj &&
                        patient_reports_obj.patientReport ? (
                          <Button
                            variant="danger"
                            className="back mr-2"
                            onClick={deleteReportFunc}
                          >
                            {patient_reports_obj.patientReport.hide
                              ? "Revert"
                              : t("patientReportRequest.delete")}
                          </Button>
                        ) : null}
                        {(isSuperUser ||
                          (patient_report_permission &&
                            patient_report_permission.includes("approve"))) &&
                        patient_reports_obj &&
                        patient_reports_obj.patientReport ? (
                          <Button
                            variant="primary"
                            className="back mr-2"
                            onClick={approveReportFunc}
                            disabled={approveLoading}
                          >
                            {approveLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              t("patientReportRequest.approve")
                            )}
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            type="submit"
                            className="back mr-2"
                            id="report-submit-test"
                          >
                            {t("patientReportRequest.submit")}
                          </Button>
                        )}

                        {patient_reports_obj?.patientReport && (
                          <Button
                            variant="primary"
                            onClick={() => onSaveDraft(values)}
                            className="back mr-2"
                            disabled={draftLoading}
                          >
                            {draftLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              t("patientReportRequest.draft")
                            )}
                          </Button>
                        )}
                        <Button
                          variant="primary"
                          className="back mr-2"
                          onClick={() => props.history.goBack()}
                        >
                          {t("patientReportRequest.cancel")}
                        </Button>
                      </div>
                    </Container>
                  </Form>
                )}
              </Formik>
            )}
          </Col>
        </Row>
      )}

      {}
    </Base>
  );
}

export default withRouter(RequestReportCreate);
