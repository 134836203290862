import React, { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Container,
  ProgressBar,
} from "react-bootstrap";
import Base from "./base.js";
import { Formik } from "formik";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  urlActions,
  capitalizeFirstLetter,
  getValidDateStringForGraphQL,
  displayMiddleName,
  getErrorMessage,
  getAge,
  concatAllErrors,
} from "../Common/helpers.js";
import FileUpload from "../component/upload";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import CountrySelect from "../Views/countrySelect";
import { onBoardingToFillVar } from "../cache/cache.js";
import * as Sentry from "@sentry/browser";
import IntakeForm from "./intakeForm.js";

const RegistrationCompleteForm = (props) => {
  const [openSnackbar] = useSnackbar(error_options);
  const formikRef = useRef();
  const [currentImage, setCurrentImage] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [focus, setFocus] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const { t } = useTranslation();
  let profileEdit = urlActions(window.location.href, "get", "profileEdit");
  const GET_COUNTRIES = gql`
    query {
      countries
    }
  `;

  const GET_GOVERNORATES = gql`
    query {
      governorates {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  const GET_AREAS = gql`
    query areas($governorate: ID!) {
      areas(governorate_Id: $governorate) {
        totalCount
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `;

  const UPDATE_PATIENT = gql`
    mutation createUpdateSelfPatient(
      $firstName: String!
      $middleName: String!
      $lastName: String!
      $phone: String!
      $dob: Date!
      $gender: String!
      $email: String!
      $civil_id: String
      $doctor: ID
      $address: String
      $governorate: ID
      $country: String!
      $area: ID
      $diagnosis: String
      $referred_by: String
      $medication: String
      $identifier: String
    ) {
      createUpdateSelfPatient(
        input: {
          firstName: $firstName
          middleName: $middleName
          lastName: $lastName
          phone: $phone
          dob: $dob
          gender: $gender
          civilId: $civil_id
          doctor: $doctor
          address: $address
          governorate: $governorate
          country: $country
          area: $area
          impressionDiagnosis: $diagnosis
          medication: $medication
          referredBy: $referred_by
          email: $email
          identifier: $identifier
        }
      ) {
        obj {
          firstName
          lastName
          email
          identifier
          phone
          id
          profileComplete
          discount
          doctor {
            identifier
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const SELECTED_PATIENT_DATA = gql`
    query receivePatientData {
      dataPatient @client
    }
  `;

  const REQUEST_INTAKE_FORMS = gql`
    query {
      intakeForms {
        edges {
          node {
            id
            patient {
              firstName
              middleName
              lastName
            }
            dynamicForm {
              name
              id
              formType
              patientOnboarding
            }
            data
            submitted
          }
        }
      }
    }
  `;

  const { data: intake_forms_obj, loading: loadingIntake } = useQuery(
    REQUEST_INTAKE_FORMS,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_INTAKE_FORMS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const ONBOARDING_FORM_TO_FILL = intake_forms_obj
    ? intake_forms_obj.intakeForms.edges.find(
        (i) => i.node.dynamicForm.patientOnboarding && !i.node.submitted
      )
    : null;

  const [UpdatePatient] = useMutation(UPDATE_PATIENT, {
    onCompleted: ({ createUpdateSelfPatient }) => {
      if (
        createUpdateSelfPatient?.errors &&
        createUpdateSelfPatient?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdateSelfPatient?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        if (
          createUpdateSelfPatient &&
          createUpdateSelfPatient.obj &&
          createUpdateSelfPatient.obj.profileComplete
        ) {
          if (ONBOARDING_FORM_TO_FILL) {
            onBoardingToFillVar(true);
            setStep(5);
          } else {
            props.history.push("/patientPortal/dashboard");
          }
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("UPDATE_PATIENT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const { data: countriesData = null } = useQuery(GET_COUNTRIES);
  const { data: governoratesData = null } = useQuery(GET_GOVERNORATES);
  const [getAreasForGov, { data: areasData = [] }] = useLazyQuery(GET_AREAS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("GET_AREAS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const { data: patientData = [] } = useQuery(SELECTED_PATIENT_DATA);
  const onChangeCountry = (e) => {
    formikRef.current.setFieldValue("country", e.target.value);
  };
  const [step, setStep] = useState(1);

  const handleNext = () => {
    document.querySelector("form").reportValidity();
    if (document.querySelector("form").checkValidity()) setStep(step + 1);
  };
  const handlePrevious = () => setStep(step - 1);

  useEffect(() => {
    if (patientData && patientData.dataPatient) {
      if (
        patientData.dataPatient.governorate &&
        patientData.dataPatient.governorate.id
      ) {
        let val = {
          governorate: patientData.dataPatient.governorate.id,
        };
        getAreasForGov({ variables: val });
      }
      if (patientData.dataPatient.idFile) {
        setCurrentImage(patientData.dataPatient.idFile);
        setUploaded(true);
      }
      if (patientData.dataPatient.dob) {
        setStartDate(new Date(patientData.dataPatient.dob));
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  const onChangeGovernorate = (e) => {
    formikRef.current.setFieldValue("governorate", e.target.value);
    let gov = governoratesData.governorates.edges.find(
      (i) => i.node.id === e.target.value
    );
    if (gov) {
      let gov_id = gov.node.id;
      let val = {
        governorate: gov_id,
      };
      getAreasForGov({ variables: val });
    }
  };

  const onChangeArea = (e) => {
    formikRef.current.setFieldValue("area", e.target.value);
  };

  let initialValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    dob: "",
    gender: "",
    civil_id: "",
    email: "",
    phone: "",
    address: "",
    governorate: "",
    country: "KW",
  };
  if (patientData && patientData.dataPatient) {
    initialValues["firstName"] = patientData.dataPatient.firstName;
    initialValues["middleName"] = displayMiddleName(
      patientData.dataPatient.middleName
    );
    initialValues["lastName"] = patientData.dataPatient.lastName;
    initialValues["dob"] = new Date(patientData.dataPatient.dob);
    initialValues["phone"] = patientData.dataPatient.phone;
    initialValues["email"] = patientData.dataPatient.email;
    initialValues["gender"] = String(
      patientData.dataPatient.displayGender
    ).toLowerCase();
    initialValues["civil_id"] = patientData.dataPatient.civilId;
    initialValues["address"] = patientData.dataPatient.address;
    initialValues["id"] = patientData.dataPatient.id
      ? patientData.dataPatient.id
      : "";
    initialValues["file_number"] = patientData.dataPatient.identifier;
    initialValues["country"] = patientData.dataPatient.country?.code
      ? patientData.dataPatient.country.code
      : "KW";
    initialValues["governorate"] = patientData.dataPatient.governorate
      ? patientData.dataPatient.governorate.id
      : "";
    initialValues["area"] = patientData.dataPatient.area
      ? patientData.dataPatient.area.id
      : "";
  }

  const onSubmit = (values) => {
    let val = {
      firstName: capitalizeFirstLetter(values.firstName),
      middleName: capitalizeFirstLetter(values.middleName),
      lastName: capitalizeFirstLetter(values.lastName),
      phone: values.phone,
      dob: getValidDateStringForGraphQL(startDate),
      email: values.email,
      gender: values.gender,
      civil_id: values.civil_id,
      address: values.address,
      country: values.country ? values.country : "KW",
      profile_complete: false,
      identifier: values.file_number,
      id: values.id,
    };
    if (val.country === "KW") {
      val["governorate"] = values.governorate;
      val["area"] = values.area;
    }
    let variables = val;
    let age = getAge(variables.dob);
    if (age >= 13) {
      UpdatePatient({ variables: variables });
    } else {
      openSnackbar(t("patientRegForm.ageNotMeet"), [SNACK_DURATION]);
    }
  };

  // var patientId = patientData && patientData.dataPatient && patientData.dataPatient.id ? patientData.dataPatient.id : null;
  var patientId = "UGF0aWVudE5vZGU6Mw==";

  const onChangeEnglishInput = (e) => {
    let value = e.target.value;
    let fieldName = e.target.name;
    value = value.replace(/[^a-z '']/gi, "");
    formikRef.current.setFieldValue(fieldName, value);
  };

  const updateFocus = (data) => {
    if (data) {
      setFocus(data);
    }
  };

  const handleSignupPhoneChange = (value) => {
    let num = "+" + value;
    formikRef.current.setFieldValue("phone", num);
    // setPhoneNumber(num);
  };
  const GENERATE_OTP = gql`
    mutation PatientGenerateOtp($id: ID!) {
      patientGenerateOtp(id: $id) {
        expiration
      }
    }
  `;
  const VERIFY_OTP = gql`
    mutation VerifyOtp($otp: String!, $id: ID!) {
      verifyOtp(otp: $otp, id: $id) {
        phoneVerified
      }
    }
  `;
  const [generateOtp] = useMutation(GENERATE_OTP, {
    onCompleted: ({ patientGenerateOtp }) => {
      if (
        patientGenerateOtp?.errors &&
        patientGenerateOtp?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(patientGenerateOtp?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("generateOtp error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const [verifyOtp] = useMutation(VERIFY_OTP, {
    onCompleted: ({ verifyOtp }) => {
      if (verifyOtp?.errors && verifyOtp?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(verifyOtp?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        if (verifyOtp.phoneVerified) {
          setStep(step + 1);
        }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("verifyOtp error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const sendCode = () => {
    UpdatePatient({ variables: { phone: formikRef.current.values.phone } });

    generateOtp({ variables: { id: patientId } });
  };
  const verifyCode = () => {
    verifyOtp({
      variables: { otp: formikRef.current.values.code, id: patientId },
    });
  };

  return (
    <Base
      title={
        profileEdit && profileEdit === "true"
          ? t("patientRegForm.profileEdit")
          : t("patientRegForm.registerForm")
      }
      showHeader={true}
      isPatientPortal={true}
    >
      <Col xs={12} sm={12} md={12} lg={12} xl={11}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
                <ProgressBar now={(step / 5) * 100} />
                <Form.Group as={Row}></Form.Group>

                {step === 1 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          xs={12}
                          column
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.patientFirstName")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            required
                            autoComplete="nope"
                            type="text"
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={onChangeEnglishInput}
                            value={values.firstName || ""}
                            id="reg-first-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          xs={12}
                          column
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.patientMiddleName")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            required
                            autoComplete="nope"
                            type="text"
                            name="middleName"
                            onBlur={handleBlur}
                            onChange={onChangeEnglishInput}
                            value={values.middleName || ""}
                            id="reg-middle-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          xs={12}
                          column
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.patientLastName")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            required
                            autoComplete="nope"
                            type="text"
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={onChangeEnglishInput}
                            value={values.lastName || ""}
                            id="reg-last-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.dateOfBirth")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <DatePicker
                            name="dob"
                            autoComplete="off"
                            showMonthDropdown
                            showYearDropdown
                            selected={startDate ? startDate : ""}
                            onChange={(date) => setStartDate(date)}
                            className="form-control"
                            dateFormat="dd/MMM/yyyy"
                            maxDate={new Date()}
                            id="reg-dob-test"
                          />
                          <input
                            name="age_temp"
                            value={startDate ? startDate.getSeconds() : ""}
                            style={{
                              height: 0,
                              position: "absolute",
                              bottom: 0,
                              left: 20,
                              zIndex: -2,
                            }}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dob}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.gender")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            onBlur={handleBlur}
                            as="select"
                            name="gender"
                            value={values.gender}
                            required
                            onChange={handleChange}
                            id="reg-gender-test"
                          >
                            <option value="">
                              {t("patientRegForm.selectGender")}
                            </option>
                            <option value={"female"}>
                              {t("patientRegForm.female")}
                            </option>
                            <option value={"male"}>
                              {t("patientRegForm.male")}
                            </option>
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 2 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.idNumber")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            type="text"
                            onBlur={handleBlur}
                            name="civil_id"
                            required
                            autoComplete="civil_id"
                            onChange={handleChange}
                            value={values.civil_id || ""}
                            id="reg-id-test"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="id-label"
                        >
                          {/* QUESTION translation? */}
                          {/* {t('patientRegForm.id')} */}
                          <span className="required">
                            {t("patientRegForm.id")}
                          </span>
                          <small>{t("patientRegForm.click")}</small>
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          {patientId ? (
                            <FileUpload
                              patientId={patientId}
                              currentImage={currentImage}
                              setCurrentImage={setCurrentImage}
                              setUploaded={setUploaded}
                            />
                          ) : null}
                          <input
                            required
                            name="id"
                            value={uploaded ? "uploaded" : ""}
                            className="hide-input"
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 3 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      {countriesData ? (
                        <Form.Group as={Row} className="text-right">
                          <Form.Label
                            column
                            xs={12}
                            sm={4}
                            md={5}
                            className="required"
                          >
                            {t("patientRegForm.country")}
                          </Form.Label>
                          <Col xs={12} sm={8} md={7}>
                            <Form.Control
                              onBlur={handleBlur}
                              as="select"
                              name="country"
                              value={values.country ? values.country : "KW"}
                              required
                              onChange={onChangeCountry}
                              id="reg-country-test"
                            >
                              <option value="">
                                {t("patientRegForm.selectCountry")}
                              </option>
                              {Object.entries(countriesData.countries).map(
                                ([key, value], index) => {
                                  if (key !== "IL") {
                                    return (
                                      <option value={key} key={index}>
                                        {value}
                                      </option>
                                    );
                                  } else {
                                    return null;
                                  }
                                }
                              )}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      ) : null}
                      {governoratesData &&
                      governoratesData.governorates &&
                      governoratesData.governorates.edges &&
                      values.country === "KW" ? (
                        <Form.Group as={Row} className="text-right">
                          <Form.Label
                            column
                            xs={12}
                            sm={4}
                            md={5}
                            className="required"
                          >
                            {t("patientRegForm.governorate")}
                          </Form.Label>
                          <Col xs={12} sm={8} md={7}>
                            <Form.Control
                              onBlur={handleBlur}
                              as="select"
                              name="governorate"
                              value={values.governorate}
                              required
                              onChange={onChangeGovernorate}
                              id="reg-governate-test"
                            >
                              <option value="">
                                {t("patientRegForm.selectGovernorate")}
                              </option>
                              {governoratesData.governorates.edges.map(
                                (item, index) => {
                                  return (
                                    <option value={item.node.id} key={index}>
                                      {item.node.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      ) : null}
                      {areasData &&
                      areasData.areas &&
                      areasData.areas.edges &&
                      values.country === "KW" ? (
                        <Form.Group as={Row} className="text-right">
                          <Form.Label
                            column
                            xs={12}
                            sm={4}
                            md={5}
                            className="required"
                          >
                            {t("patientRegForm.area")}
                          </Form.Label>
                          <Col xs={12} sm={8} md={7}>
                            <Form.Control
                              onBlur={handleBlur}
                              as="select"
                              name="area"
                              value={values.area}
                              required
                              onChange={onChangeArea}
                              id="reg-area-test"
                            >
                              <option value="">
                                {t("patientRegForm.selectArea")}
                              </option>
                              {areasData.areas.edges.map((item, index) => {
                                return (
                                  <option value={item.node.id} key={index}>
                                    {item.node.name}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      ) : null}

                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={4}
                          md={5}
                          className="required"
                        >
                          {t("patientRegForm.address")}
                        </Form.Label>
                        <Col xs={12} sm={8} md={7}>
                          <Form.Control
                            type="text"
                            onBlur={handleBlur}
                            name="address"
                            required
                            autoComplete="address"
                            onChange={handleChange}
                            value={values.address || ""}
                            id="reg-addr-test"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 4 && (
                  <Row className="text-center">
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Form.Group as={Row}>
                        <Form.Label
                          column
                          xs={12}
                          sm={2}
                          md={3}
                          className="required text-right"
                        >
                          {t("patientRegForm.phoneNumber")}
                        </Form.Label>
                        <Col xs={12} sm={6} md={5} className="text-left bbbbb">
                          <CountrySelect
                            required
                            value={values.phone || ""}
                            onChange={handleSignupPhoneChange}
                            type="text"
                            name="phone"
                            onFocus={() => updateFocus("phone")}
                            onBlur={() => updateFocus("blur")}
                            placeholder={
                              focus === "phone" ? "" : t("Account.phone")
                            }
                            id="reg-phone-test"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phone}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} sm={2} md={3} className="text-left">
                          <Button
                            variant="btn btn-info"
                            size="sm"
                            onClick={sendCode}
                          >
                            {t("sendCode")}
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="text-right">
                        <Form.Label
                          column
                          xs={12}
                          sm={2}
                          md={3}
                          className="required"
                        >
                          {t("code")}
                        </Form.Label>
                        <Col xs={12} sm={6} md={5}>
                          <Form.Control
                            type="text"
                            onBlur={handleBlur}
                            name="code"
                            required
                            autoComplete="code"
                            onChange={handleChange}
                            value={values.code || ""}
                            id="reg-addr-test"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.code}
                          </Form.Control.Feedback>
                        </Col>
                        <Col xs={12} sm={2} md={3} className="text-left">
                          <Button
                            variant="btn btn-info"
                            size="sm"
                            onClick={verifyCode}
                          >
                            {t("verify")}
                          </Button>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {step === 5 && (
                  <IntakeForm
                    inline={true}
                    intake_id={ONBOARDING_FORM_TO_FILL?.node?.id}
                  />
                )}
                <div className="d-flex justify-content-between">
                  {step < 5 && (
                    <Button
                      variant="secondary"
                      onClick={handlePrevious}
                      disabled={step === 1}
                    >
                      {t("previous")}
                    </Button>
                  )}
                  {step < 4 && (
                    <Button variant="primary" onClick={handleNext}>
                      {t("next")}
                    </Button>
                  )}

                  {step === 4 && (
                    <Button
                      type="submit"
                      variant="primary"
                      active
                      name="appointment"
                      id="reg-submit-test"
                    >
                      {t("next")}
                    </Button>
                  )}
                </div>
              </Container>
            </Form>
          )}
        </Formik>
      </Col>
    </Base>
  );
};

export default RegistrationCompleteForm;
