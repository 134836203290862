import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Card } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import Base from "./base.js";
import Preloader from "../Common/Preloder/Preloader";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  getDate,
  getForamttedTime,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
  getDateKuwaitFormatted,
  getDateDay,
  // isUserStaff
} from "../Common/helpers";
import UpcomingAppointmentCard from "../component/upcomingAppointmentCard";
import Badge from "./Badge";
import { userDetailsVar } from "../cache/cache";
import { useReactiveVar } from "@apollo/client";
import * as Sentry from "@sentry/browser";

function Dashboard(props) {
  // eslint-disable-next-line no-unused-vars
  const [startDateUpcomingAppointment, setStartDateUpcomingAppointment] =
    useState(new Date().toISOString());
  const [badgeForReportRequests, setBadgeForReportRequests] = useState();
  const [badgeForMedicalReports, setBadgeForMedicalReports] = useState();
  const [badgeForApproved, setBadgeForApproved] = useState();
  const [badgeForApprovals, setBadgeForApprovals] = useState();
  const [badgeForLeaveRequests, setBadgeForLeaveRequests] = useState();
  const [badgeForRefundRequests, setBadgeForRefundRequests] = useState();
  const [badgeForUpcomingAppointments, setBadgeForUpcomingAppointments] =
    useState();

  const [upcomingAppointments, setUpcomingAppointments] = useState();
  const [leavesList, setLeavesList] = useState([]);
  var userDetails = useReactiveVar(userDetailsVar);
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const LEAVE_REQUESTS = gql`
    query {
      leaveRequestsForApproval(first: 3, status: "pending") {
        totalCount
        edges {
          node {
            id
            startDate
            endDate
            totalDays
            status
            approvedOneDate
            approvedTwoDate
            approvedOne
            approvedTwo
            user {
              firstName
              lastName
              phone
              hr {
                position {
                  name
                }
                department {
                  name
                }
                numberAnnualLeaves
              }
            }
          }
        }
      }
    }
  `;

  const LEAVE_REQUESTS_TIER_TWO = gql`
    query {
      leaveRequestsForApproval {
        totalCount
        edges {
          node {
            id
            startDate
            endDate
            totalDays
            status
            approvedOneDate
            approvedTwoDate
            approvedOne
            approvedTwo
            user {
              firstName
              lastName
              phone
              hr {
                position {
                  name
                }
                department {
                  name
                }
                numberAnnualLeaves
              }
            }
          }
        }
      }
    }
  `;

  const REFUND_REQUESTS = gql`
    query {
      refundRequestWithApproval(first: 3) {
        totalCount
        edges {
          node {
            id
            amount
            status
            paymentModel {
              displayReason
            }
            displayStatus
            notes
            dateRefunded
            refundId
            requiresApproval
            created
            staffNotes
            paymentModel {
              patient {
                firstName
                middleName
                lastName
              }
            }
            appointment {
              start
            }
          }
        }
      }
    }
  `;

  const REQUEST_EVENTS = gql`
    query ($dashboardStart: DateTime!, $doctors: [String]) {
      events(
        doctors: $doctors
        orderby: "start"
        dashboardStart: $dashboardStart
        active: true
        first: 5
      ) {
        totalCount
        edges {
          node {
            title
            id
            displayStatus
            status
            start
            end
            patient {
              firstName
              lastName
              middleName
              id
              identifier
            }
            doctor {
              identifier
            }
            eventType {
              name
            }
            doctorEventType {
              title {
                name
              }
            }
          }
        }
      }
    }
  `;

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const REQUEST_PATIENT_REPORTS = gql`
    query {
      doctorPatientReports(first: 3, status: "paid", hide: false) {
        totalCount
        edges {
          node {
            id
            created
            languageEn
            reportType {
              name
            }
            patient {
              firstName
              middleName
              lastName
              id
              identifier
            }
          }
        }
      }
    }
  `;

  const REQUEST_MEDICAL_REPORTS = gql`
    query {
      patientReportsForApproval {
        totalCount
        edges {
          node {
            status
            id
            reason
            displayStatus
            created
            languageEn
            reportType {
              name
            }
            patient {
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;
  const REQUEST_FORM_BLOCK_APPROVALS = gql`
    query {
      formBlockApprovals {
        # ) # patient_SearchText_Istartswith: "" # patient_SearchText_Icontains: "" # patient_SearchText: "" # last: 3 # first: 3 # after: "" # before: "" # offset: 1 # (
        totalCount
        edges {
          node {
            created
            modified
            patient {
              firstName
              middleName
              lastName
              identifier
              id
            }
            encounter {
              id
              date
              doctor {
                firstName
                lastName
              }
            }
            formBlock {
              name
              created
            }
          }
        }
      }
    }
  `;

  const REQUEST_APPROVED_BLOCKS = gql`
    query {
      approvedBlockNotifications(first: 3) {
        totalCount
        edges {
          node {
            id
            created
            encounter {
              id
              doctor {
                firstName
                lastName
              }
            }
            patient {
              id
              identifier
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const DELETE_APPROVED_BLOCK = gql`
    mutation deleteBlockApprovedNotification($id: ID!) {
      deleteBlockApprovedNotification(id: $id) {
        deleted
      }
    }
  `;

  // GET REQUEST_PATIENT_REPORTS DATA
  const { data: patient_reports_obj = null } = useQuery(
    REQUEST_PATIENT_REPORTS,
    {
      fetchPolicy: "network-only",

      onCompleted: (data) => {
        setBadgeForReportRequests(data?.doctorPatientReports?.totalCount);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_REPORTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const { data: medical_reports_obj = null } = useQuery(
    REQUEST_MEDICAL_REPORTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setBadgeForMedicalReports(data?.patientReportsForApproval?.totalCount);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_MEDICAL_REPORTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const { data: formBlockApprovalsObj = null } = useQuery(
    REQUEST_FORM_BLOCK_APPROVALS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setBadgeForApprovals(data?.formBlockApprovals?.totalCount);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_FORM_BLOCK_APPROVALS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const { data: approvedBlocks = null } = useQuery(REQUEST_APPROVED_BLOCKS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setBadgeForApproved(data?.approvedBlockNotifications?.totalCount);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_APPROVED_BLOCKS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const { data: userDetailsData } = useQuery(USER_DETAILS);

  userDetails = userDetailsData ? userDetailsData.userDetails : null;

  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const [deleteBlockApprovedNotification] = useMutation(DELETE_APPROVED_BLOCK, {
    onCompleted: ({ deleteBlockApprovedNotification }) => {
      if (
        deleteBlockApprovedNotification?.errors &&
        deleteBlockApprovedNotification?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          deleteBlockApprovedNotification?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_APPROVED_BLOCK error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [openSnackbar] = useSnackbar(error_options);
  let eventVariables = { dashboardStart: startDateUpcomingAppointment };
  if (userDetails?.doctor?.identifier) {
    eventVariables["doctors"] = [userDetails?.doctor?.doctorId];
  }
  const { loading } = useQuery(REQUEST_EVENTS, {
    fetchPolicy: "network-only",
    variables: eventVariables,
    onCompleted(data) {
      let upApp = data?.events?.edges?.map((evt) => {
        return evt.node;
      });
      setUpcomingAppointments(upApp);
      setBadgeForUpcomingAppointments(data?.events?.totalCount);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_EVENTS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const { data: leaveRequests, loading: leaveRequestsLoading } = useQuery(
    LEAVE_REQUESTS,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        setBadgeForLeaveRequests(data?.leaveRequestsForApproval?.totalCount);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("LEAVE_REQUESTS error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const { data: leaveRequestsTierTwo } = useQuery(LEAVE_REQUESTS_TIER_TWO, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("LEAVE_REQUESTS_TIER_TWO error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const { data: refundRequests } = useQuery(REFUND_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      setBadgeForRefundRequests(data?.refundRequestWithApproval?.totalCount);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REFUND_REQUESTS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const has_app_permission_view = getPermissionForAction("appointment", "list");

  const renderUpcomingAppointments = (displayEventsList) => {
    return displayEventsList.map((event, index) => {
      return (
        <Link
          to={"/appointment/detail/" + event.id}
          className="dark_link"
          key={index}
        >
          <UpcomingAppointmentCard
            patient_record={event.patient}
            event={event}
            extraClasses="w100"
          />
        </Link>
      );
    });
  };

  const REQUEST_USER_PERMISSION = gql`
    query receiveDate {
      userPermissions @client
    }
  `;
  var { data: user_permissions } = useQuery(REQUEST_USER_PERMISSION);

  let userPermissions = user_permissions
    ? JSON.parse(user_permissions.userPermissions)
    : null;
  // eslint-disable-next-line no-unused-vars
  let userLeaveRequest = userPermissions ? userPermissions.leave_request : "";

  useEffect(() => {
    if (getPermissionForAction("leave_request_tier_two", "approve")) {
      let leavesArr =
        leaveRequestsTierTwo &&
        leaveRequestsTierTwo.leaveRequestsForApproval &&
        leaveRequestsTierTwo.leaveRequestsForApproval.edges
          ? leaveRequestsTierTwo.leaveRequestsForApproval.edges
          : [];
      leavesArr = leavesArr?.filter(
        (i) =>
          i.node.approvedOne === true &&
          i.node.approvedTwo === false &&
          i.node.status !== "UNAPPROVED" &&
          i.node.status !== "CANCELLED"
      );
      let count = leavesArr?.length;
      setBadgeForLeaveRequests(count);
      setLeavesList(leavesArr);
    } else {
      let leavesArr =
        leaveRequests &&
        leaveRequests.leaveRequestsForApproval &&
        leaveRequests.leaveRequestsForApproval.edges
          ? leaveRequests.leaveRequestsForApproval.edges
          : [];
      leavesArr = leavesArr.filter((i) => i.node.approvedOne !== true);
      setLeavesList(leavesArr);
    }
    // }
  }, [leaveRequestsTierTwo, leaveRequests]);

  const leaveRequestsArr = leavesList;

  const refundRequestsArr =
    refundRequests &&
    refundRequests.refundRequestWithApproval &&
    refundRequests.refundRequestWithApproval.edges
      ? refundRequests.refundRequestWithApproval.edges
      : [];

  const renderLeaveRequests = () => {
    return leaveRequestsArr.map((leaveRequest, index) => {
      let startDate = getDateKuwaitFormatted(leaveRequest.node.startDate);
      let endDate = getDateKuwaitFormatted(leaveRequest.node.endDate);
      return (
        <Link
          to={"leave/request/" + leaveRequest.node.id}
          className="dark_link"
          key={index}
        >
          <Card className="">
            <Card.Body>
              <span>{`${leaveRequest.node.user.firstName} ${leaveRequest.node.user.lastName}`}</span>
              <div className="d-flex  justify-content-between mt-3">
                <div>
                  From: <span> {getDateDay(startDate)} </span>
                  <span>{startDate}</span>
                </div>
                <div>
                  To: <span> {getDateDay(endDate)} </span>
                  <span>{endDate}</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      );
    });
  };
  const renderRefundRequests = () => {
    return refundRequestsArr.map((refundRequest, index) => {
      return (
        <Link
          to={"refund/request/" + refundRequest?.node?.id}
          className="dark_link"
          key={index}
        >
          <Card className="">
            <Card.Body>
              <div>
                <b>Refund Request</b>
              </div>
              <span>{`${
                refundRequest?.node?.paymentModel?.patient?.firstName
              } ${displayMiddleName(
                refundRequest?.node?.paymentModel?.patient?.middleName
              )} ${
                refundRequest?.node?.paymentModel?.patient?.lastName
              }`}</span>
              <div className="d-flex  flex-column mt-3">
                {refundRequest?.node?.appointment != null ? (
                  <div className="mb-1">
                    Appointment Date:{" "}
                    <span>
                      {" "}
                      {getDate(refundRequest?.node?.appointment?.start)}{" "}
                    </span>
                    <span>
                      {" "}
                      {getForamttedTime(
                        refundRequest?.node?.appointment?.start
                      )}{" "}
                    </span>
                  </div>
                ) : (
                  <span>
                    {refundRequest?.node?.paymentModel?.displayReason}
                  </span>
                )}
                <div className="mb-3">
                  Refund Requested:{" "}
                  <span> {getDate(refundRequest?.node?.created)} </span>
                  <span>
                    {" "}
                    {getForamttedTime(refundRequest?.node?.created)}{" "}
                  </span>
                </div>
                <div>
                  <span> {refundRequest?.node?.displayStatus} : </span>
                  <span>{refundRequest?.node?.amount} KWD</span>
                </div>
                {refundRequest?.node?.staffNotes ? (
                  <div>
                    <span> Staff Notes : </span>
                    <span>{refundRequest?.node?.staffNotes}</span>
                  </div>
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </Link>
      );
    });
  };

  const renderPatientReportRequests = () => {
    return patient_reports_obj.doctorPatientReports.edges.map(
      (report, index) => {
        let Requested = getDateKuwaitFormatted(report.node.created);
        return (
          <Link
            to={
              "/patient/report/request/update/" +
              report.node.id +
              "?doctorFill=true"
            }
            className="dark_link"
            key={index}
          >
            {/* get date here! */}
            <Card className="">
              <Card.Body>
                <span>{`${report.node.patient.firstName} ${displayMiddleName(
                  report.node.patient.middleName
                )}${report.node.patient.lastName}`}</span>
                <div className="d-flex  justify-content-between mt-3">
                  <div>
                    Requested On: <span> {getDateDay(Requested)} </span>
                    <br />
                    <span>{Requested}</span>
                  </div>
                  <div>
                    Report Language:{" "}
                    <span>
                      {" "}
                      {report.node.languageEn === true
                        ? "English"
                        : "Arabic"}{" "}
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Link>
        );
      }
    );
  };

  const renderMedicalReportRequests = () => {
    const medArr = medical_reports_obj?.patientReportsForApproval?.edges;
    const formApprovalsArr = formBlockApprovalsObj?.formBlockApprovals?.edges;
    const arr = medArr?.concat(formApprovalsArr ? formApprovalsArr : null);

    // eslint-disable-next-line array-callback-return
    return arr?.slice(0, 3).map((report, index) => {
      let Requested = getDateKuwaitFormatted(report?.node?.created);
      if (report?.node) {
        if (report.node.reportType) {
          return (
            <Link
              to={
                "/patient/report/request/update/" +
                report.node.id +
                "?doctorFill=true"
              }
              className="dark_link"
              key={index}
            >
              <Card className="">
                <Card.Body>
                  <span>{`${report.node.patient.firstName} ${displayMiddleName(
                    report.node.patient.middleName
                  )}${report.node.patient.lastName}`}</span>
                  <div className="d-flex  justify-content-between mt-3">
                    <div>
                      Requested On:{" "}
                      <span>
                        {" "}
                        {getDateDay(Requested)} {Requested}
                      </span>
                    </div>
                    <div>
                      Report Language:{" "}
                      <span>
                        {" "}
                        {report?.node?.languageEn === true
                          ? "English"
                          : "Arabic"}{" "}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          );
        } else {
          return (
            <Link
              to={{
                pathname: report.node.encounter
                  ? `/encounter/detail/${report.node.encounter.id}`
                  : `/patient/record/${
                      report.node.patient.id +
                      "/?identifier=" +
                      report?.node?.patient?.identifier
                    }`,
                state: { patientIdentifier: report.node.patient.identifier },
              }}
              className="dark_link"
              key={index}
            >
              <Card>
                <Card.Body>
                  <div>{report.node.formBlock.name}</div>
                  <div className="mt-3">
                    {" "}
                    Patient: {report.node.patient.firstName}{" "}
                    {report.node.patient.middleName}{" "}
                    {report.node.patient.lastName}
                  </div>
                  <div>
                    Practitioner:{" "}
                    {report.node.encounter
                      ? `${report.node.encounter.doctor.firstName} ${report.node.encounter.doctor.lastName}`
                      : null}
                  </div>
                  <div>
                    <span>
                      {getDate(
                        report.node.encounter && report.node.encounter.date
                          ? report.node.encounter.date
                          : report.node.formBlock.created
                      )}
                    </span>{" "}
                    <span>
                      {getForamttedTime(
                        report.node.encounter && report.node.encounter.date
                          ? new Date(report.node.encounter.date)
                          : new Date(report.node.formBlock.created)
                      )}
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Link>
          );
        }
      }
    });
  };

  const removeApprovedBlock = (block) => {
    if (getPermissionForAction("medical_record", "delete")) {
      deleteBlockApprovedNotification({ variables: { id: block.node.id } });
    }
    props.history.push(`/encounter/detail/${block.node.encounter.id}`);
  };

  const renderApprovedBlocks = () => {
    return approvedBlocks.approvedBlockNotifications.edges.map(
      (block, index) => {
        return (
          <Card
            className="card-approved-block"
            key={index}
            onClick={() => removeApprovedBlock(block)}
          >
            <Card.Body>
              <span>{`${block.node.patient.firstName} ${displayMiddleName(
                block.node.patient.middleName
              )}${block.node.patient.lastName}`}</span>
              <div className="mt-3">
                <div>
                  Created:{" "}
                  <span> {getDate(new Date(block.node.created))} </span>
                </div>
                <div>
                  Practitioner:{" "}
                  <span>
                    {" "}
                    {block.node.encounter.doctor.firstName}{" "}
                    {block.node.encounter.doctor.lastName}{" "}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        );
      }
    );
  };

  let canViewAppointments =
    userDetails &&
    userDetails.canViewAppointments &&
    userDetails.canViewAppointments.edges
      ? userDetails.canViewAppointments.edges.map((doc) => {
          return doc.node.identifier;
        })
      : null;
  let displayEventsList = upcomingAppointments?.filter((i) => {
    /* FIXME:  two issues here, 
    
    1. we are checking TOO late (the appointments should have not 
       been requested from the backend or should have been filtered out. 
    2. (Backend) How can there be appointments without a doctor?   
    3. I forgot, but there's a third thing wrong with this.
    */
    if (userDetails?.isSuperuser) {
      return i;
    } else {
      try {
        return canViewAppointments.includes(i.doctor.identifier) === true;
      } catch {
        return false;
      }
    }
  });

  return (
    <Base
      title={"Dashboard"}
      showHeader={true}
      isDashboard={true}
      rightContainerClass={loading ? "page_loader_base" : ""}
    >
      {/* Content */}
      {loading || leaveRequestsLoading ? (
        <Preloader />
      ) : (
        <>
          <Row>
            {displayEventsList &&
            displayEventsList.length > 0 &&
            has_app_permission_view ? (
              <Col sm={12} md={6} lg={4} xl={3} className="mb-4 col-pad">
                <div className="d-flex align-items-baseline justify-content-center">
                  <h6 className="text-center textGrey mr-1">
                    {" "}
                    <b> Upcoming Appointments </b>
                  </h6>
                  <Badge badge={badgeForUpcomingAppointments} />
                </div>
                {renderUpcomingAppointments(displayEventsList.slice(0, 3))}
                {displayEventsList.length > 3 ? (
                  <Link
                    to={
                      userDetails?.doctor?.doctorId
                        ? "/appointments" +
                          "?doctor=" +
                          userDetails?.doctor?.doctorId
                        : "/appointments"
                    }
                  >
                    <Button variant="link" block className="back ">
                      View All Appointments
                    </Button>
                  </Link>
                ) : null}
              </Col>
            ) : null}
            {getPermissionForAction("leave_request", "approve") &&
            badgeForLeaveRequests !== 0 ? (
              <Col sm={12} md={6} lg={4} xl={3} className="mb-4 col-pad">
                <div className="d-flex align-items-baseline justify-content-center">
                  <h6 className="text-center textGrey mr-1">
                    <b> Leave Requests</b>
                  </h6>
                  <Badge badge={badgeForLeaveRequests} />
                </div>
                {renderLeaveRequests()}
                {getPermissionForAction("leave_request_tier_two", "approve") ? (
                  leavesList?.length > 3 ? (
                    <Link to={"/manage/leave/requests"}>
                      <Button variant="link" block className="back ">
                        View All Leave Requests
                      </Button>
                    </Link>
                  ) : null
                ) : leaveRequests?.leaveRequestsForApproval?.totalCount > 3 ? (
                  <Link to={"/manage/leave/requests"}>
                    <Button variant="link" block className="back ">
                      View All Leave Requests
                    </Button>
                  </Link>
                ) : null}
              </Col>
            ) : null}
            {getPermissionForAction("patient_report", "add") &&
            patient_reports_obj &&
            patient_reports_obj.doctorPatientReports &&
            patient_reports_obj.doctorPatientReports.edges.length > 0 ? (
              <Col sm={12} md={6} lg={4} xl={3} className="mb-4 col-pad">
                <div className="d-flex align-items-baseline justify-content-center">
                  <h6 className="text-center textGrey mr-1">
                    <b>Patient Report Request</b>
                  </h6>
                  <Badge badge={badgeForReportRequests} />
                </div>
                {renderPatientReportRequests()}
                {patient_reports_obj?.doctorPatientReports?.totalCount > 3 ? (
                  <Link to={"/patient/report/request"}>
                    <Button variant="link" block className="back">
                      View All Report Requests
                    </Button>
                  </Link>
                ) : null}
              </Col>
            ) : null}
            {(getPermissionForAction("patient_report", "approve") ||
              getPermissionForAction("medical_record", "approve") ||
              getPermissionForAction(
                "user",
                "approve_approval_for_refund_request"
              )) &&
            badgeForApprovals +
              badgeForMedicalReports +
              badgeForRefundRequests >
              0 ? (
              <Col sm={12} md={6} lg={4} xl={3} className="mb-4 col-pad">
                <div className="d-flex align-items-baseline justify-content-center">
                  <h6 className="text-center textGrey mr-1">
                    <b>Pending Approvals</b>
                  </h6>
                  <Badge
                    badge={
                      badgeForApprovals +
                      badgeForMedicalReports +
                      badgeForRefundRequests
                    }
                  />
                </div>
                {getPermissionForAction("patient_report", "approve") ? (
                  <>
                    {renderMedicalReportRequests()}
                    {medical_reports_obj?.patientReportsForApproval?.edges
                      ?.length +
                      formBlockApprovalsObj?.formBlockApprovals?.edges.length >
                    3 ? (
                      <Link to={"/patient/blocksforapprovemenet"}>
                        <Button variant="link" block className="back ">
                          View All Approvals
                        </Button>
                      </Link>
                    ) : null}
                  </>
                ) : null}
                {getPermissionForAction(
                  "user",
                  "approve_approval_for_refund_request"
                ) ? (
                  <>
                    {renderRefundRequests()}
                    {refundRequests?.refundRequestWithApproval?.totalCount >
                    3 ? (
                      <Link to={"/manage/refund/requests"}>
                        <Button variant="link" block className="back ">
                          View All Refund Requests
                        </Button>
                      </Link>
                    ) : null}
                  </>
                ) : null}
              </Col>
            ) : null}

            {approvedBlocks?.approvedBlockNotifications?.edges.length > 0 ? (
              <Col sm={12} md={6} lg={3} xl={3} className="mb-4 col-pad">
                <div className="d-flex align-items-baseline justify-content-center">
                  <h6 className="text-center textGrey mr-1">
                    <b>Approved</b>
                  </h6>
                  <Badge badge={badgeForApproved} />
                </div>
                {renderApprovedBlocks()}
                {approvedBlocks?.approvedBlockNotifications?.totalCount > 3 ? (
                  <Link to={"/patient/approvedblocks"}>
                    <Button variant="link" block className="back ">
                      View All Approved Form Blocks
                    </Button>
                  </Link>
                ) : null}
              </Col>
            ) : null}
          </Row>
        </>
      )}
    </Base>
  );
}
export default Dashboard;
