import {
  Button,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Spinner,
  Table,
  Accordion,
} from "react-bootstrap";
import { Formik } from "formik";
import Base from "./base.js";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useSnackbar } from "react-simple-snackbar";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getDate,
  getPermissionForAction,
  getPatientsAge,
  generateUID,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
  removeAllBefore,
  success_options,
} from "../Common/helpers";
import FormBlock from "../component/DynamicFormBlock/formBlock";
import Notes from "../component/DynamicFormBlock/notes";
import CustomModals from "../component/Modal/modal";
import PatientFileUploadModal from "./patientsFileUploadModal";
import LabsComponent from "./Labs/LabsComponent";
import { useTranslation } from "react-i18next";
import { urlBackend } from "../index";
import { EditorState } from "draft-js";
import * as Sentry from "@sentry/browser";
import { deepCopy } from "../Common/helpers";

const DELETE_REFERRAL = gql`
  mutation deleteReferral($id: ID!) {
    deleteReferral(id: $id) {
      deleted
    }
  }
`;

function EncounterDetails(props) {
  const { t } = useTranslation();
  const identifier = props.match.params.encounterId;
  // const patientId = props.match.params.patientId;
  const [openSnackbar] = useSnackbar(error_options);
  const [successSnackbar] = useSnackbar(success_options);
  const formikRef = useRef();
  const formikRefNotes = useRef();
  const formikRefPrint = useRef();
  const [showModalNotes, setShowModalNotes] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [followupModal, setFollowupModal] = useState(false);
  const [referralModal, setReferralModal] = useState(false);

  const [activeNotesID, setActiveNotesID] = useState(null);
  const [doctorSession, setDoctorSession] = useState(null);
  const [followUpmonths, setFollowUpMonths] = useState(null);
  const [followUpWeeks, setFollowUpWeeks] = useState(null);
  const [numberOfFollowUps, setNumberOfFollowUps] = useState(null);
  const [requestedForms, setRequestedForms] = useState([]);
  const [refFormElemList, setRefFormElemList] = useState({ keys: [] });
  const [refFormElemValues, setRefFormElemValues] = useState({ values: [] });
  const [editNoteTitleObj, setEditNoteTitle] = useState(false);
  const [expandNotesModal, setExpandNotesModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [patientIdentifier, setPatientIdentifier] = useState(null);
  const [printEncounterModal, setPrintEncounterModal] = useState(false);
  const [
    previousEncounterPrescriptionBlockData,
    setPreviousEncounterPrescriptionBlockData,
  ] = useState(null);
  const [previousEncounterNotesData, setPreviousEncounterNotesData] =
    useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedDoctorSeenBy, setSelectedDoctorSeenBy] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [openCheckboxId, setOpenCheckboxId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [blockPrintInstances, setBlockPrintInstances] = useState([]);
  const [initialValuesPrint, setInitialValuesPrint] = useState({});
  const [encounterDataValues, setEncounterDataValues] = useState(null);
  const [notesValue, setNotesValue] = useState("");
  const [dataPatientVital, setDataPatientVital] = useState(null);
  const [doctors_obj, setDoctorObj] = useState(null);

  let returnAllSectionPermissions = true;
  const referral_permissions = getPermissionForAction(
    "referral",
    "",
    returnAllSectionPermissions
  );
  const patient_vital_permission = getPermissionForAction(
    "patient_vital",
    "",
    returnAllSectionPermissions
  );
  const encounter_permission = getPermissionForAction(
    "encounter",
    "",
    returnAllSectionPermissions
  );
  const medical_record_permission = getPermissionForAction(
    "medical_record",
    "",
    returnAllSectionPermissions
  );
  const encounternote_permission = getPermissionForAction(
    "encounternote",
    "",
    returnAllSectionPermissions
  );
  const [blockPrintData, setBlockData] = useState(null);
  const lab_request_permission = getPermissionForAction(
    "lab_request",
    "",
    returnAllSectionPermissions
  );
  const [showModal, setShowModal] = useState(false);
  const [vitalDataState, setVitalDataState] = useState({ edges: [] });
  const [patientId, setPatientId] = useState(null);
  const [mainMedicalBlockPatient, setMainMedicalBlockPatient] = useState(null);
  const [vitalHeight, setVitalHeight] = useState("");
  const [vitalWeight, setVitalWeight] = useState("");
  const [vitalO2, setVitalO2] = useState("");
  const [vitalBP, setVitalBP] = useState("");
  const [vitalWaistCirc, setVitalWaistCirc] = useState("");
  const [vitalTemp, setVitalTemp] = useState("");
  const [vitalResp, setVitalResp] = useState("");
  const [vitalBMI, setVitalBMI] = useState("");
  const [vitalBMIPer, setVitalBMIPer] = useState("");
  const [vitalPulse, setVitalPulse] = useState("");
  const [encounterPrintParams, setEncounterPrintParams] = useState("");
  const [previousNotes, setPreviousNotes] = useState("");
  const [editorReadOnly, setEditorReadOnly] = useState(true);
  const [savedStateBe, setSavedStateBe] = useState(null);
  const [time, setTime] = React.useState(null);
  const [manuallySaved, setManuallySaved] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState("");
  if (encounter_permission.includes("change")) {
    encounter_permission.push("edit");
  }

  useEffect(() => {
    if (!printEncounterModal) {
      setOpenCheckboxId(null);
      setInitialValuesPrint({});
      setBlockPrintInstances([]);
    }
  }, [printEncounterModal]);

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data: userDet = {} } = useQuery(USER_DETAILS);
  var userDetails = userDet.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }
  let canViewAppointments =
    userDetails &&
    userDetails.canViewAppointments &&
    userDetails.canViewAppointments.edges
      ? userDetails.canViewAppointments.edges.map((doc) => {
          return doc.node.identifier;
        })
      : null;

  const GENERTE_ENCOUNTER_PRINT_CODE = gql`
    mutation generateEncounterPrintCode($id: ID!) {
      generateEncounterPrintCode(id: $id) {
        code
      }
    }
  `;

  // DELETE_APPOINTMENT
  const [generateEncounterPrintCode] = useMutation(
    GENERTE_ENCOUNTER_PRINT_CODE,
    {
      onCompleted: ({ generateEncounterPrintCode }) => {
        let oneTimeCode = generateEncounterPrintCode.code;
        if (encounterPrintParams) {
          setTimeout(() => {
            window.open(
              urlBackend +
                "pdf/reports/encounter/report/" +
                data.encounter.staticId +
                "/" +
                oneTimeCode +
                "/" +
                encounterPrintParams
            );
          }, 300);
        } else if (blockPrintData) {
          setTimeout(() => {
            window.open(
              urlBackend +
                "pdf/reports/prescription/report/" +
                data.encounter.staticId +
                "/" +
                oneTimeCode +
                "/"
            );
          }, 300);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("GENERTE_ENCOUNTER_PRINT_CODE error " + e);
        }
        if (e.message) {
          openSnackbar(e.message, [SNACK_DURATION]);
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  const REQUEST_ENCOUNTER_TYPES = gql`
    query {
      encounterTypes {
        edges {
          node {
            id
            name
            dynamicForm {
              id
              name
            }
          }
        }
      }
    }
  `;

  const REQUEST_DYNAMIC_FORMS = gql`
    query {
      dynamicForms(formType: "intake_form") {
        edges {
          node {
            staticId
            id
            name
          }
        }
      }
    }
  `;

  const REQUEST_LOCATION = gql`
    query {
      listEventLocation {
        edges {
          node {
            title
            id
          }
        }
      }
    }
  `;
  const REQUEST_DOCTOR = gql`
    query {
      doctors(forReferral: true, active: true) {
        edges {
          node {
            firstName
            lastName
            identifier
            id
            eventType {
              edges {
                node {
                  id
                  title {
                    name
                  }
                  duration
                  buffer
                  price
                  eventType {
                    id
                    name
                  }
                  eventLocation {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_PREV_ENCOUNTER_PRESCRIPTION = gql`
    query encounter($encounter_identifier: ID!) {
      encounter(id: $encounter_identifier) {
        id
        notes {
          edges {
            node {
              name
              text
              id
            }
          }
        }
      }
    }
  `;
  const REQUEST_ENCOUNTER = gql`
  query {
    encounter(id:"${identifier}"){
      id
      data
      date
      patient{
        id
        identifier
        firstName
        middleName
        lastName
        phone
        email
        gender
        displayGender
        dob
        civilId
     }
     staticId
     doctor{
      id
      firstName
      lastName
      eventType {
        edges {
          node {
            id
            title{
              name
            }
            duration
            buffer
            price
            eventType {
              id
              name
            }
            eventLocation {
              id
              title
            }
          }
        }
      }
    }
    notes{
      edges{
        node{
          name
          text
          id
        }
      }
    }
    facility{
      title
      id
    }
    referrals{
      edges {
        node {
          id
          followUp
          referredDoctorSession{
            title {
              name
            }
            doctor{
              firstName
              middleName
              lastName
            }
          }
          recommendedDate
        }
      }

    }
    appointment{
      id
      identifier
      start
      intakeForms{
        edges{
          node{
            id
            dynamicForm{
              name
            }
          }
        }
      }
      doctor{
        identifier
      }
      doctorEventType{
        id
        eventType{
          name
        }
      }
    }
      encounterType{
        id
        name
        dynamicForm {
          name
          id
          formType
          dynamicFormBlocks {
            edges {
              node {
                id
                formBlock {
                  name
                  id
                  staticId
                  code
                  isPrescription
                  blockFormFields {
                    edges {
                      node {
                        formField {
                          id
                          name
                          subText
                          fieldName
                          variant
                          hideLabel
                          staticId
                          defaultBooleanVariant
                          options {
                            edges {
                              node {
                                id
                                staticId
                                value
                                default
                              }
                            }
                          }
                        }
                        formBlock {
                          id
                        }
                        required
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
     }
   }
`;
  const CREATE_UPDATE_ENCOUNTER_NOTES = gql`
    mutation createUpdateEncounterNote(
      $text: String!
      $name: String!
      $encounter: ID!
      $id: ID
    ) {
      createUpdateEncounterNote(
        input: { text: $text, name: $name, encounter: $encounter, id: $id }
      ) {
        obj {
          id
          name
          text
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const UPDATE_ENCOUNTER = gql`
    mutation updateEncounter(
      $patient: ID!
      $doctor: ID!
      $facility: ID!
      $date: DateTime
      $id: ID!
    ) {
      updateEncounter(
        input: {
          patient: $patient
          doctor: $doctor
          facility: $facility
          date: $date
          id: $id
        }
      ) {
        obj {
          id
          date
          appointment {
            doctorEventType {
              id
              eventType {
                name
              }
            }
          }
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const DELETE_ENCOUNTER = gql`
    mutation deleteEncounter($id: ID!) {
      deleteEncounter(id: $id) {
        deleted
      }
    }
  `;

  const CREATE_UPDATE_REFERRAL = gql`
    mutation createUpdateReferral(
      $id: ID
      $patient: ID!
      $doctor: ID!
      $doctor_session: ID!
      $encounter: ID!
      $title: String
      $description: String
      $dynamicForms: [ID]
      $followUp: Boolean
      $recommendedDate: DateTime
      $numberLeft: Int!
    ) {
      createUpdateReferral(
        input: {
          id: $id
          patient: $patient
          referredDoctor: $doctor
          referredDoctorSession: $doctor_session
          encounter: $encounter
          title: $title
          description: $description
          dynamicForms: $dynamicForms
          followUp: $followUp
          recommendedDate: $recommendedDate
          numberLeft: $numberLeft
        }
      ) {
        obj {
          id
          created
          modified
          title
          description
          followUp
        }
        errors {
          field
          messages
        }
      }
    }
  `;

  const REQUEST_PATIENT_VITAL = gql`
  query {
    patients(identifier:"${patientId}"){
      edges {
        node {
          prescriptionData{
            id
          }
          encounters{
            edges{
              node{
                id
                date
                data
                encounterType{
                  id
                  name
                }
                notes{
                  edges{
                    node{
                      id
                    }
                  }
                }
                appointment{
                  start
                  status
                } 
              }
            }
          }
          formData{
            id
            data
            dynamicFormData
            commonFormBlocks {
              dynamicFormBlocks {
                edges {
                  node {
                    id
                    formBlock {
                      name
                      id
                      staticId
                      isMedication
                      code
                      blockFormFields {
                        edges {
                          node {
                            formField {
                              id
                              name
                              subText
                              fieldName
                              variant
                              hideLabel
                              staticId
                              defaultBooleanVariant
                              options {
                                edges {
                                  node {
                                    id
                                    staticId
                                    value
                                    default
                                  }
                                }
                              }
                            }
                            formBlock {
                              id
                            }
                            required
                            width
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          
          vitals{
            edges{
              node{
                id
                created
                modified
                bmi
                bp
                height
                weight
                waistCircumference
                pulse
                temperature
                bmiPercentile
                pain
                respirationRate
                o2Saturation
                headCircumference
              }
            }
          }
        }
      }
    }
  }
  `;

  const [GetPreviousEncounterPrescBlock] = useLazyQuery(
    REQUEST_PREV_ENCOUNTER_PRESCRIPTION,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
      onError: ({ networkError }, e) => {
        Sentry.setContext("error", networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException(
            "REQUEST_PREV_ENCOUNTER_PRESCRIPTION error " + networkError
          );
        }
        if (networkError?.result) {
          openSnackbar(
            networkError.result?.errors.map((error, index) => {
              return error.message;
            }),
            [SNACK_DURATION]
          );
        } else {
          let errorMsg = concatAllErrors(e?.graphQLErrors);
          let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
          openSnackbar(msgToDisplay, [SNACK_DURATION]);
        }
      },
    }
  );

  // DELETE_REFERRAL
  const [deleteReferral] = useMutation(DELETE_REFERRAL, {
    onCompleted: ({ deleteReferral }) => {
      if (deleteReferral?.errors && deleteReferral?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deleteReferral?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        successSnackbar("Successfully deleted.", [SNACK_DURATION]);
      }
    },
    refetchQueries: [{ query: REQUEST_ENCOUNTER, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_REFERRAL error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const deleteRefFunc = (id) => {
    if (window.confirm("Delete Referral?")) {
      let val = {};
      val["id"] = id;
      deleteReferral({ variables: val });
    }
  };

  useEffect(() => {
    if (showModalNotes) {
      let notes_expand = document.getElementsByClassName("modal-90w");
      if (notes_expand && notes_expand.length > 0) {
        notes_expand[0].classList.add("opacityhalf");
      }
    } else {
      let notes_expand = document.getElementsByClassName("modal-90w");
      if (notes_expand && notes_expand.length > 0) {
        notes_expand[0].classList.remove("opacityhalf");
      }
    }
  }, [showModalNotes]);

  const updatePatientMedicalData = (patient_record) => {
    if (patient_record?.node.formData) {
      let medicalInstancesToAdd = [];
      let medicalBlockStaticId =
        patient_record?.node.formData?.commonFormBlocks.dynamicFormBlocks.edges.find(
          (i) => i.node.formBlock.isMedication
        );
      if (medicalBlockStaticId?.node.formBlock.isMedication) {
        medicalBlockStaticId = medicalBlockStaticId?.node.formBlock.staticId;
        let dataToCheck = patient_record?.node.formData?.dynamicFormData;
        if (!dataToCheck) {
          dataToCheck = patient_record?.node.formData?.data;
        }
        if (dataToCheck) {
          let dynamicDatakeys = Object.keys(dataToCheck);
          if (dynamicDatakeys?.length > 0) {
            let mainMedicalInstances = dynamicDatakeys.filter(
              (i) => i.indexOf("main_medication_") > -1
            );
            mainMedicalInstances.map((i) => {
              let enc_static_id = null;
              if (i.indexOf("main_medication") > -1) {
                enc_static_id = i.split("encounter_")[1];
                enc_static_id = enc_static_id.split("_block_")[0];
              }
              let inst = dataToCheck[i];
              if (inst) {
                let new_form_block_instances = inst.form_block_instances.map(
                  (j) => {
                    let newObj = JSON.parse(JSON.stringify(j));
                    newObj["is_from_encounter"] = true;
                    newObj["enc_static_id"] = enc_static_id;
                    newObj["form_block_key"] = i;
                    return newObj;
                  }
                );
                medicalInstancesToAdd.push(...new_form_block_instances);
              }
            });
            if (medicalBlockStaticId) {
              let prevJsonData = patient_record?.node.formData?.data
                ? patient_record?.node.formData?.data
                : {};
              let jsonDataClone = JSON.parse(JSON.stringify(prevJsonData));
              let prevMedDataInstances = [];
              if (
                jsonDataClone.hasOwnProperty("block_" + medicalBlockStaticId)
              ) {
                prevMedDataInstances =
                  jsonDataClone["block_" + medicalBlockStaticId]
                    .form_block_instances;
              }
              if (medicalInstancesToAdd.length > 0) {
                prevMedDataInstances.push(...medicalInstancesToAdd);
              }
              if (
                jsonDataClone.hasOwnProperty("block_" + medicalBlockStaticId)
              ) {
                jsonDataClone["block_" + medicalBlockStaticId][
                  "form_block_instances"
                ] = prevMedDataInstances;
              } else {
                jsonDataClone["block_" + medicalBlockStaticId] = {
                  form_block_instances: prevMedDataInstances,
                };
              }
              let medicationFormBlock =
                patient_record.node.formData.commonFormBlocks.dynamicFormBlocks.edges.filter(
                  (i) => i.node.formBlock.isMedication
                );
              let currMed = {
                medicationFormBlock: medicationFormBlock,
                jsonDataMedical: jsonDataClone,
                patientFormData: patient_record.node.formData.id,
                is_prev_medication_block: false,
                is_current_medication_block: true,
              };
              let prevMed = {
                medicationFormBlock: medicationFormBlock,
                jsonDataMedical: jsonDataClone,
                patientFormData: patient_record.node.formData.id,
                is_prev_medication_block: true,
              };
              setMainMedicalBlockPatient([prevMed, currMed]);
              return;
            }
          }
        } else {
          let medicationFormBlock =
            patient_record.node.formData.commonFormBlocks.dynamicFormBlocks.edges.filter(
              (i) => i.node.formBlock.isMedication
            );
          let currMed = {
            medicationFormBlock: medicationFormBlock,
            jsonDataMedical: null,
            patientFormData: patient_record.node.formData.id,
            is_prev_medication_block: false,
            is_current_medication_block: true,
          };
          let prevMed = {
            medicationFormBlock: medicationFormBlock,
            jsonDataMedical: null,
            patientFormData: patient_record.node.formData.id,
            is_prev_medication_block: true,
          };
          setMainMedicalBlockPatient([prevMed, currMed]);
        }
      }
    }
  };

  const [getVitals, { data: rawDataPatientVital }] = useLazyQuery(
    REQUEST_PATIENT_VITAL,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ patients }) => {
        if (patients?.errors && patients?.errors?.length > 0) {
          let error_messages_string = getErrorMessage(patients?.errors);
          openSnackbar(error_messages_string, [SNACK_DURATION]);
        } else {
          if (patients?.edges[0]?.node?.vitals) {
            setVitalDataState(patients?.edges[0].node.vitals);
          }
          const patient_record = patients?.edges[0];
          updatePatientMedicalData(patient_record);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_VITAL error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  useEffect(() => {
    setDataPatientVital(deepCopy(rawDataPatientVital));
  }, [rawDataPatientVital]);

  const patient_record = dataPatientVital?.patients?.edges[0];
  const MAIN_RECORD_FORMS = patient_record?.node?.formData?.commonFormBlocks;

  useEffect(() => {
    if (patient_record) {
      let encountersListForPatient = patient_record?.node?.encounters?.edges;
      const ENCOUNTER_LIST = encountersListForPatient
        ? encountersListForPatient.sort(function compare(a, b) {
            var dateA = a.node?.appointment
              ? new Date(a.node?.appointment.start)
              : new Date(a.node?.date);
            var dateB = b.node?.appointment
              ? new Date(b.node?.appointment.start)
              : new Date(b.node?.date);
            return dateB - dateA;
          })
        : [];
      let currentEncIndex = ENCOUNTER_LIST.findIndex(
        (i) => i.node.id === identifier
      );
      if (currentEncIndex <= ENCOUNTER_LIST.length) {
        let prevEncList = removeAllBefore(ENCOUNTER_LIST, currentEncIndex + 1);
        let prescJson = null;
        // eslint-disable-next-line array-callback-return
        let prevCompletedEncWithPresc = prevEncList.find((i) => {
          let hasPrescBlock = i.node.data ? Object.keys(i.node.data) : null;
          if (hasPrescBlock?.length > 0) {
            // eslint-disable-next-line array-callback-return
            hasPrescBlock.map((j) => {
              if (
                i.node.data[j]?.form_block_name
                  ?.toLocaleLowerCase()
                  .indexOf("prescription") > -1
              ) {
                prescJson = i.node.data[j];
                return j;
              }
            });
          }
          if (i?.node?.appointment?.status === "COMPLETED" && hasPrescBlock) {
            return i;
          }
        });

        if (prevCompletedEncWithPresc?.node?.id && prescJson) {
          setPreviousEncounterPrescriptionBlockData(prescJson);
        }

        // eslint-disable-next-line array-callback-return
        let prevCompletedEncWithNotes = prevEncList.find((i) => {
          let hasNotesBlock = i?.node?.notes?.edges?.length > 0;
          if (
            i?.node?.appointment?.status === "COMPLETED" &&
            i?.node?.encounterType?.id === data?.encounter?.encounterType?.id &&
            hasNotesBlock
          ) {
            return i;
          }
        });

        if (prevCompletedEncWithNotes?.node?.id) {
          let prevEncIdToFetch = prevCompletedEncWithNotes?.node?.id;
          if (prevEncIdToFetch) {
            GetPreviousEncounterPrescBlock({
              variables: { encounter_identifier: prevEncIdToFetch },
            }).then((res) => {
              let prevEncounterNotes = res?.data?.encounter?.notes?.edges;
              if (
                prevEncounterNotes?.length > 0 &&
                prevEncounterNotes[0]?.node?.text
              ) {
                setPreviousEncounterNotesData(
                  prevEncounterNotes[0]?.node?.text
                );
              }
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_record]);

  useEffect(() => {
    if (props.location.state?.vitalDataState) {
      setVitalDataState(props.location.state.vitalDataState);
    }
    if (patientId) {
      getVitals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const [getEncounter, { data }] = useLazyQuery(REQUEST_ENCOUNTER, {
    fetchPolicy: "network-only",
    onCompleted: ({ encounter }) => {
      if (encounter === null) {
        props.history.push("/404-not-found");
      }
      if (
        encounter?.notes?.edges?.length > 0 &&
        encounter?.notes?.edges[0]?.node?.text
      ) {
        setPreviousNotes(encounter?.notes?.edges[0]?.node?.text);
        setNotesValue(encounter?.notes?.edges[0]?.node?.text);
      }
      if (!patientId) {
        setPatientId(encounter?.patient.identifier);
      } else {
        getVitals();
      }
    },
    refetchQueries: [
      { query: REQUEST_PATIENT_VITAL, fetchPolicy: "network-only" },
    ],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_ENCOUNTER error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    getEncounter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // GET ENCOUNTER_TYPES DATA
  const { data: encounter_types_obj = null } = useQuery(
    REQUEST_ENCOUNTER_TYPES,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_ENCOUNTER_TYPES error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  // GET ENCOUNTER_TYPES DATA
  const { data: dynamic_forms_obj = null } = useQuery(REQUEST_DYNAMIC_FORMS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DYNAMIC_FORMS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  // GET REQUEST_LOCATION DATA
  const { data: locations_obj = null } = useQuery(REQUEST_LOCATION, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_LOCATION error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  // GET DOCTOR DATA
  const { data: rawDoctorObj = null } = useQuery(REQUEST_DOCTOR, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_DOCTOR error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    setDoctorObj(deepCopy(rawDoctorObj));
  }, [rawDoctorObj]);

  doctors_obj?.doctors?.edges.sort((a, b) =>
    a?.node?.firstName?.localeCompare(b?.node?.firstName)
  );

  const [createUpdateEncounterNotesAutoSave] = useMutation(
    CREATE_UPDATE_ENCOUNTER_NOTES,
    {
      onCompleted: (data) => {
        try {
          const { createUpdateEncounterNote } = data;
          if (
            createUpdateEncounterNote?.errors &&
            createUpdateEncounterNote?.errors?.length > 0
          ) {
            let error_messages_string = getErrorMessage(
              createUpdateEncounterNote?.errors
            );
            openSnackbar(error_messages_string, [SNACK_DURATION]);
          } else if (createUpdateEncounterNote.obj) {
            if (createUpdateEncounterNote?.obj?.text) {
              setPreviousNotes(createUpdateEncounterNote?.obj?.text);
            }
          }
        } catch (e) {
          console.error("An error occurred:", e);
          openSnackbar("An unexpected error occurred.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("CREATE_UPDATE_ENCOUNTER_NOTES error " + e);
        }

        openSnackbar("Your notes was not saved.", [SNACK_DURATION]);
      },
    }
  );

  const [createUpdateEncounterNote] = useMutation(
    CREATE_UPDATE_ENCOUNTER_NOTES,
    {
      onCompleted: (data) => {
        try {
          const { createUpdateEncounterNote } = data;
          if (
            createUpdateEncounterNote?.errors &&
            createUpdateEncounterNote?.errors?.length > 0
          ) {
            let error_messages_string = getErrorMessage(
              createUpdateEncounterNote?.errors
            );
            openSnackbar(error_messages_string, [SNACK_DURATION]);
          } else if (createUpdateEncounterNote.obj) {
            if (createUpdateEncounterNote?.obj?.text) {
              setPreviousNotes(createUpdateEncounterNote?.obj?.text);
            }
            setTimeout(() => {
              setActiveNotesID(null);
              setEditNoteTitle(null);
            }, 300);
            if (encounterDataValues) {
              onSubmit(encounterDataValues);
            }
            // Show success message
            successSnackbar("Your notes have been saved successfully.", [1000]);
          }
        } catch (e) {
          console.error("An error occurred:", e);
          openSnackbar("An unexpected error occurred.", [SNACK_DURATION]);
        }
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("CREATE_UPDATE_ENCOUNTER_NOTES 1 error " + e);
        }

        openSnackbar("Your notes was not saved.", [SNACK_DURATION]);
      },
    }
  );

  // CREATE ENCOUNTER
  const [updateEncounter, { loading }] = useMutation(UPDATE_ENCOUNTER, {
    onCompleted: ({ updateEncounter }) => {
      if (updateEncounter?.errors && updateEncounter?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(updateEncounter?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (updateEncounter.obj) {
        props.history.push(
          "/patient/record/" +
            data?.encounter?.patient?.id +
            "/?identifier=" +
            data?.encounter?.patient?.identifier
        );
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("UPDATE_ENCOUNTER error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // DELETE_ENCOUNTER ENCOUNTER
  const [deleteEncounter] = useMutation(DELETE_ENCOUNTER, {
    onCompleted: ({ deleteEncounter }) => {
      if (deleteEncounter?.errors && deleteEncounter?.errors?.length > 0) {
        let error_messages_string = getErrorMessage(deleteEncounter?.errors);
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (deleteEncounter.deleted) {
        props.history.push(
          "/patient/record/" +
            data?.encounter?.patient?.id +
            "/?identifier=" +
            data?.encounter?.patient?.identifier
        );
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_ENCOUNTER error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // CREATE REFERRAL
  const [createUpdateReferral] = useMutation(CREATE_UPDATE_REFERRAL, {
    onCompleted: ({ createUpdateReferral }) => {
      if (
        createUpdateReferral?.errors &&
        createUpdateReferral?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          createUpdateReferral?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else {
        if (createUpdateReferral?.obj?.followUp) {
          successSnackbar("Follow was created.", [SNACK_DURATION]);
        } else {
          successSnackbar("Referral was created.", [SNACK_DURATION]);
        }
        setNumberOfFollowUps(null);
        setFollowUpWeeks(null);
        setFollowUpMonths(null);
        setFollowupModal(false);
        setDoctorSession(null);
        setRefFormElemValues({ values: [] });
        setReferralModal(false);
        setRefFormElemList({ keys: [] });
      }
    },
    refetchQueries: [{ query: REQUEST_ENCOUNTER, fetchPolicy: "network-only" }],
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("CREATE_UPDATE_REFERRAL error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  const onSubmit = (values) => {
    let val = {
      doctor: values.doctor,
      facility: values.facility,
      id: data?.encounter?.id,
      date: values.encounter_date ? values.encounter_date.toISOString() : "",
      patient: data?.encounter?.patient.id,
    };
    updateEncounter({ variables: val });
  };

  const onSubmitNotes = (values, encDataVal, isAutoSave) => {
    clearTimeout(time);
    let val = {
      // make possible to set empty notes
      text: values.notes_text,
      name: "",
      encounter: data?.encounter?.id,
      encounterDataValues: values,
    };

    if (val.text && val.encounter) {
      if (isAutoSave) {
        if (!manuallySaved) {
          createUpdateEncounterNotesAutoSave({ variables: val });
        }
      } else {
        setManuallySaved(true);
        createUpdateEncounterNote({ variables: val });
        setTimeout(() => {
          setManuallySaved(false);
        }, 25000);
      }
    } else {
      if (encDataVal) {
        onSubmit(encDataVal);
      }
    }
  };

  const setEncounterDate = (info) => {
    formikRef.current.setFieldValue("encounter_date", info);
  };

  var initialValues = {
    doctor: "",
    encounter_type: "",
    facility: "",
    has_appointment: false,
    encounter_date: new Date(),
  };
  if (data && data.encounter) {
    var selected_doc =
      doctors_obj && doctors_obj.doctors && doctors_obj.doctors.edges.length > 0
        ? doctors_obj.doctors.edges.find(
            (i) => i.node.id === data?.encounter?.doctor.id
          )
        : "";
    let selected_enc_type =
      encounter_types_obj &&
      encounter_types_obj.encounterTypes &&
      encounter_types_obj.encounterTypes.edges.length > 0
        ? encounter_types_obj.encounterTypes.edges.find(
            (i) => i.node.id === data?.encounter?.encounterType.id
          )
        : "";

    let selected_facility =
      locations_obj &&
      locations_obj.listEventLocation &&
      locations_obj.listEventLocation.edges.length > 0
        ? locations_obj.listEventLocation.edges.find(
            (i) => i.node.id === data?.encounter?.facility.id
          )
        : "";

    initialValues["doctor"] = selected_doc ? selected_doc.node.id : "";
    initialValues["encounter_type"] = selected_enc_type
      ? selected_enc_type.node.id
      : "";
    initialValues["facility"] = selected_facility
      ? selected_facility.node.id
      : "";
    initialValues["notes"] = data?.encounter?.notes
      ? data?.encounter?.notes.edges
      : [];
    initialValues["encounter_date"] =
      data && data.encounter && data?.encounter?.date
        ? new Date(data?.encounter?.date)
        : new Date();
  }

  // // To set the selected doctor object since encounter already has a doctor
  useEffect(() => {
    if (selected_doc) {
      setSelectedDoctorSeenBy(selected_doc);
    }
  }, [selected_doc]);

  let initialValuesNotes = {};
  if (editNoteTitleObj) {
    initialValuesNotes["notes_name"] = editNoteTitleObj.name;
    initialValuesNotes["notes_text"] = editNoteTitleObj.text;
  }

  const onSubmitInlineRef = async () => {
    // Create an array of promises by mapping over refFormElemValues.values
    const promises = refFormElemValues.values.map(async (refItem) => {
      let val = {
        doctor: refItem.doctor,
        doctor_session: refItem.session,
        patient: data && data.encounter ? data?.encounter?.patient.id : null,
        encounter: data && data.encounter ? data?.encounter?.id : null,
        recommendedDate: null,
      };
      if (refItem.numberOfRefs > 1) {
        val["numberLeft"] = refItem.numberOfRefs - 1;
      } else {
        val["numberLeft"] = 0;
      }
      // Use await to wait for createUpdateReferral to complete
      await createUpdateReferral({ variables: val });
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // // Now you can set your state variable to true
    // setState(true);
  };

  const onSubmitReferral = (docEventTypeId) => {
    let val = {
      doctor: data && data.encounter ? data?.encounter?.doctor.id : null,
      doctor_session: docEventTypeId,
      patient: data && data.encounter ? data?.encounter?.patient.id : null,
      encounter: data && data.encounter ? data?.encounter?.id : null,
    };
    if (followUpmonths || followUpWeeks) {
      let recommended_date = new Date();
      if (followUpmonths) {
        recommended_date = recommended_date.setMonth(
          recommended_date.getMonth() + parseInt(followUpmonths)
        );
      }
      if (followUpWeeks) {
        recommended_date.setDate(
          recommended_date.getDate() + parseInt(followUpWeeks) * 7
        );
      }
      val["recommendedDate"] = new Date(recommended_date);
      if (requestedForms) {
        val["dynamicForms"] = requestedForms;
        val["followUp"] = true;
      }
      if (numberOfFollowUps > 1) {
        val["numberLeft"] = numberOfFollowUps - 1;
      } else {
        val["numberLeft"] = 0;
      }
      createUpdateReferral({ variables: val });
    }
  };

  const onSubmitSingleNote = (values) => {
    let obj_keys = Object.keys(values);
    let id = obj_keys.find((i) => i.indexOf("single_note") > -1);
    if (id) {
      id = id.split("single_note")[1];
    }
    let val = {
      text: values["single_note" + id],
      name: values.notes_name,
      encounter: data?.encounter?.id,
      id: id,
    };

    if (val.encounter) {
      createUpdateEncounterNote({ variables: val });
    }
  };

  const onSubmitPrint = (values) => {
    let hasNotes = values["notes"] === true ? true : false;
    let hasPrescriptions = values["prescription"] === true ? true : false;
    let params = "?";
    if (hasNotes) {
      params += "notes=True";
    }
    if (hasPrescriptions) {
      if (hasNotes) {
        params += "&";
      }
      params += "prescription=True";
    }
    setEncounterPrintParams(params);
    let variables = { id: data.encounter.id };
    generateEncounterPrintCode({ variables });
    setBlockPrintInstances(values);
    setBlockData(null);
  };

  var DYNAMIC_FORM = null;
  if (
    data &&
    data.encounter &&
    data?.encounter?.encounterType &&
    data?.encounter?.encounterType &&
    data?.encounter?.encounterType.dynamicForm
  ) {
    DYNAMIC_FORM = data?.encounter?.encounterType.dynamicForm;
  }

  const deleteEncounterCall = () => {
    setPatientIdentifier(data?.encounter?.patient.identifier);
    if (window.confirm("Delete Encounter?")) {
      deleteEncounter({ variables: { id: data?.encounter?.id } });
    }
  };

  const getDoctorSessionDetails = (session) => {
    if (selectedDoctorSeenBy) {
      let selected_session = selectedDoctorSeenBy.node.eventType.edges.find(
        (i) => i.node.id === session
      );
      if (selected_session) {
        return (
          <>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Location: {selected_session.node.eventLocation.title}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Type: {selected_session.node.eventType.name}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className="doctor_session">
              Duration: {selected_session.node.duration} Mins
            </Form.Control.Feedback>
          </>
        );
      }
    }
  };

  const handleDoctor = (event, elId) => {
    let formVal = {};
    formVal["doctor"] = event.target.value;
    formVal["id"] = elId;
    formVal["numberOfRefs"] = "";
    setRefFormElemValues((refFormElemValues) => ({
      values: [
        ...refFormElemValues.values.filter((i) => i.id !== elId),
        formVal,
      ],
    }));
    let act_doc = doctors_obj.doctors.edges.find(
      (i) => i.node.id === event.target.value
    );
    let selectedDoc = selectedDoctor.filter((i) => i.id !== elId);
    selectedDoc.push({ id: elId, doctor: act_doc, session: null });
    setSelectedDoctor(selectedDoc);
  };

  const handleSession = (event, elId) => {
    let formVal = {};
    let currRef = refFormElemValues.values.find((i) => i.id === elId);
    formVal["session"] = event.target.value;
    formVal["doctor"] = currRef.doctor;
    formVal["description"] = currRef.description;
    formVal["showDescription"] = currRef.showDescription;
    formVal["numberOfRefs"] = "";
    formVal["id"] = elId;
    setRefFormElemValues((refFormElemValues) => ({
      values: [
        ...refFormElemValues.values.filter((i) => i.id !== elId),
        formVal,
      ],
    }));
  };

  const handleRefNumberChange = (event, elId) => {
    let formVal = {};
    let currRef = refFormElemValues.values.find((i) => i.id === elId);
    formVal["session"] = currRef.session;
    formVal["doctor"] = currRef.doctor;
    formVal["description"] = currRef.description;
    formVal["showDescription"] = currRef.showDescription;
    formVal["id"] = elId;
    formVal["numberOfRefs"] = event.target.value;
    setRefFormElemValues((refFormElemValues) => ({
      values: [
        ...refFormElemValues.values.filter((i) => i.id !== elId),
        formVal,
      ],
    }));
  };

  const getButtons = () => {
    return (
      <div className="d-flex encounter-button-container">
        {lab_request_permission.includes("add") ? (
          <Button
            onClick={() => setShowModal(true)}
            id="add-lab-request"
            className="ml-1"
          >
            Add Lab Request
          </Button>
        ) : null}
        <Button
          variant="primary"
          id="add-files"
          className="ml-1"
          onClick={() => setShowFilesModal(true)}
        >
          Add Files
        </Button>
        {notesValue ||
        previousNotes ||
        (data && data.encounter && data?.encounter?.data) ? (
          <Button
            variant="primary"
            className="encounter-detail-btn"
            onClick={() => setPrintEncounterModal(true)}
          >
            Print{" "}
          </Button>
        ) : null}
        {encounter_permission && encounter_permission.includes("delete") ? (
          <Button
            variant="danger"
            className="encounter-detail-btn"
            onClick={deleteEncounterCall}
          >
            {" "}
            Delete{" "}
          </Button>
        ) : null}
      </div>
    );
  };

  const onPrintAll = () => {
    formikRefPrint.current.setFieldValue("prescription", true);
    formikRefPrint.current.setFieldValue("notes", true);
    setInitialValuesPrint({
      prescription: true,
      notes: true,
    });
  };

  const onClearPrint = () => {
    formikRefPrint.current.setFieldValue("prescription", false);
    formikRefPrint.current.setFieldValue("notes", false);
    setInitialValuesPrint({
      prescription: false,
      notes: false,
    });
  };

  const notesSaveCall = (encDataVal, isAutoSave, notesToBeSaved) => {
    let values_new = {
      notes_name: "some title",
      notes_text: notesToBeSaved ? notesToBeSaved : notesValue,
    };

    if (editorReadOnly === false) {
      onSubmitNotes(values_new, encDataVal, isAutoSave);
    }
  };

  const saveNotesFunc = (values) => {
    setEncounterDataValues(values);
    let encDataVal = values;

    if (editorReadOnly === false) {
      notesSaveCall(encDataVal);
    } else {
      onSubmit(encDataVal);
    }
  };

  const saveReferral = () => {
    if (
      (numberOfFollowUps || doctorSession) &&
      !followUpmonths &&
      !followUpWeeks
    ) {
      window.alert("Please add Weeks or Months to add a follow up");
      return;
    } else if (!doctorSession) {
      window.alert("Please add doctor session to add a follow up");
      return;
    }

    onSubmitReferral(doctorSession);
  };

  const handleSelectNotes = (values, key) => {
    formikRefPrint.current.setFieldValue(key, !values[key]);
    let temp = initialValuesPrint;
    temp[key] = !values[key];
    setInitialValuesPrint(temp);
  };

  const saveBigModal = () => {
    setExpandNotesModal(false);
    notesSaveCall();
  };

  const dynamicFormSelect = (e) => {
    let staticId = e.target.name;
    let reqForms = requestedForms;
    if (reqForms.includes(staticId)) {
      reqForms = reqForms.filter((i) => i !== staticId);
      setRequestedForms(reqForms);
    } else {
      reqForms.push(staticId);
      setRequestedForms(reqForms);
    }
  };

  const addRefForms = () => {
    setRefFormElemList((refFormElemList) => ({
      keys: [...refFormElemList.keys, generateUID()],
    })); // giving unique ids to each form so we can use this to save each doctor and session id.
  };

  const removeRefForms = (i) => {
    let values = refFormElemList.keys;
    values.splice(i, 1);
    setRefFormElemList({ keys: values });
  };

  const getShowDescription = (elId) => {
    let currRef = refFormElemValues.values.find((i) => i.id === elId);
    if (currRef) {
      return currRef.showDescription;
    }
    return false;
  };

  const changeRefDescription = (e, elId) => {
    let descVal = e.target.value;
    let formVal = {};
    let currRef = refFormElemValues.values.find((i) => i.id === elId);
    if (currRef) {
      formVal["doctor"] = currRef.doctor;
      formVal["session"] = currRef.session;
      formVal["description"] = descVal;
      formVal["id"] = elId;
      formVal["showDescription"] = true;
    } else {
      formVal["id"] = elId;
      formVal["showDescription"] = true;
      formVal["description"] = descVal;
    }
    setRefFormElemValues((refFormElemValues) => ({
      values: [
        ...refFormElemValues.values.filter((i) => i.id !== elId),
        formVal,
      ],
    }));
  };

  const showDescriptionCall = (elId) => {
    let formVal = {};
    let currRef = refFormElemValues.values.find((i) => i.id === elId);
    if (currRef) {
      formVal["doctor"] = currRef.doctor;
      formVal["session"] = currRef.session;
      formVal["description"] = currRef.description;
      formVal["showDescription"] = currRef.showDescription ? false : true;
      formVal["id"] = elId;
    } else {
      formVal["showDescription"] = true;
      formVal["id"] = elId;
    }
    setRefFormElemValues((refFormElemValues) => ({
      values: [
        ...refFormElemValues.values.filter((i) => i.id !== elId),
        formVal,
      ],
    }));
  };

  const openReferralModal = () => {
    setReferralModal(true);
    addRefForms();
  };

  const getRefForms = () => {
    return (
      <>
        {refFormElemValues?.values.length > 0 && (
          <Row>
            <Col xs={12} style={{ display: "flex", justifyContent: "end" }}>
              <Button onClick={onSubmitInlineRef}>Save</Button>
            </Col>
          </Row>
        )}
        {refFormElemList.keys.map((elId, index) => {
          return (
            <Row
              className="my-2 pt-3 referralFormCard"
              style={{ background: "#e9ecef", borderRadius: 10 }}
            >
              <Col xs={12} sm={12} md={12} className="mx-auto ">
                <Row>
                  <Form.Label column sm={4} md={3}>
                    Refer To:
                  </Form.Label>
                </Row>

                <Form.Group as={Row} className="mb-1">
                  <Form.Label column sm={4} md={3} className="required">
                    Practitioner
                  </Form.Label>
                  <Col sm={8} md={7} className="doc-col">
                    <Form.Control
                      autoComplete="off"
                      as="select"
                      name="doctor"
                      id="doctor-ref"
                      value={
                        refFormElemValues.values.find((i) => i.id === elId)
                          ? refFormElemValues.values.find((i) => i.id === elId)
                              .doctor
                          : ""
                      }
                      onChange={(event) => handleDoctor(event, elId)}
                      required
                    >
                      <option value="">Select Practitioner</option>
                      {doctors_obj &&
                        doctors_obj.doctors &&
                        doctors_obj.doctors.edges.map((doctor) => {
                          return (
                            <option value={doctor.node.id} key={doctor.node.id}>
                              {doctor.node.firstName} {doctor.node.lastName}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} className="mx-auto ">
                <Form.Group as={Row} className="mb-1">
                  <Form.Label column sm={4} md={3} className="required">
                    Session Type
                  </Form.Label>
                  <Col sm={8} md={7}>
                    <Form.Control
                      autoComplete="off"
                      required
                      as="select"
                      name="session"
                      id="doctor-session-ref"
                      value={
                        refFormElemValues.values.find((i) => i.id === elId)
                          ? refFormElemValues.values.find((i) => i.id === elId)
                              .session
                          : ""
                      }
                      onChange={(event) => handleSession(event, elId)}
                    >
                      <option value="">Select Session</option>
                      {selectedDoctor?.length > 0 &&
                      selectedDoctor?.find((i) => i.id === elId)
                        ? selectedDoctor
                            .find((i) => i.id === elId)
                            .doctor?.node?.eventType.edges.map((eventtype) => {
                              return (
                                <option
                                  value={eventtype.node.id}
                                  key={eventtype.node.id}
                                >
                                  {eventtype.node.title.name}
                                </option>
                              );
                            })
                        : null}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} className="mx-auto ">
                <Form.Group as={Row} className="mb-1">
                  <Form.Label column sm={6} md={3}>
                    Recurrence
                  </Form.Label>
                  <Col sm={6} md={7}>
                    <Form.Control
                      autoComplete="off"
                      as="input"
                      type="number"
                      name="recurrence"
                      value={
                        refFormElemValues.values.find((i) => i.id === elId)
                          ? refFormElemValues.values.find((i) => i.id === elId)
                              .numberOfRefs
                          : ""
                      }
                      onChange={(event) => handleRefNumberChange(event, elId)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={12}>
                {getShowDescription(elId) ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm={4} md={3} className="text-right">
                      {"Description"}
                    </Form.Label>
                    <Col sm={8} md={7}>
                      <Form.Control
                        autoComplete="off"
                        as="textarea"
                        rows={2}
                        name="description"
                        value={
                          refFormElemValues.values.find((i) => i.id === elId)
                            ? refFormElemValues.values.find(
                                (i) => i.id === elId
                              ).description
                            : ""
                        }
                        onChange={(e) => changeRefDescription(e, elId)}
                      />
                    </Col>
                  </Form.Group>
                ) : null}
              </Col>
              <Col
                md={12}
                className="d-flex align-items-start justify-content-end pb-3"
              >
                <div className="removeRef">
                  <Button
                    onClick={() => showDescriptionCall(elId)}
                    className=" py-0"
                    variant="link"
                  >
                    {getShowDescription(elId)
                      ? "Hide Description"
                      : "Add Description"}{" "}
                  </Button>
                  {index > 0 && (
                    <Button
                      onClick={() => removeRefForms(index)}
                      className="red p-0 removeLink"
                      variant="link"
                    >
                      {" "}
                      Remove{" "}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (blockPrintData) {
      setEncounterPrintParams(null);
      let variables = { id: data.encounter.id };
      generateEncounterPrintCode({ variables });
      // setTimeout(() => { window.print() }, 400);
    }
  }, [blockPrintData]);

  const handleDoctorChange = (e) => {
    formikRef.current.setFieldValue("doctor", e.target.value);
    let doc_obj = doctors_obj.doctors.edges.find(
      (i) => i.node.id === e.target.value
    );
    setSelectedDoctorSeenBy(doc_obj);
  };

  useEffect(() => {
    if (vitalDataState && vitalDataState.edges.length > 0) {
      vitalDataState.edges.map((vital, index) => {
        setVitalHeight(vital?.node?.height);
        setVitalWeight(vital?.node?.weight);
        setVitalBMI(vital?.node?.bmi);
        setVitalBMIPer(vital?.node?.bmiPercentile);
        setVitalBP(vital?.node?.bp);
        setVitalO2(vital?.node?.o2Saturation);
        setVitalPulse(vital?.node?.pulse);
        setVitalResp(vital?.node?.respirationRate);
        setVitalTemp(vital?.node?.temperature);
        setVitalWaistCirc(vital?.node?.waistCircumference);
      });
    }
  }, [patient_record, vitalDataState]);

  const getVitalsValues = (value) => {
    if (
      vitalDataState &&
      vitalDataState.edges &&
      vitalDataState.edges.length > 0
    ) {
      return vitalDataState.edges.map((vital, index) => {
        switch (value) {
          case "height":
            return (
              <td key={index} className="text-center">
                {vital.node?.height} <span>cm</span>
              </td>
            );
          case "weight":
            return (
              <td key={index} className="text-center">
                {vital.node?.weight} <span>kg</span>
              </td>
            );
          case "waistCircumference":
            return (
              <td key={index} className="text-center">
                {vital.node?.waistCircumference} <span>cm</span>
              </td>
            );
          case "temperature":
            return (
              <td key={index} className="text-center">
                {vital.node?.temperature} <span>°C</span>
              </td>
            );
          case "pulse":
            return (
              <td key={index} className="text-center">
                {vital.node?.pulse} <span>bpm</span>
              </td>
            );
          case "bp":
            return (
              <td key={index} className="text-center">
                {vital.node?.bp} <span>bpm</span>
              </td>
            );
          case "bmi":
            return (
              <td key={index} className="text-center">
                {vital.node?.bmi}
              </td>
            );
          case "bmiPercentile":
            return (
              <td key={index} className="text-center">
                {vital.node?.bmiPercentile}
              </td>
            );
          case "respirationRate":
            return (
              <td key={index} className="text-center">
                {vital.node?.respirationRate} <span>bpm</span>
              </td>
            );
          case "o2Saturation":
            return (
              <td key={index} className="text-center">
                {vital.node?.o2Saturation} <span>%</span>
              </td>
            );
          default:
            return null;
        }
      });
    } else {
      return null;
    }
  };

  const renderVitals = () => {
    return (
      <Table striped bordered className="vitals-table">
        <thead>
          <tr>
            <th style={{ width: "160px" }}></th>
            {vitalDataState && vitalDataState.edges.length > 0
              ? vitalDataState.edges.map((vital, index) => {
                  return (
                    <th key={index} style={{ width: "160px" }}>
                      <div className="d-flex align-items-baseline justify-content-center">
                        {getDate(vital.node.created)}
                      </div>
                    </th>
                  );
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {vitalHeight ? (
            <tr>
              <td>Height</td>
              {getVitalsValues("height")}
            </tr>
          ) : null}
          {vitalWeight ? (
            <tr>
              <td>Weight</td>
              {getVitalsValues("weight")}
            </tr>
          ) : null}
          {vitalBMI ? (
            <tr>
              <td>BMI</td>
              {getVitalsValues("bmi")}
            </tr>
          ) : null}
          {vitalBMIPer ? (
            <tr>
              <td>BMI Percentile</td>
              {getVitalsValues("bmiPercentile")}
            </tr>
          ) : null}
          {vitalWaistCirc ? (
            <tr>
              <td>Waist Circumference</td>
              {getVitalsValues("waistCircumference")}
            </tr>
          ) : null}
          {vitalTemp ? (
            <tr>
              <td>Temperature</td>
              {getVitalsValues("temperature")}
            </tr>
          ) : null}
          {vitalPulse ? (
            <tr>
              <td>Pulse</td>
              {getVitalsValues("pulse")}
            </tr>
          ) : null}
          {vitalBP ? (
            <tr>
              <td>BP</td>
              {getVitalsValues("bp")}
            </tr>
          ) : null}
          {vitalO2 ? (
            <tr>
              <td>O2 Saturation</td>
              {getVitalsValues("o2Saturation")}
            </tr>
          ) : null}
          {vitalResp ? (
            <tr>
              <td>Respiration Rate</td>
              {getVitalsValues("respirationRate")}
            </tr>
          ) : null}
        </tbody>
      </Table>
    );
  };

  let canViewCurrentApt = canViewAppointments?.some(
    (i) => i === data?.encounter?.appointment?.doctor.identifier
  );

  const disablePrint = (initialValuesPrint) => {
    let objValues = Object.values(initialValuesPrint);
    if (objValues.some((j) => j === true)) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Base
        title={"ENCOUNTER DETAILS"}
        showHeader={true}
        rightChild={getButtons()}
      >
        <Row className="">
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Formik
              enableReinitialize
              onSubmit={saveNotesFunc}
              innerRef={formikRef}
              initialValues={initialValues}
            >
              {({ handleChange, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <Container fluid className="encounter-container">
                    <Row>
                      <CustomModals
                        showModal={showModal}
                        modalHeader={"LAB REQUEST"}
                        setShowModal={setShowModal}
                        dialogClassName="modal60h"
                      >
                        {data &&
                        data.encounter &&
                        data?.encounter?.patient.id ? (
                          <LabsComponent
                            patient_id={data?.encounter?.patient.id}
                            setShowModal={setShowModal}
                            showModal={showModal}
                            fromEncounter={true}
                          />
                        ) : null}
                      </CustomModals>
                      {data ? (
                        <>
                          <Form.Label
                            column
                            sm={12}
                            md={12}
                            className="text-left pb-0 text-uppercase textGrey textBold"
                          >
                            <div>
                              Patient Name:
                              <Link
                                className="d-inline-block ml-1"
                                to={`/patient/record/${
                                  data?.encounter?.patient?.id +
                                  "/?identifier=" +
                                  data?.encounter?.patient?.identifier
                                }`}
                              >
                                <Button variant="link" className="p-0">
                                  {data?.encounter?.patient.firstName}{" "}
                                  {displayMiddleName(
                                    data?.encounter?.patient.middleName
                                  )}
                                  {data?.encounter?.patient.lastName}
                                </Button>
                              </Link>
                            </div>
                          </Form.Label>
                          {data?.encounter?.patient?.dob ? (
                            <Form.Label
                              column
                              sm={12}
                              md={12}
                              className=" pb-2 pt-0 text-left text-uppercase textGrey textBold"
                            >
                              <div>
                                Patient Age:{" "}
                                <span>
                                  {" "}
                                  {getPatientsAge(
                                    data?.encounter?.patient.dob
                                  )}{" "}
                                </span>
                              </div>
                            </Form.Label>
                          ) : null}
                        </>
                      ) : null}
                      <Col xs={12} sm={12} lg={6}>
                        {data &&
                        data.encounter &&
                        data?.encounter?.appointment ? (
                          <div as={Row}>
                            <h6 className="d-inline-block text-left text-uppercase textGrey textBold">
                              Appointment:
                            </h6>
                            <div>
                              Date:
                              <span className="ml-1">
                                {getDate(data?.encounter?.appointment.start)}
                              </span>
                            </div>
                            <div>
                              Identifier:
                              <Link
                                className={
                                  "d-inline-block ml-1" +
                                  (!canViewCurrentApt ? " disabled " : "")
                                }
                                disabled={!canViewCurrentApt}
                                to={`/appointment/detail/${data?.encounter?.appointment.id}`}
                              >
                                <Button
                                  variant="link"
                                  className="p-0"
                                  disabled={!canViewCurrentApt}
                                >
                                  {data?.encounter?.appointment?.identifier}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        ) : null}
                        {data &&
                        data.encounter &&
                        data?.encounter?.appointment &&
                        data?.encounter?.appointment?.intakeForms?.edges
                          .length > 0 ? (
                          <div as={Row}>
                            <h6 className="d-inline-block text-left text-uppercase textGrey textBold">
                              Clinical Forms:
                            </h6>
                            <div>
                              {data?.encounter?.appointment?.intakeForms.edges.map(
                                (form) => {
                                  return (
                                    <Col md={6} className="px-0">
                                      <Link
                                        to={
                                          "/patient/intakeform/" + form.node.id
                                        }
                                      >
                                        {form.node.dynamicForm.name}{" "}
                                      </Link>
                                    </Col>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        ) : null}
                        {data &&
                        data?.encounter &&
                        data?.encounter?.encounterType &&
                        data?.encounter?.encounterType.name ? (
                          <Form.Group className="mb-0">
                            <Form.Label className="text-left text-uppercase textGrey textBold mb-0">
                              Encounter Type:
                            </Form.Label>
                            <div
                              className="d-inline-block ml-1 text-capitalize"
                              id="encounter-type"
                              data-encounter-type={
                                data?.encounter?.encounterType.name
                              }
                            >
                              {data?.encounter?.encounterType.name}
                            </div>
                          </Form.Group>
                        ) : null}

                        {doctors_obj && doctors_obj.doctors ? (
                          <Form.Group as={Row} className="mb-0">
                            <Form.Label
                              column
                              sm={12}
                              md={12}
                              className="text-left text-uppercase textGrey textBold"
                            >
                              Seen By
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <Form.Control
                                autoComplete="off"
                                as="select"
                                name="doctor"
                                disabled={true}
                                value={values.doctor}
                                onChange={handleDoctorChange}
                                required
                              >
                                <option value={""}> Select Doctor</option>
                                {doctors_obj.doctors.edges.map(
                                  (item, index) => {
                                    return (
                                      <option value={item.node.id} key={index}>
                                        {item.node.firstName} -{" "}
                                        {item.node.lastName}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                        ) : null}
                        {locations_obj && locations_obj.listEventLocation ? (
                          <Form.Group as={Row} className="mb-0">
                            <Form.Label
                              column
                              sm={12}
                              md={12}
                              className="text-left  text-uppercase textGrey textBold"
                            >
                              Facility
                            </Form.Label>
                            <Col sm={8} md={8}>
                              <Form.Control
                                autoComplete="off"
                                as="select"
                                name="facility"
                                disabled={true}
                                value={values.facility}
                                onChange={handleChange}
                                required
                              >
                                <option value={""}> Select Facility</option>
                                {locations_obj.listEventLocation.edges.map(
                                  (item, index) => {
                                    return (
                                      <option value={item.node.id} key={index}>
                                        {item.node.title}
                                      </option>
                                    );
                                  }
                                )}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                        ) : null}
                        {patient_vital_permission &&
                        patient_vital_permission.includes("view") ? (
                          <Row className="overflow-auto">
                            <Col>
                              <h6 className="textGrey text-uppercase pt-2">
                                <b>Vitals</b>
                              </h6>
                              {vitalDataState && vitalDataState.edges.length > 0
                                ? renderVitals()
                                : "No vitals here yet"}
                            </Col>
                          </Row>
                        ) : null}

                        {encounternote_permission &&
                        encounternote_permission.includes("view") ? (
                          <div className="notes_container ">
                            <Button
                              variant="link"
                              href="#"
                              className="d-flex justify-content-end px-0 icon-black expand-icon"
                              onClick={() => setExpandNotesModal(true)}
                            >
                              <i className="fa fa-expand mt-2"></i>
                            </Button>
                            {!expandNotesModal ? (
                              <Notes
                                is_modal={false}
                                values={values}
                                setSavedStateBe={setSavedStateBe}
                                savedStateBe={savedStateBe}
                                convertedContent={convertedContent}
                                setConvertedContent={setConvertedContent}
                                editorState={editorState}
                                previousEncounterNotesData={
                                  previousEncounterNotesData
                                }
                                setEditorState={setEditorState}
                                setNotesValue={setNotesValue}
                                notesValue={notesValue}
                                time={time}
                                setTime={setTime}
                                notesSaveCall={notesSaveCall}
                                encounternote_permission={
                                  encounternote_permission
                                }
                                previousNotes={previousNotes}
                                onSubmitNotes={onSubmitNotes}
                                setShowModalNotes={setShowModalNotes}
                                showModalNotes={showModalNotes}
                                setEditNoteTitle={setEditNoteTitle}
                                onSubmitSingleNote={onSubmitSingleNote}
                                activeNotesID={activeNotesID}
                                setActiveNotesID={setActiveNotesID}
                                initialValuesNotes={initialValuesNotes}
                                formikRefNotes={formikRefNotes}
                                expandNotesModal={expandNotesModal}
                                editorReadOnly={editorReadOnly}
                                setEditorReadOnly={setEditorReadOnly}
                                patient={
                                  data &&
                                  data.encounter &&
                                  data?.encounter?.patient
                                    ? data?.encounter?.patient
                                    : null
                                }
                              />
                            ) : null}
                            <Modal
                              show={expandNotesModal}
                              onHide={() => setExpandNotesModal(false)}
                              dialogClassName="modal-90w"
                              aria-labelledby="example-custom-modal-styling-title"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                  Notes
                                </Modal.Title>
                                <Button
                                  variant="link"
                                  href="#"
                                  className="d-flex justify-content-end px-0 icon-black add_button modal_add"
                                  onClick={() => setShowModalNotes(true)}
                                >
                                  <i className="fa fa-plus-circle mt-2"></i>
                                </Button>
                              </Modal.Header>
                              <Modal.Body className="pb-2">
                                <Notes
                                  is_modal={true}
                                  values={values}
                                  setSavedStateBe={setSavedStateBe}
                                  savedStateBe={savedStateBe}
                                  previousEncounterNotesData={
                                    previousEncounterNotesData
                                  }
                                  convertedContent={convertedContent}
                                  setConvertedContent={setConvertedContent}
                                  editorState={editorState}
                                  setEditorState={setEditorState}
                                  encounternote_permission={
                                    encounternote_permission
                                  }
                                  setNotesValue={setNotesValue}
                                  notesValue={notesValue}
                                  time={time}
                                  setTime={setTime}
                                  previousNotes={previousNotes}
                                  onSubmitNotes={onSubmitNotes}
                                  notesSaveCall={notesSaveCall}
                                  setShowModalNotes={setShowModalNotes}
                                  showModalNotes={showModalNotes}
                                  setEditNoteTitle={setEditNoteTitle}
                                  onSubmitSingleNote={onSubmitSingleNote}
                                  activeNotesID={activeNotesID}
                                  setActiveNotesID={setActiveNotesID}
                                  initialValuesNotes={initialValuesNotes}
                                  formikRefNotes={formikRefNotes}
                                  editorReadOnly={editorReadOnly}
                                  setEditorReadOnly={setEditorReadOnly}
                                  patient={
                                    data &&
                                    data.encounter &&
                                    data?.encounter?.patient
                                      ? data?.encounter?.patient
                                      : null
                                  }
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                {encounternote_permission &&
                                encounternote_permission.includes("add") ? (
                                  <Button
                                    variant="primary"
                                    size="md"
                                    className="ml-3"
                                    onClick={saveBigModal}
                                  >
                                    SAVE
                                  </Button>
                                ) : null}
                              </Modal.Footer>
                            </Modal>
                          </div>
                        ) : null}
                        <div>
                          <Accordion defaultActiveKey={"0"}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                              }}
                            >
                              <div>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  className="pointer textGrey textBold text-uppercase p-0 w-100 d-flex justify-content-start"
                                  eventKey={"0"}
                                >
                                  Follow up
                                </Accordion.Toggle>
                              </div>
                              <div className="d-flex justify-content-end align-items-center form_edit_buttons">
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="link"
                                    onClick={() => setFollowupModal(true)}
                                    className="p-1 ml-1"
                                  >
                                    <i className="fa fa-plus-circle"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <Accordion.Collapse eventKey={"0"}>
                              <div className="dynamic_block_display_container mt-3">
                                <div className="dynamic_block_display form_edit_buttons thinScrollBar">
                                  <div
                                    className={"block_item_container"}
                                    key={1}
                                  >
                                    {data?.encounter?.referrals?.edges
                                      ?.filter((item) => item.node.followUp)
                                      .map((ref, index) => {
                                        const recommendedDate = new Date(
                                          ref.node.recommendedDate
                                        );
                                        const formattedRecommendedDate =
                                          getDate(recommendedDate);

                                        return (
                                          <>
                                            <div className="block_item_edit d-flex">
                                              <Button
                                                variant="link"
                                                className="danger-color"
                                                onClick={() =>
                                                  deleteRefFunc(ref.node.id)
                                                }
                                              >
                                                <i className="fa fa-times"></i>
                                              </Button>
                                            </div>
                                            <div
                                              className="block_item print-prescription"
                                              key={index}
                                              style={{ width: "80%" }}
                                            >
                                              <span className="text-primary font-size-timestamp">
                                                [{formattedRecommendedDate}]
                                              </span>
                                              - Should be seen{" "}
                                              {formattedRecommendedDate} -{" "}
                                              {
                                                ref.node?.referredDoctorSession
                                                  ?.title?.name
                                              }{" "}
                                              -{" "}
                                              {
                                                ref.node?.referredDoctorSession
                                                  ?.doctor?.firstName
                                              }{" "}
                                              {
                                                ref.node?.referredDoctorSession
                                                  ?.doctor?.lastName
                                              }
                                            </div>
                                          </>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Collapse>

                            <CustomModals
                              showModal={followupModal}
                              modalHeader={"Create follow up"}
                              setShowModal={setFollowupModal}
                              dialogClassName="modal60h"
                            >
                              <Row
                                style={{
                                  background: "#e9ecef",
                                  borderRadius: 10,
                                }}
                              >
                                <Col>
                                  <Form.Group>
                                    <Row>
                                      <Form.Label column sm={12} md={12}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          Follow Up
                                          <Button onClick={saveReferral}>
                                            Save
                                          </Button>
                                        </div>
                                      </Form.Label>
                                    </Row>
                                    <Row className="mb-1">
                                      <Form.Label column sm={6} md={3}>
                                        Months
                                      </Form.Label>
                                      <Col sm={6} md={7}>
                                        <Form.Control
                                          autoComplete="off"
                                          as="input"
                                          name="months"
                                          type="number"
                                          value={followUpmonths || ""}
                                          onChange={(e) => {
                                            setFollowUpMonths(e.target.value);
                                            setFollowUpWeeks("");
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="mb-1">
                                      <Form.Label column sm={6} md={3}>
                                        Weeks
                                      </Form.Label>
                                      <Col sm={6} md={7}>
                                        <Form.Control
                                          autoComplete="off"
                                          as="input"
                                          name="weeks"
                                          type="number"
                                          value={followUpWeeks || ""}
                                          onChange={(e) => {
                                            setFollowUpWeeks(e.target.value);
                                            setFollowUpMonths("");
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Form.Label column sm={6} md={3}>
                                        Recurrence
                                      </Form.Label>
                                      <Col sm={6} md={7}>
                                        <Form.Control
                                          autoComplete="off"
                                          as="input"
                                          name="numOfFollowups"
                                          type="number"
                                          value={numberOfFollowUps || ""}
                                          onChange={(e) => {
                                            setNumberOfFollowUps(
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </Col>
                                {data && data.encounter ? (
                                  <Col md={12}>
                                    <Form.Group as={Row}>
                                      <Form.Label column sm={4} md={4}>
                                        Practitioner Session
                                      </Form.Label>
                                      <Col sm={8} md={8}>
                                        <Form.Control
                                          autoComplete="off"
                                          as="select"
                                          name="session"
                                          required={
                                            followUpWeeks || followUpmonths
                                          }
                                          className={
                                            followUpWeeks || followUpmonths
                                              ? "required"
                                              : ""
                                          }
                                          id="doctor-session"
                                          value={doctorSession || ""}
                                          onChange={(e) =>
                                            setDoctorSession(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            Select Session
                                          </option>
                                          {selectedDoctorSeenBy &&
                                          selectedDoctorSeenBy.node &&
                                          selectedDoctorSeenBy.node.eventType &&
                                          selectedDoctorSeenBy.node.eventType
                                            .edges.length > 0
                                            ? selectedDoctorSeenBy.node.eventType.edges.map(
                                                (eventtype) => {
                                                  return (
                                                    <option
                                                      value={eventtype.node.id}
                                                      key={eventtype.node.id}
                                                    >
                                                      {
                                                        eventtype.node.title
                                                          .name
                                                      }
                                                    </option>
                                                  );
                                                }
                                              )
                                            : null}
                                        </Form.Control>
                                        {selectedDoctorSeenBy && doctorSession
                                          ? getDoctorSessionDetails(
                                              doctorSession
                                            )
                                          : null}
                                      </Col>
                                    </Form.Group>
                                  </Col>
                                ) : null}
                                {dynamic_forms_obj &&
                                dynamic_forms_obj.dynamicForms &&
                                dynamic_forms_obj.dynamicForms.edges.length >
                                  0 ? (
                                  <Col md={12}>
                                    Requested Forms
                                    <Row>
                                      {dynamic_forms_obj.dynamicForms.edges.map(
                                        (item, index) => {
                                          return (
                                            <Col md={6}>
                                              <Form.Group as={Row} key={index}>
                                                <Col md={1}>
                                                  <Form.Check
                                                    className="mt-2"
                                                    type="checkbox"
                                                    value={requestedForms.includes(
                                                      item.node.staticId
                                                    )}
                                                    // checked={requestedForms.includes(item.node.staticId)}
                                                    name={item.node.staticId}
                                                    onChange={dynamicFormSelect}
                                                  />
                                                </Col>
                                                <Form.Label column md={10}>
                                                  {item.node.name}
                                                </Form.Label>
                                              </Form.Group>
                                            </Col>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Col>
                                ) : null}
                              </Row>
                            </CustomModals>
                          </Accordion>
                        </div>
                        <div>
                          <Accordion defaultActiveKey={"0"}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                              }}
                            >
                              <div>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  className="pointer textGrey textBold text-uppercase p-0 w-100 d-flex justify-content-start"
                                  eventKey={"0"}
                                >
                                  Referral
                                </Accordion.Toggle>
                              </div>
                              <div className="d-flex justify-content-end align-items-center form_edit_buttons">
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="link"
                                    onClick={openReferralModal}
                                    className="p-1 ml-1"
                                  >
                                    <i className="fa fa-plus-circle"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <Accordion.Collapse eventKey={"0"}>
                              <div className="dynamic_block_display_container mt-3">
                                <div className="dynamic_block_display form_edit_buttons thinScrollBar">
                                  <div
                                    className={"block_item_container"}
                                    key={1}
                                  >
                                    {data?.encounter?.referrals?.edges
                                      ?.filter((item) => !item.node.followUp)
                                      .map((ref, index) => {
                                        return (
                                          <>
                                            <div className="block_item_edit d-flex">
                                              <Button
                                                variant="link"
                                                className="danger-color"
                                                onClick={() =>
                                                  deleteRefFunc(ref.node.id)
                                                }
                                              >
                                                <i className="fa fa-times"></i>
                                              </Button>
                                            </div>
                                            <div
                                              className="block_item print-prescription"
                                              key={index}
                                              style={{ width: "100%" }}
                                            >
                                              {
                                                ref.node?.referredDoctorSession
                                                  ?.title?.name
                                              }{" "}
                                              -{" "}
                                              {
                                                ref.node?.referredDoctorSession
                                                  ?.doctor?.firstName
                                              }{" "}
                                              {
                                                ref.node?.referredDoctorSession
                                                  ?.doctor?.lastName
                                              }
                                            </div>
                                          </>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Collapse>

                            <CustomModals
                              showModal={referralModal}
                              modalHeader={"Create referral"}
                              setShowModal={setReferralModal}
                              dialogClassName="modal60h"
                            >
                              <Row
                                style={{
                                  background: "#e9ecef",
                                  borderRadius: 10,
                                }}
                              >
                                <Col>
                                  {referral_permissions &&
                                  referral_permissions.includes("add") ? (
                                    <div>
                                      {getRefForms()}
                                      <Button
                                        variant="link"
                                        className="px-0 "
                                        onClick={addRefForms}
                                      >
                                        <i
                                          className="fa fa-plus pr-2"
                                          id="add-referral-link"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Add Referral{" "}
                                      </Button>
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </CustomModals>
                          </Accordion>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        lg={6}
                        className="page_save_cancel_container"
                      >
                        <Form.Group
                          as={Row}
                          className="text-left text-uppercase textGrey textBold"
                        >
                          <Form.Label column sm={12} md={12} xl={12}>
                            ENCOUNTER DATE
                          </Form.Label>
                          <Col xs={8} sm={8} md={8} xl={8}>
                            <DatePicker
                              placeholder={t("forms.selectDate")}
                              selected={
                                values.encounter_date
                                  ? values.encounter_date
                                  : ""
                              }
                              onChange={(info) => setEncounterDate(info)}
                              name="encounter_date"
                              className="form-control"
                              showTimeSelect
                              disabled={data?.encounter?.appointment}
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              autocomplete="off"
                              dateFormat="dd/MMM/yyyy - h:mm aa"
                              popperModifiers={{
                                offset: {
                                  enabled: true,
                                  offset: "5px, 10px",
                                },
                                preventOverflow: {
                                  enabled: true,
                                  escapeWithReference: false,
                                  boundariesElement: "viewport",
                                },
                              }}
                            />
                          </Col>
                          <Col xs={4} sm={4} md={4} xl={4}>
                            <Button
                              variant="outline-primary"
                              onClick={() => setEncounterDate(new Date())}
                              className="borderRadius"
                            >
                              TODAY
                            </Button>
                          </Col>
                        </Form.Group>
                        {mainMedicalBlockPatient?.length > 0 && data
                          ? mainMedicalBlockPatient.map((medBlock) => {
                              return medBlock?.medicationFormBlock.map(
                                (blockNode, index) => {
                                  let block = blockNode.node.formBlock;
                                  let initialValues = {};
                                  let forms = block.blockFormFields.edges;
                                  let is_prescription =
                                    block.name
                                      .toLowerCase()
                                      .indexOf("prescription") > -1;
                                  let medicationBlockForm = null;
                                  let has_medication_block = true;
                                  if (is_prescription) {
                                    medicationBlockForm =
                                      DYNAMIC_FORM?.dynamicFormBlocks.edges.find(
                                        (i) =>
                                          i.node.formBlock.name
                                            .toLowerCase()
                                            .indexOf("medication") > -1
                                      );
                                    if (!medicationBlockForm) {
                                      has_medication_block = false;
                                    }
                                  }
                                  forms.map((form) => {
                                    let default_val = "";
                                    if (
                                      form.node.formField.variant === "BOOLEAN"
                                    ) {
                                      default_val = false;
                                    }
                                    return (initialValues[
                                      form.node.formField.fieldName
                                    ] = default_val);
                                  });
                                  let json_data = medBlock?.jsonDataMedical;
                                  let blockData = {
                                    blockId: block.id,
                                    blockCode: medBlock.is_prev_medication_block
                                      ? "previous" + block.code
                                      : block.code,
                                    title: medBlock.is_prev_medication_block
                                      ? "PREVIOUS MEDICATION"
                                      : block.name,
                                    id: block.id,
                                    initialValues: initialValues,
                                    forms: forms,
                                    staticId: block.staticId,
                                    encounterFormStaticId:
                                      data?.encounter?.staticId,
                                    patientFormData:
                                      patient_record?.node.formData.id,
                                    prescriptionDataId:
                                      patient_record?.node?.prescriptionData
                                        ?.id,
                                    relatedModelId: data?.encounter?.id,
                                    json_data: json_data,
                                  };
                                  var DYNAMIC_FORM_DATA = {};
                                  if (
                                    patient_record?.node?.formData
                                      ?.dynamicFormData
                                  ) {
                                    DYNAMIC_FORM_DATA =
                                      patient_record?.node?.formData
                                        ?.dynamicFormData;
                                  }
                                  return (
                                    <FormBlock
                                      setBlockData={setBlockData}
                                      blockPrintData={blockPrintData}
                                      blockData={blockData}
                                      key={index}
                                      REQUEST_UPDATE={REQUEST_PATIENT_VITAL}
                                      REQUEST_PATIENT_UPDATE={
                                        REQUEST_PATIENT_VITAL
                                      }
                                      medicationBlockForm={medicationBlockForm}
                                      has_medication_block={
                                        has_medication_block
                                      }
                                      medical_record_permission={
                                        medical_record_permission
                                      }
                                      hide_edit={true}
                                      is_patient_record={true}
                                      collapseByDefault={false}
                                      previousEncounterPrescriptionBlockData={
                                        previousEncounterPrescriptionBlockData
                                      }
                                      DYNAMIC_FORM_DATA_UPDATE_MAIN={
                                        DYNAMIC_FORM_DATA
                                      }
                                      // DYNAMIC_FORM_DATA={DYNAMIC_FORM_DATA}
                                      is_prev_medication_block={
                                        medBlock.is_prev_medication_block
                                      }
                                      is_current_medication_block={
                                        medBlock.is_current_medication_block
                                      }
                                      other_json_data={
                                        data?.encounter?.data
                                          ? data?.encounter?.data
                                          : {}
                                      }
                                      other_form_blocks={
                                        DYNAMIC_FORM?.dynamicFormBlocks.edges
                                      }
                                    />
                                  );
                                }
                              );
                            })
                          : null}
                        {MAIN_RECORD_FORMS &&
                        MAIN_RECORD_FORMS.dynamicFormBlocks
                          ? MAIN_RECORD_FORMS.dynamicFormBlocks.edges.map(
                              (blockNode, index) => {
                                let block = blockNode.node.formBlock;
                                if (!block.isMedication) {
                                  let initialValues = {};
                                  let forms = block.blockFormFields.edges;
                                  let is_prescription =
                                    block.name
                                      .toLowerCase()
                                      .indexOf("prescription") > -1;
                                  let medicationBlockForm = null;
                                  let has_medication_block = true;
                                  if (is_prescription) {
                                    medicationBlockForm =
                                      MAIN_RECORD_FORMS.dynamicFormBlocks.edges.find(
                                        (i) =>
                                          i.node.formBlock.name
                                            .toLowerCase()
                                            .indexOf("medication") > -1
                                      );
                                    if (!medicationBlockForm) {
                                      has_medication_block = false;
                                    }
                                  }
                                  forms.map((form) => {
                                    let default_val = "";
                                    if (
                                      form.node.formField.variant === "BOOLEAN"
                                    ) {
                                      default_val = false;
                                    }
                                    return (initialValues[
                                      form.node.formField.fieldName
                                    ] = default_val);
                                  });
                                  let json_data = patient_record?.node.formData
                                    ?.data
                                    ? patient_record?.node.formData?.data
                                    : {};
                                  let blockData = {
                                    blockId: block.id,
                                    blockCode: block.code,
                                    title: block.name,
                                    id: block.id,
                                    initialValues: initialValues,
                                    forms: forms,
                                    staticId: block.staticId,
                                    encounterFormStaticId:
                                      data?.encounter?.staticId,
                                    relatedModelId: data?.encounter?.id,
                                    json_data: json_data,
                                    patientFormData:
                                      patient_record?.node.formData.id,
                                    prescriptionDataId:
                                      patient_record?.node?.prescriptionData
                                        ?.id,
                                  };
                                  var DYNAMIC_FORM_DATA = {};
                                  if (
                                    patient_record?.node?.formData
                                      ?.dynamicFormData
                                  ) {
                                    DYNAMIC_FORM_DATA =
                                      patient_record?.node?.formData
                                        ?.dynamicFormData;
                                  }
                                  return (
                                    <FormBlock
                                      setBlockData={setBlockData}
                                      blockPrintData={blockPrintData}
                                      blockData={blockData}
                                      key={index}
                                      REQUEST_UPDATE={REQUEST_PATIENT_VITAL}
                                      REQUEST_PATIENT_UPDATE={
                                        REQUEST_PATIENT_VITAL
                                      }
                                      medicationBlockForm={medicationBlockForm}
                                      has_medication_block={
                                        has_medication_block
                                      }
                                      medical_record_permission={
                                        medical_record_permission
                                      }
                                      hide_edit={true}
                                      is_patient_record={true}
                                      collapseByDefault={false}
                                      DYNAMIC_FORM_DATA_UPDATE_MAIN={
                                        DYNAMIC_FORM_DATA
                                      }
                                      previousEncounterPrescriptionBlockData={
                                        previousEncounterPrescriptionBlockData
                                      }
                                      other_json_data={
                                        data?.encounter?.data
                                          ? data?.encounter?.data
                                          : {}
                                      }
                                      other_form_blocks={
                                        DYNAMIC_FORM?.dynamicFormBlocks?.edges
                                      }
                                    />
                                  );
                                }
                              }
                            )
                          : null}
                        {DYNAMIC_FORM &&
                        DYNAMIC_FORM?.dynamicFormBlocks &&
                        MAIN_RECORD_FORMS?.dynamicFormBlocks
                          ? DYNAMIC_FORM?.dynamicFormBlocks.edges.map(
                              (blockNode, index) => {
                                let block = blockNode.node.formBlock;
                                let initialValues = {};
                                let forms = block.blockFormFields.edges;
                                let is_prescription =
                                  block.name
                                    .toLowerCase()
                                    .indexOf("prescription") > -1;
                                let medicationBlockForm = null;
                                let has_medication_block = true;
                                if (is_prescription) {
                                  medicationBlockForm =
                                    MAIN_RECORD_FORMS?.dynamicFormBlocks.edges.find(
                                      (i) =>
                                        i.node.formBlock.name
                                          .toLowerCase()
                                          .indexOf("medication") > -1
                                    );
                                  if (!medicationBlockForm) {
                                    has_medication_block = false;
                                  }
                                }
                                forms.map((form) => {
                                  let default_val = "";
                                  if (
                                    form.node.formField.variant === "BOOLEAN"
                                  ) {
                                    default_val = false;
                                  }
                                  return (initialValues[
                                    form.node.formField.fieldName
                                  ] = default_val);
                                });
                                let json_data = data?.encounter?.data
                                  ? data?.encounter?.data
                                  : {};
                                let blockData = {
                                  blockId: block.id,
                                  title: block.name,
                                  blockCode: block.code,
                                  id: block.id,
                                  initialValues: initialValues,
                                  forms: forms,
                                  staticId: block.staticId,
                                  encounterFormStaticId:
                                    data?.encounter?.staticId,
                                  relatedModelId: data?.encounter?.id,
                                  json_data: json_data,
                                  patientFormData:
                                    patient_record?.node.formData.id,
                                  prescriptionDataId:
                                    patient_record?.node?.prescriptionData?.id,
                                };
                                var DYNAMIC_FORM_DATA = {};
                                if (
                                  patient_record?.node?.formData
                                    ?.dynamicFormData
                                ) {
                                  DYNAMIC_FORM_DATA =
                                    patient_record?.node?.formData
                                      ?.dynamicFormData;
                                }
                                return (
                                  <FormBlock
                                    setBlockData={setBlockData}
                                    blockPrintData={blockPrintData}
                                    blockData={blockData}
                                    key={index}
                                    REQUEST_UPDATE={REQUEST_ENCOUNTER}
                                    is_encounter={true}
                                    is_prescription={is_prescription}
                                    medicationBlockForm={medicationBlockForm}
                                    has_medication_block={has_medication_block}
                                    medical_record_permission={
                                      medical_record_permission
                                    }
                                    collapseByDefault={false}
                                    DYNAMIC_FORM_DATA_UPDATE_MAIN={
                                      DYNAMIC_FORM_DATA
                                    }
                                    previousEncounterPrescriptionBlockData={
                                      previousEncounterPrescriptionBlockData
                                    }
                                    REQUEST_PATIENT_UPDATE={
                                      REQUEST_PATIENT_VITAL
                                    }
                                    other_json_data={
                                      data?.encounter?.data
                                        ? data?.encounter?.data
                                        : {}
                                    }
                                  />
                                );
                              }
                            )
                          : null}
                        {encounter_permission &&
                        encounter_permission.includes("add") ? (
                          <Row className="pr-3 mb-2 page_save_cancel justify-content-end">
                            {data &&
                            data.encounter &&
                            data?.encounter?.patient ? (
                              <Link
                                to={
                                  "/patient/record/" +
                                  data?.encounter?.patient?.id +
                                  "/?identifier=" +
                                  data?.encounter?.patient?.identifier
                                }
                              >
                                <Button type="button">CANCEL</Button>
                              </Link>
                            ) : null}
                            <Button
                              type="submit"
                              disabled={loading}
                              className="ml-3"
                            >
                              {" "}
                              {loading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "SAVE"
                              )}
                            </Button>
                            {/* </Col> */}
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
            <CustomModals
              showModal={printEncounterModal}
              modalHeader={
                "PRINT ENCOUNTER FOR " +
                (data && data.encounter
                  ? data?.encounter?.patient.firstName +
                    " " +
                    displayMiddleName(data?.encounter?.patient.middleName) +
                    data?.encounter?.patient.lastName
                  : "patient")
              }
              setShowModal={setPrintEncounterModal}
              dialogClassName="modal60h printEncounter"
            >
              <Formik
                enableReinitialize
                onSubmit={onSubmitPrint}
                initialValues={initialValuesPrint}
                innerRef={formikRefPrint}
              >
                {({ handleSubmit, handleChange, values }) => (
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row className="my-4 printModalScroll">
                      <Col xs={12} sm={12} md={12} className="mx-auto">
                        <h6 className="text-uppercase text-grey">
                          {" "}
                          Select patient information to print{" "}
                        </h6>
                        <h6>
                          {" "}
                          <span
                            className="text-primary pr-2 pointer"
                            onClick={() => onPrintAll(values)}
                          >
                            {" "}
                            Select all{" "}
                          </span>{" "}
                          <span
                            className="text-primary pointer px-2"
                            onClick={onClearPrint}
                          >
                            {" "}
                            Clear{" "}
                          </span>{" "}
                        </h6>
                        <div className="pt-3 checkbox-wrapper">
                          <div className="py-1">
                            <Form.Group
                              as={Row}
                              className="justify-content-start position-relative mb-0"
                            >
                              <Col
                                xs={4}
                                sm={7}
                                md={3}
                                lg={12}
                                xl={12}
                                className={"pst-absolute-160px d-flex"}
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"prescription"}
                                  onChange={() =>
                                    handleSelectNotes(values, "prescription")
                                  }
                                  // value={values["prescriptions"]}
                                  checked={values["prescription"]}
                                />
                                <Form.Label className="mb-0">
                                  {" "}
                                  Prescriptions{" "}
                                </Form.Label>
                              </Col>
                            </Form.Group>
                          </div>
                          <div className="py-1">
                            <Form.Group
                              as={Row}
                              className="justify-content-start position-relative mb-0"
                            >
                              <Col
                                xs={4}
                                sm={7}
                                md={3}
                                lg={12}
                                xl={12}
                                className={"pst-absolute-160px d-flex"}
                              >
                                <Form.Check
                                  type="checkbox"
                                  name={"notes"}
                                  onChange={() =>
                                    handleSelectNotes(values, "notes")
                                  }
                                  // value={notesValue}
                                  checked={values["notes"]}
                                />
                                <Form.Label className="mb-0">
                                  {" "}
                                  Notes{" "}
                                </Form.Label>
                              </Col>
                            </Form.Group>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        className="d-flex flex-wrap justify-content-center justify-content-sm-between"
                      >
                        <Button
                          variant="primary"
                          size="md"
                          className="mb-1"
                          onClick={() => setPrintEncounterModal(false)}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant="primary"
                          disabled={disablePrint(initialValuesPrint)}
                          size="md"
                          className="ml-2 mr-2 mb-1"
                          type="submit"
                        >
                          Print
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CustomModals>
            <CustomModals
              showModal={showFilesModal}
              modalHeader={"UPLOAD PATIENT FILE"}
              setShowModal={setShowFilesModal}
              dialogClassName="modal60h"
            >
              {data &&
              data.encounter &&
              data?.encounter?.patient &&
              data?.encounter?.patient.id ? (
                <PatientFileUploadModal
                  patient_id={data?.encounter?.patient.id}
                  encounter_id={data?.encounter?.id}
                  setShowModal={setShowFilesModal}
                  showModal={showFilesModal}
                />
              ) : null}
            </CustomModals>
          </Col>
        </Row>
      </Base>
    </>
  );
}
export default withRouter(EncounterDetails);
