import "./Calendar.css";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import dayGridWeek from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import timeGridWeek from "@fullcalendar/timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  dateEventInVar,
  calendarSlotsVar,
  eventsDataVar,
  potentialAppointmentSlotVar,
  calendarActiveMonthVar,
  appFromCalVar,
  currentSlotIndexVar,
  myAppointmentDetailsVar,
  displayLoaderVar,
  editableAppointmentVar,
} from "cache/cache.js";
import {
  isUserStaff,
  urlActions,
  displayMiddleName,
  ERROR_MESSAGE,
  error_options,
  getValidDateStringForGraphQL,
  getSunday,
} from "Common/helpers.js";
import { withRouter } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "react-simple-snackbar";
import i18n from "locales/i18n";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Preloader from "Common/Preloder/Preloader";
import _ from "lodash";

const Calendar = (props) => {
  const {
    // data,
    myAppointments,
    selectedDoctor,
    doctorResources,
    doctorLeavesList,
    fullCalendarRef,
    history,
    selectedDateEvent,
    selectedSession,
    referralObjectData,
    GetEvents,
    settings_obj,
    selectedDocList,
    doctorsList,
    appFetchVariables,
    appointmentCreate,
    setVariablesToBeFetched,
  } = props;

  const [openSnackbar] = useSnackbar(error_options);

  const myAppointmentDetails = useReactiveVar(myAppointmentDetailsVar);
  const editableAppointment = useReactiveVar(editableAppointmentVar);
  const [calendarView, setCalendarView] = useState("timeGridWeek");
  const [calendarSlotDuration, setCalendarSlotDuration] = useState(30);
  const [activeDocShiftArr, setActiveDocShiftArr] = useState([]);
  const isStaff = isUserStaff();
  const [amountOfEvents, setAmountOfEvents] = useState(25);
  const [data, setData] = useState([]);
  const [slotSt, setSlotSt] = useState("03");
  const [slotEnd, setSlotEnd] = useState("19");
  const [availableSlotsArr, setAvailableSlotsArr] = useState([]);
  const [recurringEventsList, setRecurringEventsList] = useState([]);
  const [calendarBodyHeight, setCalendarBodyHeight] = useState({});
  const [coords, setcoords] = useState(null);
  const [selectedDateStr, setSelectedDateStr] = useState(null);
  const [selectedDocToCreateEvent, setSelectedDocToCreateEvent] =
    useState(null);
  const { t } = useTranslation();
  const eventsDataMain = useReactiveVar(eventsDataVar);
  const displayLoader = useReactiveVar(displayLoaderVar);
  const calendarActiveMonth = useReactiveVar(calendarActiveMonthVar);
  const potentialAppointmentSlot = useReactiveVar(potentialAppointmentSlotVar);
  const appFromCal = useReactiveVar(appFromCalVar);
  const [calMounted, setCalMounted] = useState(false);
  const appCreateFromCal = props?.location?.state?.appCreateFromCal;
  const [time, setTime] = React.useState(null);
  const isGroupAppointment = window.location.href.indexOf("group=true") > -1;
  const isGroupAddPatient =
    window.location.href.indexOf("groupAddPatient=true") > -1;
  const isGroupEditPatient =
    window.location.href.indexOf("groupEditPatient=true") > -1;
  const iseditGroupTherapy =
    window.location.href.indexOf("editGroupTherapy=true") > -1;

  // const CALENDAR_SLOTS = gql`
  // query receiveDate {
  //   calendarSlots @client
  // }
  // `;
  // const { data: calendarData = null } = useQuery(CALENDAR_SLOTS);

  let calendarExtraProps = {};

  useEffect(() => {
    if (selectedSession) {
      let duration = selectedSession?.node.duration;
      setCalendarSlotDuration(duration);
    }
    if (isGroupAddPatient) {
      let calendarButtons = document.getElementsByClassName("fc-toolbar-chunk");
      for (var i = 0; i < calendarButtons.length; i++) {
        calendarButtons[i].classList.add("disabled");
        calendarButtons[i].classList.add("opacity05");
      }
    }
  }, [selectedSession, isGroupAddPatient]);

  // SET STATE FROM REACTIVE VARIBALE
  useEffect(() => {
    let refCalendarView =
      fullCalendarRef && fullCalendarRef.current
        ? fullCalendarRef?.current?.getApi()?.view
        : null;
    if (refCalendarView && selectedDoctor?.node?.availability) {
      getActiveDocShifts(
        refCalendarView.activeStart,
        refCalendarView.activeEnd,
        selectedDoctor,
        recurringEventsList
      );
    }

    if (selectedDocList && refCalendarView) {
      selectedDocList.map((selDoc) => {
        let setDocObj = doctorsList?.doctors?.edges.find(
          (i) => i.node.identifier === selDoc.value
        );
        if (setDocObj?.node?.availability) {
          getActiveDocShifts(
            refCalendarView.activeStart,
            refCalendarView.activeEnd,
            setDocObj,
            recurringEventsList
          );
        }
      });
    }

    if (!selectedDoctor && selectedDocList?.length < 1) {
      setActiveDocShiftArr([]);
    }
  }, [fullCalendarRef, selectedDoctor, selectedDocList, recurringEventsList]);

  useEffect(() => {
    setActiveDocShiftArr([]);
  }, [selectedDocList?.length, selectedDoctor]);

  const getActiveDocShifts = (
    activeStart,
    activeEnd,
    selectedDoctor,
    recurringEvents
  ) => {
    let shiftArr = activeDocShiftArr;
    if (isStaff) {
      for (var d = activeStart; d <= activeEnd; d.setDate(d.getDate() + 1)) {
        d.setHours(2);
        let shift_for_day = selectedDoctor.node.availability?.edges.find(
          (i) => i.node.day === d.getDay() + ""
        );
        let dd = String(d.getDate()).padStart(2, "0");
        let mm = String(d.getMonth() + 1).padStart(2, "0");
        let yyyy = d.getFullYear();

        let activeDateStr = yyyy + "-" + mm + "-" + dd;
        let activeDateObj = new Date(activeDateStr);
        var SHIFT_OBJ = null;

        SHIFT_OBJ = shift_for_day;

        let activeRecurringDay = recurringEvents.filter((i) =>
          i.node.days.includes(d.getDay() + "")
        ); //converting int active day to string to compare

        if (SHIFT_OBJ?.node?.shifts?.edges) {
          SHIFT_OBJ.node.shifts.edges.map((shift, index) => {
            let shiftsObj = getShiftsForDoc(
              shift.node.startTime,
              shift.node.endTime
            );
            let shiftStartTime =
              String(shiftsObj.startTime.getHours()).padStart(2, "0") +
              ":" +
              String(shiftsObj.startTime.getMinutes()).padStart(2, "0");
            let shiftEndTime =
              String(shiftsObj.endTime.getHours()).padStart(2, "0") +
              ":" +
              String(shiftsObj.endTime.getMinutes()).padStart(2, "0");

            let shiftStHrMin = shiftStartTime.split(":");
            let shiftSt = new Date(d);
            shiftSt.setHours(shiftStHrMin[0]);
            shiftSt.setMinutes(shiftStHrMin[1]);

            let shiftEnHrMin = shiftEndTime.split(":");
            let shiftEnd = new Date(d);
            shiftEnd.setHours(shiftEnHrMin[0]);
            shiftEnd.setMinutes(shiftEnHrMin[1]);

            let yyyy = shiftSt.getFullYear();

            let dd = String(shiftSt.getDate()).padStart(2, "0");
            let mm = String(shiftSt.getMonth() + 1).padStart(2, "0");
            let hr = String(shiftSt.getHours()).padStart(2, "0");
            let mn = String(shiftSt.getMinutes()).padStart(2, "0");

            let ddEn = String(shiftEnd.getDate()).padStart(2, "0");
            let mmEn = String(shiftEnd.getMonth() + 1).padStart(2, "0");
            let hrEn = String(shiftEnd.getHours()).padStart(2, "0");
            let mnEn = String(shiftEnd.getMinutes()).padStart(2, "0");

            shiftArr.push({
              groupId: "shiftGroupId",
              start: yyyy + "-" + mm + "-" + dd + "T" + hr + ":" + mn + ":00",
              end:
                yyyy +
                "-" +
                mmEn +
                "-" +
                ddEn +
                "T" +
                hrEn +
                ":" +
                mnEn +
                ":00",
              display: "background",
              resourceId: selectedDoctor.node.id,
            });
          });
        }

        for (var k = 0; k < activeRecurringDay.length; k++) {
          let currRecurr = activeRecurringDay[k];

          let currDateStart = new Date(currRecurr.node.startDate);
          currDateStart.setHours(0);
          currDateStart.setMinutes(0);

          let currDateUntil = null;
          if (currRecurr.node.dateUntil) {
            currDateUntil = new Date(currRecurr.node.dateUntil);
            currDateUntil.setHours(23);
            currDateUntil.setMinutes(59);
          }

          if (
            currRecurr.node.dateUntil === null ||
            (currRecurr.node.dateUntil &&
              currDateUntil &&
              d.getTime() < new Date(currDateUntil).getTime() &&
              d.getTime() >= new Date(currDateStart).getTime())
          ) {
            let recEventSt = currRecurr.node.startTime.split(":");
            let recEventEn = currRecurr.node.endTime.split(":");
            shiftArr.push({
              groupId: "recurringGroupId",
              start:
                yyyy +
                "-" +
                mm +
                "-" +
                dd +
                "T" +
                recEventSt[0] +
                ":" +
                recEventSt[1] +
                ":00",
              end:
                yyyy +
                "-" +
                mm +
                "-" +
                dd +
                "T" +
                recEventEn[0] +
                ":" +
                recEventEn[1] +
                ":00",
              backgroundColor: "#288dcc",
              textColor: "#fff",
              title: currRecurr.node.title,
              resourceId: currRecurr.node.resourceId,
            });
          }
        }

        let currDayLeavesArr =
          selectedDoctor?.node?.unavailableDates?.edges.filter(
            (i) =>
              i.node.date === yyyy + "-" + mm + "-" + dd && i.node.leaveRequest
          );

        if (currDayLeavesArr?.length > 0) {
          for (var l = 0; l < currDayLeavesArr?.length; l++) {
            let leaveItem = currDayLeavesArr[l];
            let leaveItemToDisplay = {
              groupId: "leaveGroupId",
              backgroundColor: "#288dcc",
              textColor: "#fff",
              resourceId: selectedDoctor.node.id,
              title: leaveItem?.node?.leaveRequest?.description
                ? leaveItem?.node?.leaveRequest?.description
                : leaveItem?.node?.leaveRequest?.leaveType,
            };
            if (leaveItem?.node?.leaveRequest?.leaveType === "SHORT_LEAVE") {
              leaveItemToDisplay["start"] =
                yyyy + "-" + mm + "-" + dd + "T" + leaveItem.node.startTime;
              leaveItemToDisplay["end"] =
                yyyy + "-" + mm + "-" + dd + "T" + leaveItem.node.endTime;
            } else {
              leaveItemToDisplay["start"] =
                yyyy + "-" + mm + "-" + dd + "T" + "00:00:00";
              leaveItemToDisplay["end"] =
                yyyy + "-" + mm + "-" + dd + "T" + "23:59:00";
            }
            shiftArr.push(leaveItemToDisplay);
          }
        }
      }
      if (myAppointments) {
        let uniqueChldrn = _.uniqWith(shiftArr, _.isEqual);
        setActiveDocShiftArr(uniqueChldrn);
      } else {
        let uniqueChldrn = _.uniqBy(shiftArr, "start");
        setActiveDocShiftArr(uniqueChldrn);
      }
    }
  };

  const getShiftsForDoc = (start_time, end_time) => {
    let now = new Date();
    let diff = now.getTimezoneOffset(); // -180 minutes kuwait
    let diff_in_hr = diff / 60; // -3 hours for kuwait

    diff_in_hr += 3; // UTC + 3

    let startTimeArr = start_time.split(":");
    let startTime = new Date();
    startTime.setHours(startTimeArr[0]);
    startTime.setMinutes(startTimeArr[1]);
    startTime.setSeconds(0);

    startTime.setHours(startTime.getHours() - diff_in_hr);

    let endTimeArr = end_time.split(":");
    let endTime = new Date();
    endTime.setHours(endTimeArr[0]);
    endTime.setMinutes(endTimeArr[1]);
    endTime.setSeconds(0);

    endTime.setHours(endTime.getHours() - diff_in_hr);
    return {
      startTime: startTime,
      endTime: endTime,
    };
  };

  // ======================================== DOCTOR AND SESSION AVAILABILITY ================================
  let weekdayAvailability = [];
  let businessHours = [];
  let workingHours = [];

  // if availability exists for the selected session/service- use that, otherwise use availability for doctor shifts
  // Weekday availability and shift hours based on Selected SERVICE
  if (
    // Weekday availability and buisness hours based on DOCTOR
    selectedDoctor &&
    selectedDoctor.node.availability &&
    selectedDoctor.node.availability?.edges.length > 0
  ) {
    weekdayAvailability = selectedDoctor.node.availability?.edges.map(
      (available) => parseInt(available.node.day)
    );
    selectedDoctor.node.availability?.edges.map((available) => {
      let shifts = available.node.shifts.edges;
      for (var i = 0; i < shifts.length; i++) {
        let shift = shifts[i];
        let dayArr = Array.from(String(available.node.day), Number);
        // since backend returns kuwait time for shifts so change the start and end time of shift to kuwait
        let shiftsObj = getShiftsForDoc(
          shift.node.startTime,
          shift.node.endTime
        );
        let st =
          String(shiftsObj.startTime.getHours()).padStart(2, "0") +
          ":" +
          String(shiftsObj.startTime.getMinutes()).padStart(2, "0");
        let en =
          String(shiftsObj.endTime.getHours()).padStart(2, "0") +
          ":" +
          String(shiftsObj.endTime.getMinutes()).padStart(2, "0");
        workingHours.push({
          daysOfWeek: dayArr,
          startTime: st,
          endTime: en,
        });
      }
      return null;
    });
  }
  // else if (
  //   selectedSession &&
  //   selectedSession?.node.availability &&
  //   selectedSession?.node.availability?.edges.length > 0
  // ) {
  //   weekdayAvailability = selectedSession?.node.availability?.edges.map((available) =>
  //     parseInt(available.node.day),
  //   );
  //   selectedSession?.node.availability?.edges.map((available) => {
  //     let shifts = available.node.shifts.edges;
  //     for (var i = 0; i < shifts.length; i++) {
  //       let shift = shifts[i];
  //       let dayArr = Array.from(String(available.node.day), Number);
  //       let shiftsObj=getShiftsForDoc(shift.node.startTime,shift.node.endTime);
  //       let st=String(shiftsObj.startTime.getHours()).padStart(2, '0')+":"+String(shiftsObj.startTime.getMinutes()).padStart(2, '0')
  //       let en=String(shiftsObj.endTime.getHours()).padStart(2, '0')+":"+String(shiftsObj.endTime.getMinutes()).padStart(2, '0')
  //       workingHours.push({
  //         daysOfWeek: dayArr,
  //         startTime:st,
  //         endTime:en,
  //       });
  //     }
  //     return null;
  //   });
  // }

  let defaultHours = {
    daysOfWeek: weekdayAvailability,
    startTime: "09:00",
    endTime: "17:00",
  };
  let defaultBuisness = [];
  defaultBuisness.push(defaultHours);
  calendarExtraProps["selectConstraint"] = "businessHours";
  if (workingHours && workingHours.length > 0) {
    businessHours = workingHours;
  } else {
    businessHours = defaultBuisness;
  }

  calendarExtraProps["businessHours"] = businessHours;

  // ======================================== DOCTOR AND SESSION AVAILABILITY END ================================

  const addMinutes = (time, minsToAdd) => {
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }
    if (time) {
      var piece = time.split(":");
      var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
    }
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  };

  const handleChangeView = (daySelect) => {
    if (daySelect.view.type === "dayGridMonth") {
      daySelect.view.calendar.changeView(
        "timeGridDay",
        `${daySelect.startStr}`
      );
      setCalendarView("timeGridDay");
    } else if (daySelect.view.type === "timeGridDay") {
      return false;
    }
  };

  const handleSelectAllow = (info) => {
    if (calendarView === "dayGridMonth" || info.allDay) {
      return true;
    } else {
      let allAvailableEvents =
        fullCalendarRef && fullCalendarRef.current
          ? fullCalendarRef?.current?.getApi()?.getEvents()
          : [];
      let is_allowed = allAvailableEvents.find(
        (i) => i.startStr === info.startStr
      );
      if (is_allowed) {
        return true;
      } else {
        return false;
      }
    }
  };
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    localStorage.setItem("calendarViewStored", "");

    // change calendar visible start and end times based on timezone of current user
    if (slotSt === "03") {
      //  only trigger once for each  page load
      let now = new Date();
      let diff = now.getTimezoneOffset(); // -180 minutes kuwait
      let diff_in_hr = diff / 60; // -3 hours for kuwait

      let tempSt = parseInt(slotSt) - diff_in_hr;
      let tempEnd = parseInt(slotEnd) - diff_in_hr;

      tempSt = String(tempSt).padStart(2, "0");
      tempEnd = String(tempEnd).padStart(2, "0");

      setSlotSt(tempSt);
      setSlotEnd(tempEnd);
    }

    //To initialize calendar based on url params
    let calendarInitDate = urlActions(
      window.location.href,
      "get",
      "calendarInitDate"
    );
    let calendarInitView = urlActions(
      window.location.href,
      "get",
      "calendarInitView"
    );
    if (calendarInitView && calendarInitDate) {
      if (calendarInitDate?.indexOf(" ") > -1) {
        // the calendarInitDate 2021-12-05T00:00:00+03:00 is retrieved as '2021-12-05T00:00:00 03:00' that causes error for date object creation, so we are processing it before prodicing it to the date object
        calendarInitDate = calendarInitDate.replace(" ", "+");
      }

      let dtSt = new Date(calendarInitDate);
      dtSt.setHours(1);
      let endDate = new Date(dtSt);
      endDate.setDate(dtSt.getDate() + 30);
      if (dtSt) {
        let calDate = dtSt.toISOString();
        fullCalendarRef?.current
          ?.getApi()
          ?.changeView(calendarInitView, calDate);
      }
    }
    setCalMounted(true);
    return () => window.removeEventListener("resize", updateWidth);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let calendarViewStored = localStorage.getItem("calendarViewStored");

  useEffect(() => {
    if (calendarViewStored) {
      setCalendarView(calendarViewStored);
    }
  }, [calendarViewStored]);

  const getSessionAvailability = (selectedSession) => {
    if (selectedSession?.node.availability?.edges.length > 0) {
      weekdayAvailability = selectedSession?.node.availability?.edges.map(
        (available) => parseInt(available.node.day)
      );
      let workingHours = [];
      selectedSession?.node.availability?.edges.map((available) => {
        let shifts = available.node.shifts.edges;
        for (var i = 0; i < shifts.length; i++) {
          let shift = shifts[i];
          let dayArr = Array.from(String(available.node.day), Number);
          let shiftsObj = getShiftsForDoc(
            shift.node.startTime,
            shift.node.endTime
          );
          let st =
            String(shiftsObj.startTime.getHours()).padStart(2, "0") +
            ":" +
            String(shiftsObj.startTime.getMinutes()).padStart(2, "0");
          let en =
            String(shiftsObj.endTime.getHours()).padStart(2, "0") +
            ":" +
            String(shiftsObj.endTime.getMinutes()).padStart(2, "0");
          workingHours.push({
            daysOfWeek: dayArr,
            startTime: st,
            endTime: en,
          });
        }
        return null;
      });
      return workingHours;
    }
  };

  const getSlotBasedOnSession = (
    allSessionHoursToday,
    startTime,
    endTime,
    current_slot
  ) => {
    let currentShiftSlot = allSessionHoursToday?.find((i) => {
      let sessionAvailabilityStartDate = new Date();
      let sessionAvSt = i.startTime;
      sessionAvSt = sessionAvSt.split(":");
      sessionAvailabilityStartDate.setHours(sessionAvSt[0]);
      sessionAvailabilityStartDate.setMinutes(sessionAvSt[1]);

      let sessionAvailabilityEndDate = new Date();
      let sessionAvEnd = i.endTime;
      sessionAvEnd = sessionAvEnd.split(":");
      sessionAvailabilityEndDate.setHours(sessionAvEnd[0]);
      sessionAvailabilityEndDate.setMinutes(sessionAvEnd[1]);

      let startTimeDateFormat = new Date();
      let startTimeArr = startTime.split(":");
      startTimeDateFormat.setHours(startTimeArr[0]);
      startTimeDateFormat.setMinutes(startTimeArr[1]);

      let endTimeDateFormat = new Date();
      let endTimeArr = endTime.split(":");
      endTimeDateFormat.setHours(endTimeArr[0]);
      endTimeDateFormat.setMinutes(endTimeArr[1]);

      if (
        sessionAvailabilityStartDate >= startTimeDateFormat &&
        sessionAvailabilityEndDate <= endTimeDateFormat
      ) {
        return i;
      }
    });

    if (currentShiftSlot) {
      let sessionAvailabilityStartDate = new Date(current_slot.start);
      let sessionAvSt = currentShiftSlot.startTime;
      sessionAvSt = sessionAvSt.split(":");
      sessionAvailabilityStartDate.setHours(sessionAvSt[0]);
      sessionAvailabilityStartDate.setMinutes(sessionAvSt[1]);

      let sessionAvailabilityEndDate = new Date(current_slot.end);
      let sessionAvEnd = currentShiftSlot.endTime;
      sessionAvEnd = sessionAvEnd.split(":");
      sessionAvailabilityEndDate.setHours(sessionAvEnd[0]);
      sessionAvailabilityEndDate.setMinutes(sessionAvEnd[1]);

      if (
        new Date(current_slot.start) >= sessionAvailabilityStartDate &&
        new Date(current_slot.end) <= sessionAvailabilityEndDate
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getRecurGroupSlotsFunc = (holidays, groupEvent, shortLeaves) => {
    let tempRecurrGroupSlots = [];
    let tempAllGroupSlots = [];
    var start = new Date(groupEvent.groupStartDate);
    // if(start<new Date){
    //   start = new Date();
    // }
    var end = new Date(groupEvent.groupEndDate);

    for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
      let currentDay = d.getDay(); // javascript returns sunday as 0 but backend considers monday as 0 and sunday as 6 so this calculation is to match the currect day
      if (
        (!myAppointments &&
          !holidays?.includes(getValidDateStringForGraphQL(d)) &&
          weekdayAvailability?.includes(d.getDay())) ||
        myAppointments
      ) {
        if (groupEvent.groupDays?.includes(currentDay + "")) {
          let newCreatedEventRecurr = JSON.parse(JSON.stringify(groupEvent));
          newCreatedEventRecurr["start"] =
            getValidDateStringForGraphQL(d) +
            "T" +
            groupEvent.start.split("T")[1];
          newCreatedEventRecurr["end"] =
            getValidDateStringForGraphQL(d) +
            "T" +
            groupEvent.end.split("T")[1];
          newCreatedEventRecurr["backgroundColor"] =
            selectedSession?.node.eventType.color?.hexCode;
          newCreatedEventRecurr["borderColor"] =
            selectedSession?.node.eventType.color?.hexCode;
          if (myAppointments) {
            newCreatedEventRecurr["passDateInURL"] =
              getValidDateStringForGraphQL(d) +
              "T" +
              groupEvent.start.split("T")[1];
          }
          tempRecurrGroupSlots.push(newCreatedEventRecurr);
        }
      }
    }

    for (var currSlotIndex in tempRecurrGroupSlots) {
      let currentSlot = tempRecurrGroupSlots[currSlotIndex];
      let currentSlotStartWithTimezone = new Date(
        currentSlot.start
      ).toLocaleString("en-US");
      let activeDateStr = currentSlot.start.split("T")[0];
      let activeStartTime = currentSlotStartWithTimezone.split(", ")[1];
      if (activeStartTime) {
        activeStartTime = activeStartTime.substring(0, 5);
      }
      let buffer_end;
      let slot_end_without_buffer = addMinutes(
        activeStartTime,
        calendarSlotDuration
      ); // '18:35'
      let create_slot = true;
      let buffer_minutes = selectedSession?.node
        ? parseInt(selectedSession?.node.buffer)
        : 0; // change default to 0 after testing
      let currentSlotEndBuffer = addMinutes(
        slot_end_without_buffer.substring(0, 5),
        parseInt(buffer_minutes)
      );
      let current_slot = {
        start: activeDateStr + "T" + activeStartTime,
        end: activeDateStr + "T" + currentSlotEndBuffer,
      };
      let appointment_buffer;
      let nextStartBuffer;
      for (var j in data) {
        let appointment = data[j];
        if (appointment && !appointment.holdWaitingReplacement) {
          if (!appointment?.doctorEventType?.groupSession) {
            appointment_buffer = appointment?.doctorEventType?.buffer;
            let app_end_time = appointment.end.split("T")[1];
            nextStartBuffer = addMinutes(
              app_end_time.substring(0, 5),
              parseInt(appointment_buffer)
            );
            buffer_end = new Date(activeDateStr + "T" + nextStartBuffer);
            let app_st = appointment.start; // utc
            var app_en = appointment.end;
            let appStart = new Date(app_st); // kuwait
            var appEnd = new Date(app_en); //
            if (current_slot) {
              let current_slot_start = new Date(current_slot.start);
              let current_slot_end = new Date(current_slot.end);
              let stDate = new Date(current_slot.start);
              let etDate = new Date(app_st);
              if (stDate.setHours(0, 0, 0, 0) === etDate.setHours(0, 0, 0, 0)) {
                if (
                  //current_slot_start is suggested slot start date , current_slot_end is suggested slot end. appStart: existing appointment start and appEnd is existing appointment end
                  //buffer_end is the buffer end the current existing appointment buffer end time in for loop that is being compared against new suggested slot
                  (current_slot_start < appEnd &&
                    current_slot_start >= appStart) ||
                  (current_slot_end < appEnd && current_slot_end > appStart) ||
                  (current_slot_start <= appStart &&
                    current_slot_end >= appEnd) ||
                  (buffer_end <= appEnd && buffer_end > appStart) ||
                  (current_slot_start <= appStart && buffer_end >= appEnd)
                ) {
                  create_slot = false;
                  break;
                }
              }
            }
          }
        }
      }

      if (!myAppointments) {
        let activeDate = new Date(currentSlot.start);
        let activeDay = activeDate.getDay();
        let current_slot_end = new Date(currentSlot.end);
        if (
          activeDay &&
          recurringEventsList &&
          recurringEventsList.length > 0
        ) {
          let activeRecurringDay = recurringEventsList.filter((i) =>
            i.node.days.includes(activeDay + "")
          ); //converting int active day to string to compare
          if (activeStartTime && activeRecurringDay) {
            for (var k = 0; k < activeRecurringDay.length; k++) {
              let currRecurr = activeRecurringDay[k];
              if (
                currRecurr.node.dateUntil === null ||
                (currRecurr.node.dateUntil &&
                  activeDate <= new Date(currRecurr.node.dateUntil) &&
                  activeDate >= new Date(currRecurr.node.startDate))
              ) {
                let currRecurrStart = currRecurr.node.startTime.slice(0, -3); // to convert 10:00:00 to 10:00 to match the format of existing start time in code
                let currRecurrEnd = currRecurr.node.endTime.slice(0, -3);
                let recurrEventSt = new Date(
                  activeDateStr + "T" + currRecurrStart
                );
                let slotStart = new Date(
                  activeDateStr +
                    "T" +
                    new Date(currentSlot.start).toISOString().split("T")[1]
                );
                let slotEnd = new Date(
                  activeDateStr +
                    "T" +
                    new Date(currentSlot.end).toISOString().split("T")[1]
                );
                let recurrEventEnd = new Date(
                  activeDateStr + "T" + currRecurrEnd
                );

                if (
                  currRecurr &&
                  (recurrEventSt.getTime() === slotStart.getTime() ||
                    (slotStart >= recurrEventSt && slotEnd <= recurrEventEnd) ||
                    (slotStart < recurrEventSt && recurrEventSt < slotEnd) ||
                    (slotStart < recurrEventEnd && recurrEventEnd < slotEnd))
                ) {
                  create_slot = false;
                  // recurringEvent = true;
                  nextStartBuffer = addMinutes(currRecurrEnd, parseInt(0)); // no buffer is applied for recurring events so 0
                  break;
                }
              }
            }
          }
        }
      }

      let currShortLeave = shortLeaves?.find(
        (i) => i.node.date === activeDateStr
      );
      if (currShortLeave) {
        let currShortLeaveStart = currShortLeave.node.startTime.slice(0, -3); // to convert 10:00:00 to 10:00 to match the format of existing start time in code
        let currShortLeaveEnd = currShortLeave.node.endTime.slice(0, -3);
        let shortLeaveStart = new Date(
          activeDateStr + "T" + currShortLeaveStart
        );
        let slotStart = new Date(activeDateStr + "T" + activeStartTime);
        let slotEnd = new Date(activeDateStr + "T" + currentSlotEndBuffer);
        let shortLeaveEnd = new Date(activeDateStr + "T" + currShortLeaveEnd);
        if (
          currShortLeave &&
          (shortLeaveStart.getTime() === slotStart.getTime() ||
            (slotStart >= shortLeaveStart && slotEnd <= shortLeaveEnd) ||
            (slotStart < shortLeaveStart && shortLeaveStart < slotEnd) ||
            (slotStart < shortLeaveEnd && shortLeaveEnd < slotEnd))
        ) {
          create_slot = false;
          nextStartBuffer = addMinutes(currShortLeaveEnd, parseInt(0)); // no buffer is applied for recurring events so 0
        }
      }

      if (create_slot) {
        tempAllGroupSlots.push(currentSlot);
      }
    }
    return tempAllGroupSlots;
  };

  // FUNCTION THAT CALCULATES  AVAILABLE SLOTS
  const getAllAvailableSlots = (start_date, end_date, appFetchVariables) => {
    let doctor_conditions = selectedDoctor && !!selectedDoctor.node.identifier;
    let session_conditions =
      selectedSession && selectedSession !== null ? true : false;
    let session_working_hours = getSessionAvailability(selectedSession);

    if (
      referralObjectData &&
      referralObjectData.referralObj &&
      referralObjectData.referralObj.referral
    ) {
      doctor_conditions = true;
      session_conditions = true;
    }
    let enddate = end_date; // create slots for 7 more days incase any week view has ending of nth month and start of (n+1)th month
    enddate.setDate(7);
    let group_conditions =
      isGroupAppointment &&
      selectedSession?.node?.groupSession &&
      !iseditGroupTherapy
        ? true
        : false;
    if (!selectedSession?.node?.groupSession) {
      group_conditions = true;
    }
    if (
      doctor_conditions &&
      session_conditions &&
      !myAppointments &&
      group_conditions
    ) {
      // var today = new Date();
      var today = new Date(start_date);
      if (today < new Date()) {
        today = new Date();
      }
      // IF PATIENT, START DATE IS AFTER THE PAYMENT CUTOFF TIME SO THAT THE NEXT AVAILABLE TAKES USER TO NEXT APPLICABLE SLOT
      if (!isStaff) {
        let paymentCutOffTime =
          selectedSession?.node.notification?.downPaymentNotificationCutoff;
        if (!paymentCutOffTime) {
          paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
        }
        if (paymentCutOffTime) {
          let currentTime = new Date();
          currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
          today = currentTime;
        }
      }

      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      let buffer_minutes = selectedSession?.node
        ? parseInt(selectedSession?.node.buffer)
        : 0; // change default to 0 after testing

      // YYYY-MM-DD
      var todayDateStr = yyyy + "-" + mm + "-" + dd;
      let raw_events = data;
      var fullHolidays = doctorLeavesList?.leaves?.edges.filter(
        (leave) => !leave.node.startTime
      );
      let shortLeaves = doctorLeavesList?.leaves?.edges.filter(
        (leave) => leave.node.startTime
      );

      let holidays = fullHolidays
        ? fullHolidays?.map((leave) => leave.node.date)
        : [];

      var allevents = [];
      // today is new date because we do not care about creating slots for previous days
      for (var d = today; d <= enddate; d.setDate(d.getDate() + 1)) {
        // date in the range
        let activeDate = new Date(d);
        let dd = String(activeDate.getDate()).padStart(2, "0");
        let mm = String(activeDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = activeDate.getFullYear();

        let activeDay = activeDate.getDay();
        let allSessionHoursToday = session_working_hours?.filter((i) =>
          i.daysOfWeek.some((j) => j === activeDay)
        );
        // YYYY-MM-DD
        let activeDateStr = yyyy + "-" + mm + "-" + dd;
        let businesshours = calendarExtraProps["businessHours"];
        // Check if date is on holiday do nothing
        if (
          !holidays?.includes(activeDateStr) &&
          weekdayAvailability?.includes(activeDate.getDay())
        ) {
          // business start and endtime
          let calendarBusinesshours = businesshours.filter((i) =>
            i.daysOfWeek?.includes(activeDate.getDay())
          );

          calendarBusinesshours.sort(function compare(a, b) {
            var dateA = new Date("01/01/2011");
            dateA.setHours(a.startTime.split(":")[0]);
            dateA.setMinutes(a.startTime.split(":")[1]);
            var dateB = new Date("01/01/2011");
            dateB.setHours(b.startTime.split(":")[0]);
            dateB.setMinutes(b.startTime.split(":")[1]);
            return dateA - dateB;
          });

          let sessionHoursForActiveDay =
            session_working_hours?.length > 0
              ? session_working_hours.filter((i) =>
                  i.daysOfWeek.some((j) => j === activeDay)
                )
              : [];
          if (sessionHoursForActiveDay?.length > 0) {
            calendarBusinesshours = sessionHoursForActiveDay;
          }
          for (var a in calendarBusinesshours) {
            let businesshour = calendarBusinesshours[a];
            if (businesshour) {
              let startTime = businesshour.startTime;
              let endTime = businesshour.endTime;

              let activeStartTime = startTime;

              let todayTime =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();

              // get events only for day
              // for loop on the active day and remove all date less than the active date.
              let raw_events_today = [];
              for (var i in raw_events) {
                let event = raw_events[i];
                // start ex. 2020-12-06T10:00:00Z
                let [event_date] = event.start.split("T");
                if (event_date === activeDateStr) {
                  // raw_events.splice(i, 1);
                  raw_events_today.push(event);
                } else {
                  break;
                }
              }
              // slot inside day. while activeStartTime <= endTime append event to the list
              while (
                Date.parse("01/01/2011 " + activeStartTime) <=
                Date.parse("01/01/2011 " + endTime)
              ) {
                // create the time slot
                // let slot_end = addMinutes(activeStartTime, calendarSlotDuration); // '18:35'
                // create the time slot
                let slot_end_without_buffer = addMinutes(
                  activeStartTime,
                  calendarSlotDuration
                ); // '18:35'
                let slot_end = addMinutes(
                  slot_end_without_buffer,
                  buffer_minutes
                );
                let create_slot = true;
                var appointment_buffer = 0;
                var nextStartBuffer = addMinutes(
                  slot_end,
                  parseInt(appointment_buffer)
                );
                let buffer_end = new Date(
                  activeDateStr + "T" + nextStartBuffer
                );
                let recurringEvent = false;
                let currentSlotEndBuffer = addMinutes(
                  slot_end_without_buffer.substring(0, 5),
                  parseInt(buffer_minutes)
                );
                let current_slot = {
                  start: activeDateStr + "T" + activeStartTime,
                  end: activeDateStr + "T" + currentSlotEndBuffer,
                };
                for (var j in data) {
                  let appointment = data[j];
                  if (appointment && !appointment.holdWaitingReplacement) {
                    appointment_buffer = appointment?.doctorEventType?.buffer;
                    let app_end_time = appointment.end.split("T")[1];
                    nextStartBuffer = addMinutes(
                      app_end_time.substring(0, 5),
                      parseInt(appointment_buffer)
                    );
                    buffer_end = new Date(
                      activeDateStr + "T" + nextStartBuffer
                    );
                    let app_st = appointment.start; // utc
                    var app_en = appointment.end;
                    let appStart = new Date(app_st); // kuwait
                    var appEnd = new Date(app_en); //
                    if (current_slot) {
                      let current_slot_start = new Date(current_slot.start);
                      let current_slot_end = new Date(current_slot.end);
                      let stDate = new Date(current_slot.start);
                      let etDate = new Date(app_st);
                      if (
                        stDate.setHours(0, 0, 0, 0) ===
                        etDate.setHours(0, 0, 0, 0)
                      ) {
                        if (
                          //current_slot_start is suggested slot start date , current_slot_end is suggested slot end. appStart: existing appointment start and appEnd is existing appointment end
                          //buffer_end is the buffer end the current existing appointment buffer end time in for loop that is being compared against new suggested slot
                          (current_slot_start < appEnd &&
                            current_slot_start >= appStart) ||
                          (current_slot_end < appEnd &&
                            current_slot_end > appStart) ||
                          (current_slot_start <= appStart &&
                            current_slot_end >= appEnd) ||
                          (buffer_end <= appEnd && buffer_end > appStart) ||
                          (current_slot_start <= appStart &&
                            buffer_end >= appEnd)
                        ) {
                          create_slot = false;
                          break;
                        }
                      }
                    }
                  }
                }
                // do not create slot if recurring events exists for this date and time of the loop
                if (recurringEventsList && recurringEventsList.length > 0) {
                  let activeRecurringDay = recurringEventsList.filter((i) =>
                    i.node.days.includes(activeDay + "")
                  ); //converting int active day to string to compare
                  if (activeStartTime && activeRecurringDay) {
                    for (var k = 0; k < activeRecurringDay.length; k++) {
                      let currRecurr = activeRecurringDay[k];
                      if (
                        currRecurr.node.dateUntil === null ||
                        (currRecurr.node.dateUntil &&
                          activeDate <= new Date(currRecurr.node.dateUntil) &&
                          activeDate >= new Date(currRecurr.node.startDate))
                      ) {
                        let currRecurrStart = currRecurr.node.startTime.slice(
                          0,
                          -3
                        ); // to convert 10:00:00 to 10:00 to match the format of existing start time in code
                        let currRecurrEnd = currRecurr.node.endTime.slice(
                          0,
                          -3
                        );
                        let recurrEventSt = new Date(
                          activeDateStr + "T" + currRecurrStart
                        );
                        let slotStart = new Date(
                          activeDateStr + "T" + activeStartTime
                        );
                        let slotEnd = new Date(activeDateStr + "T" + slot_end);
                        let recurrEventEnd = new Date(
                          activeDateStr + "T" + currRecurrEnd
                        );
                        if (
                          currRecurr &&
                          (recurrEventSt.getTime() === slotStart.getTime() ||
                            (slotStart >= recurrEventSt &&
                              slotEnd <= recurrEventEnd) ||
                            (slotStart < recurrEventSt &&
                              recurrEventSt < slotEnd) ||
                            (slotStart < recurrEventEnd &&
                              recurrEventEnd < slotEnd))
                        ) {
                          create_slot = false;
                          recurringEvent = true;
                          nextStartBuffer = addMinutes(
                            currRecurrEnd,
                            parseInt(0)
                          ); // no buffer is applied for recurring events so 0
                          break;
                        }
                      }
                    }
                  }
                }
                let currShortLeave = shortLeaves?.find(
                  (i) => i.node.date === activeDateStr
                );
                if (currShortLeave) {
                  let currShortLeaveStart = currShortLeave.node.startTime.slice(
                    0,
                    -3
                  ); // to convert 10:00:00 to 10:00 to match the format of existing start time in code
                  let currShortLeaveEnd = currShortLeave.node.endTime.slice(
                    0,
                    -3
                  );
                  let shortLeaveStart = new Date(
                    activeDateStr + "T" + currShortLeaveStart
                  );
                  let slotStart = new Date(
                    activeDateStr + "T" + activeStartTime
                  );
                  let slotEnd = new Date(activeDateStr + "T" + slot_end);
                  let shortLeaveEnd = new Date(
                    activeDateStr + "T" + currShortLeaveEnd
                  );
                  if (
                    currShortLeave &&
                    (shortLeaveStart.getTime() === slotStart.getTime() ||
                      (slotStart >= shortLeaveStart &&
                        slotEnd <= shortLeaveEnd) ||
                      (slotStart < shortLeaveStart &&
                        shortLeaveStart < slotEnd) ||
                      (slotStart < shortLeaveEnd && shortLeaveEnd < slotEnd))
                  ) {
                    create_slot = false;
                    recurringEvent = true;
                    nextStartBuffer = addMinutes(
                      currShortLeaveEnd,
                      parseInt(0)
                    ); // no buffer is applied for recurring events so 0
                  }
                }

                let proposed_start = slot_end;
                if (!create_slot) {
                  proposed_start = nextStartBuffer;
                  if (appEnd >= buffer_end && !recurringEvent) {
                    // we care about appointment end if slot was not created due to existing appointment not if it was a recurring event
                    let new_start =
                      appEnd.getHours() +
                      ":" +
                      String(appEnd.getMinutes()).padStart(2, "0");
                    new_start = addMinutes(
                      new_start,
                      parseInt(appointment_buffer)
                    );
                    proposed_start = new_start;
                  }
                }

                let allow_to_create_slot = allSessionHoursToday
                  ? getSlotBasedOnSession(
                      allSessionHoursToday,
                      startTime,
                      endTime,
                      current_slot
                    )
                  : true;

                if (
                  create_slot &&
                  allow_to_create_slot &&
                  Date.parse("01/01/2011 " + slot_end_without_buffer) <=
                    Date.parse("01/01/2011 " + endTime) &&
                  !(
                    todayDateStr === activeDateStr &&
                    Date.parse("01/01/2011 " + activeStartTime) <=
                      Date.parse("01/01/2011 " + todayTime)
                  )
                ) {
                  allevents.push({
                    title: "Available",
                    start: activeDateStr + "T" + activeStartTime,
                    end: activeDateStr + "T" + slot_end_without_buffer,
                    backgroundColor:
                      selectedSession?.node.eventType.color?.hexCode,
                    borderColor: selectedSession?.node.eventType.color?.hexCode,
                  });
                }
                activeStartTime = proposed_start;
                // }
              }
            }
          }
        }
      }

      let allFilters = allevents.filter((i) => i !== null && i !== undefined);
      if (appFromCal && appFromCal.selectedDateStr && appCreateFromCal) {
        let dateToBlock = appFromCal.selectedDateStr;
        let dd = String(dateToBlock.getDate()).padStart(2, "0");
        let mm = String(dateToBlock.getMonth() + 1).padStart(2, "0");
        let yy = dateToBlock.getFullYear();
        let time =
          "T" +
          String(dateToBlock.getHours()).padStart(2, "0") +
          ":" +
          String(dateToBlock.getMinutes()).padStart(2, "0");
        let dateToBlockStr = yy + "-" + mm + "-" + dd + time;
        let dateToBlockEnd = new Date(dateToBlock);
        dateToBlockEnd.setMinutes(
          dateToBlockEnd.getMinutes() + selectedSession?.node.duration
        );

        let ddEnd = String(dateToBlockEnd.getDate()).padStart(2, "0");
        let mmEnd = String(dateToBlockEnd.getMonth() + 1).padStart(2, "0");
        let yyEnd = dateToBlockEnd.getFullYear();
        let timeEnd =
          "T" +
          String(dateToBlockEnd.getHours()).padStart(2, "0") +
          ":" +
          String(dateToBlockEnd.getMinutes()).padStart(2, "0");
        let dateToBlockEndStr = yyEnd + "-" + mmEnd + "-" + ddEnd + timeEnd;

        if (allFilters.find((i) => i.start === dateToBlockStr)) {
          dateEventInVar({
            start: dateToBlockStr,
            end: dateToBlockEndStr,
          });
        } else {
          alert(
            "The selected date and timeslot on " +
              dateToBlock.toString() +
              " is not available please select other timeslot"
          );
          let dataToSave = appFromCal;
          dataToSave["selectedDateStr"] = null;
          appFromCalVar(dataToSave);
        }
      }

      if (appFetchVariables?.action === "prev") {
        // update last slot of that month as selected
        let currMonth = new Date(start_date).getMonth();
        let lastSlotForCurrMonth = _.findLastIndex(allFilters, function (o) {
          return new Date(o.start).getMonth() === currMonth;
        });
        if (lastSlotForCurrMonth > -1) {
          let lastSlot = allFilters[lastSlotForCurrMonth];
          let dateToSave = {
            start: lastSlot.start,
            end: lastSlot.end,
          };
          setTimeout(() => {
            dateEventInVar(dateToSave);
            currentSlotIndexVar(lastSlotForCurrMonth);
          }, 300);
        }
      }
      calendarSlotsVar({
        allFilters: allFilters,
        slotsUntil: enddate,
      });
      setAvailableSlotsArr(allFilters);
    } else if (selectedSession?.node?.groupSession) {
      let fullHolidays = doctorLeavesList?.leaves?.edges.filter(
        (leave) => !leave.node.startTime
      );
      let holidays = fullHolidays
        ? fullHolidays?.map((leave) => leave.node.date)
        : [];
      let shortLeaves = doctorLeavesList?.leaves?.edges.filter(
        (leave) => leave.node.startTime
      );
      if (Array.isArray(data)) {
        let allFilters = data.filter((i) => i.mainSlot);
        let allGroupRecurrSlots = [];
        if (
          (!editableAppointment ||
            (editableAppointment && isGroupEditPatient)) &&
          !iseditGroupTherapy
        ) {
          allFilters.map((groupEvent) => {
            if (
              groupEvent.groupStartDate &&
              groupEvent.groupEndDate &&
              groupEvent.groupDays.length > 0
            ) {
              let recurrGroupSlots = getRecurGroupSlotsFunc(
                holidays,
                groupEvent,
                shortLeaves
              );
              allGroupRecurrSlots.push(...recurrGroupSlots);
            } else {
              allGroupRecurrSlots.push(groupEvent);
            }
          });
        }
        if ((editableAppointment && isGroupAddPatient) || iseditGroupTherapy) {
          let addPatientToGroup = {};
          addPatientToGroup["start"] = editableAppointment.start;
          addPatientToGroup["end"] = editableAppointment.end;
          addPatientToGroup["backgroundColor"] =
            selectedSession?.node.eventType.color?.hexCode;
          addPatientToGroup["borderColor"] =
            selectedSession?.node.eventType.color?.hexCode;
          allGroupRecurrSlots = [];
          allGroupRecurrSlots.push(addPatientToGroup);
        }

        if (!isStaff) {
          let currentTime = new Date();
          let paymentCutOffTime =
            selectedSession?.node.notification?.downPaymentNotificationCutoff;
          if (!paymentCutOffTime) {
            paymentCutOffTime = settings_obj?.settings?.downpayment_cutoff_time;
          }
          if (paymentCutOffTime) {
            currentTime.setHours(currentTime.getHours() + paymentCutOffTime);
            allGroupRecurrSlots = allGroupRecurrSlots.filter((i) => {
              let appStartDate = new Date(i.start);
              if (appStartDate >= currentTime) {
                //1 aug 10:00am >= 2 aug 2pm
                return i;
              }
            });
          }
        }
        let sortedAllGroupRecurrSlots = _.sortBy(
          allGroupRecurrSlots,
          function (dateObj) {
            return new Date(dateObj.start);
          }
        ); // sort recurring group therapy by date
        calendarSlotsVar({
          allFilters: sortedAllGroupRecurrSlots,
          slotsUntil: enddate,
        });
        setAvailableSlotsArr(sortedAllGroupRecurrSlots);
      }
    } else if (myAppointments) {
      if (Array.isArray(data)) {
        let allFilters = data?.filter((i) => !i.parent && i.patient);
        allFilters = allFilters.map((appointment) => {
          if (appointment.children?.edges.length > 0) {
            let children = appointment.children?.edges.map((item) => {
              return item.node;
            });
            let uniqueChildren = _.uniq(_.map(children, "start"));
            return uniqueChildren.map((item) => {
              let app = appointment.children.edges.find(
                (i) => i.node.start === item
              );
              let newCreatedEventRecurr = JSON.parse(
                JSON.stringify(appointment)
              );
              newCreatedEventRecurr["start"] = app.node.start;
              newCreatedEventRecurr["end"] = app.node.end;
              newCreatedEventRecurr["passDateInURL"] = app.node.start;
              return newCreatedEventRecurr;
            });
          } else {
            return appointment;
          }
        });
        allFilters = allFilters.flat(2);
        allFilters = allFilters.filter(
          (i) => !i?.doctorEventType?.groupSession
        ); // remove all appointments that are under group therapy
        let groupRecurringEvents = data.filter((i) => !i.patient);
        let allGroupRecurrSlots = [];
        let fullHolidays = doctorLeavesList?.leaves?.edges.filter(
          (leave) => !leave.node.startTime
        );
        let shortLeaves = doctorLeavesList?.leaves?.edges.filter(
          (leave) => leave.node.startTime
        );
        let holidays = fullHolidays
          ? fullHolidays?.map((leave) => leave.node.date)
          : [];
        groupRecurringEvents.map((groupEvent) => {
          if (
            groupEvent.groupStartDate &&
            groupEvent.groupEndDate &&
            groupEvent.groupDays.length > 0
          ) {
            let recurrGroupSlots = getRecurGroupSlotsFunc(
              holidays,
              groupEvent,
              shortLeaves
            );
            allGroupRecurrSlots.push(...recurrGroupSlots);
          } else {
            let newCreatedEventRecurr = JSON.parse(JSON.stringify(groupEvent));
            newCreatedEventRecurr["passDateInURL"] = groupEvent.start;
            allGroupRecurrSlots.push(newCreatedEventRecurr);
          }
        });
        if (allGroupRecurrSlots.length > 0) {
          allFilters.push(...allGroupRecurrSlots);
        }

        setAvailableSlotsArr(allFilters);
      }
    } else {
      setAvailableSlotsArr([]);
    }

    setTimeout(() => {
      displayLoaderVar(false);
    }, 300);
  };

  // INITIALIZE CALENDAR BASED ON FULL CALENDAR REF AND EVENTS DATA
  useEffect(() => {
    let refCalendarView =
      fullCalendarRef && fullCalendarRef.current
        ? fullCalendarRef?.current?.getApi()?.view
        : null;

    if (refCalendarView) {
      let current_month = refCalendarView.activeEnd.getMonth();
      let calendarEnd = refCalendarView.activeEnd;
      let current_year = refCalendarView.activeStart.getFullYear();
      if (
        calendarActiveMonth !== null &&
        current_month !== calendarActiveMonth
      ) {
        current_month = calendarActiveMonth;
      }
      let dateStart = new Date(current_year, current_month, 1);
      let dateEnd = new Date(current_year, current_month + 1, 1);
      if (calendarEnd > dateEnd) {
        dateEnd = calendarEnd;
      }
      dateEnd.setDate(dateEnd.getDate() + 7);
      dateEnd.setHours(23);
      getAllAvailableSlots(dateStart, dateEnd, appFetchVariables);
      if (calendarActiveMonth === null) {
        calendarActiveMonthVar(current_month);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCalendarRef, data, appFetchVariables]);

  // SET STATE FROM REACTIVE VARIBALE
  useEffect(() => {
    setData(eventsDataMain);
  }, [eventsDataMain]);

  // RECURRING EVENTS DAYS SET BASED ON EVERYDAY OR FEW DAYS
  useEffect(() => {
    if (selectedDoctor && selectedDoctor.node) {
      let recurringEvents = selectedDoctor?.node?.recurringEvents?.edges;
      let recurrArr = recurringEvents?.map((item) => {
        if (item.node.everyday) {
          let new_item = {
            dateUntil: item.node.dateUntil,
            days: ["0", "1", "2", "3", "4", "5", "6"],
            description: item.node.description,
            endTime: item.node.endTime,
            everyday: true,
            startTime: item.node.startTime,
            startDate: item.node.startDate,
            title: item.node.title,
            resourceId: selectedDoctor.node.id,
          };
          return {
            node: new_item,
          };
        }
        let val = JSON.parse(JSON.stringify(item.node));
        val["resourceId"] = selectedDoctor.node.id;
        return {
          node: val,
        };
        // return item;
      });
      setRecurringEventsList(recurrArr);
    }
    if (selectedDocList) {
      let allRecurrEvent = [];
      selectedDocList.map((selDoc) => {
        let setDocObj = doctorsList?.doctors?.edges.find(
          (i) => i.node.identifier === selDoc.value
        );
        let recurringEvents = setDocObj?.node?.recurringEvents?.edges
          ? setDocObj?.node?.recurringEvents?.edges
          : [];
        let recurrArr = recurringEvents.map((item) => {
          if (item.node.everyday) {
            let new_item = {
              dateUntil: item.node.dateUntil,
              days: ["0", "1", "2", "3", "4", "5", "6"],
              description: item.node.description,
              endTime: item.node.endTime,
              everyday: true,
              startTime: item.node.startTime,
              startDate: item.node.startDate,
              title: item.node.title,
              resourceId: setDocObj.node.id,
            };
            return {
              node: new_item,
            };
          }
          let val = JSON.parse(JSON.stringify(item.node));
          val["resourceId"] = setDocObj.node.id;
          return {
            node: val,
          };
          // return item;
        });
        allRecurrEvent.push(...recurrArr);
      });
      setRecurringEventsList(allRecurrEvent);
    }
  }, [selectedDoctor, selectedDocList, doctorsList]);

  //FUNCTION THAT TRIGGERS ON EVERY VIEW CHANGE
  const handleViewChange = (info) => {
    displayLoaderVar(true);
    clearTimeout(time);
    setTime(
      setTimeout(() => {
        let startStr = info.startStr;
        let view = info.view.type;
        let doctorArrStr = urlActions(window.location.href, "get", "doctor");
        let status = urlActions(window.location.href, "get", "status");
        let patient_name = urlActions(
          window.location.href,
          "get",
          "patient_name"
        );
        let patient = urlActions(window.location.href, "get", "patient");
        let patientId = urlActions(window.location.href, "get", "patientId");
        let calendarInitView = urlActions(
          window.location.href,
          "get",
          "calendarInitView"
        );
        let calendarInitDate = urlActions(
          window.location.href,
          "get",
          "calendarInitDate"
        );
        let calParamUrl =
          "?calendarInitDate=" + startStr + "&calendarInitView=" + view;
        if (doctorArrStr) {
          calParamUrl += "&doctor=" + doctorArrStr;
        }
        if (patient_name) {
          calParamUrl += "&patient_name=" + patient_name;
        }
        if (patient) {
          calParamUrl += "&patient=" + patient;
        }
        if (patientId) {
          calParamUrl += "&patientId=" + patientId;
        }
        if (status) {
          calParamUrl += "&status=" + status;
        }
        if (calMounted && myAppointments) {
          // window.history.pushState(null, '',calParamUrl);
        }
        let current_year = info.view.activeStart.getFullYear();
        let cur_cal_month = info.view.activeStart.getMonth();
        let calendarEnd = info.end;

        if (selectedDoctor?.node?.availability) {
          getActiveDocShifts(
            info.view.activeStart,
            info.view.activeEnd,
            selectedDoctor,
            recurringEventsList
          );
        }

        if (selectedDocList) {
          selectedDocList.map((selDoc) => {
            let setDocObj = doctorsList?.doctors?.edges.find(
              (i) => i.node.identifier === selDoc.value
            );
            if (setDocObj?.node?.availability) {
              getActiveDocShifts(
                info.view.activeStart,
                info.view.activeEnd,
                setDocObj,
                recurringEventsList
              );
            }
          });
        }

        if (calendarActiveMonth !== null || myAppointments) {
          if (!myAppointments) {
            calendarActiveMonthVar(cur_cal_month);
          }

          let dateStart = new Date(current_year, cur_cal_month, 1);
          let dateEnd = new Date(current_year, cur_cal_month + 1, 1);
          if (calendarEnd > dateEnd) {
            dateEnd = calendarEnd;
          }
          dateEnd.setDate(dateEnd.getDate() + 7);
          dateEnd.setHours(23);
          let getEventsVar = {
            // doctor_identifier: selectedDoctor.node.identifier,
            start: getValidDateStringForGraphQL(dateStart),
            end: getValidDateStringForGraphQL(dateEnd),
          };
          if (
            !myAppointments &&
            (selectedDoctor || referralObjectData?.referralObj)
          ) {
            if (selectedDoctor) {
              getEventsVar["doctor_identifier"] =
                selectedDoctor.node.identifier;
            } else if (
              referralObjectData &&
              referralObjectData.referralObj &&
              referralObjectData.referralObj.referral.referredDoctor
            ) {
              getEventsVar["doctor_identifier"] =
                referralObjectData.referralObj.referral.referredDoctor.identifier;
            }
            if (editableAppointment) {
              getEventsVar["excludeId"] = editableAppointment.eventId + "";
            }
            let patient = urlActions(window.location.href, "get", "patient");
            if (patient) {
              getEventsVar["patient_Identifier"] = patient;
            }
            if (!isStaff) {
              GetEvents({ variables: getEventsVar });
            }
          } else if (myAppointments) {
            let dtEn = new Date(info.view.activeEnd);
            dtEn.setDate(dtEn.getDate() - 1);
            getEventsVar = {
              // doctor_identifier: selectedDoctor.node.identifier,
              start: getValidDateStringForGraphQL(info.view.activeStart),
              end: getValidDateStringForGraphQL(dtEn),
            };

            let doctorArrStr = urlActions(
              window.location.href,
              "get",
              "doctor"
            );
            let status = urlActions(window.location.href, "get", "status");
            let patient = urlActions(window.location.href, "get", "patient");
            let docListArr = doctorArrStr?.split("__");

            getEventsVar["patient_Identifier"] = patient;
            if (doctorArrStr) {
              getEventsVar["doctors"] = docListArr;
            }
            getEventsVar["status"] = status;
            getEventsVar["active"] =
              status === "no_show" || status === "cancelled" ? false : true;
            if (editableAppointment?.eventId) {
              getEventsVar["excludeId"] = editableAppointment.eventId + "";
            }

            if (appointmentCreate) {
              if (selectedDoctor) {
                getEventsVar["doctor_identifier"] =
                  selectedDoctor.node.identifier;
                GetEvents({ variables: getEventsVar });
              } else {
                displayLoaderVar(false);
              }
            } else {
              if (
                info?.view?.type !== calendarInitView ||
                info.startStr.split("T")[0] !== calendarInitDate
              ) {
                GetEvents({ variables: getEventsVar });
              } else {
                setVariablesToBeFetched(getEventsVar);
              }
            }
          }
        }
        let amt = 10;
        if (info.view.type === "timeGridWeek") {
          amt = 25;
        } else if (info.view.type === "dayGridMonth") {
          amt = 3;
        }
        if (amt !== amountOfEvents) {
          setAmountOfEvents(amt);
        }
      }, 300)
    );
  };

  const getHeight = () => {
    const bodyHeight = document.querySelector(".fc-view-harness");
    if (bodyHeight) {
      setCalendarBodyHeight({ height: bodyHeight.clientHeight + "px" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getHeight();
    }, 200);
    window.addEventListener("resize", getHeight);
    return () => {
      window.removeEventListener("resize", getHeight);
      calendarSlotsVar(null);
    };
  }, []);

  const renderPopup = () => {
    if (coords?.length > 0) {
      return (
        <div
          className="cancel"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 99,
          }}
          onClick={() => {
            setcoords([]);
          }}
        >
          <div
            style={{
              top: coords[1],
              left: coords[0],
            }}
            className="cal-popup-container"
          >
            <div>
              {selectedDateStr?.getHours()}:
              {("0" + selectedDateStr?.getMinutes()).slice(-2)} - Create a:
            </div>
            <div className="cal-link-container">
              <Link
                to={{
                  pathname: "/create/appointment",
                  state: { appCreateFromCal: true },
                }}
                // to={`/create/appointment`}
                // params={{appFromCal:true}}
                onClick={() => {
                  let dataToPass = appFromCal ? appFromCal : {};
                  dataToPass["selectedDateStr"] = selectedDateStr;
                  dataToPass["selectedDocToCreateEvent"] =
                    selectedDocToCreateEvent;
                  appFromCalVar(dataToPass);
                }}
                className="hover-link"
              >
                Appointment
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderEventContent = (eventInfo) => {
    let slotAvailable = null;
    if (eventInfo.event._def?.extendedProps?.slotsAvailable) {
      // if group slot
      slotAvailable = eventInfo.event._def?.extendedProps?.slotsAvailable;
      let hasRelatedSchedule =
        eventInfo.event._def?.extendedProps?.multipleScheduleSlots?.edges?.find(
          (i) =>
            new Date(i.node.start).getTime() ===
            new Date(eventInfo.event.startStr).getTime()
        );
      if (hasRelatedSchedule) {
        slotAvailable = hasRelatedSchedule.node.slotsAvailable;
      }
    }
    let eventTitle = eventInfo.event.title;

    return (
      <div>
        <OverlayTrigger
          placement="top"
          // show={true}
          shouldUpdatePosition={true}
          overlay={
            <Tooltip id="tooltip-top">
              {eventTitle} {eventInfo.timeText}{" "}
              {slotAvailable ? (
                <span>- SLOTS AVAILABLE: {slotAvailable}</span>
              ) : null}
            </Tooltip>
          }
        >
          <div
            className={
              "fc-event-main-frame" +
              (eventInfo.event._def.groupId === "recurringGroupId"
                ? " recurringEventSlot "
                : "")
            }
            data-app-identifier={
              eventInfo.event._def?.extendedProps?.identifier
            }
            data-doc-name={
              eventInfo.event._def?.extendedProps?.doctor?.firstName +
              " " +
              eventInfo.event._def?.extendedProps?.doctor?.lastName
            }
          >
            <div className="fc-event-time">
              {eventTitle} {eventInfo.timeText}{" "}
              {slotAvailable ? (
                <span>- SLOTS AVAILABLE: {slotAvailable}</span>
              ) : null}
            </div>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const selectedDocsIds = selectedDocList?.map((i) => i.doctorId);
  let otherCalProps = {};
  if (isStaff) {
    otherCalProps["resources"] =
      selectedDocsIds?.length > 0
        ? doctorResources?.filter((i) =>
            selectedDocsIds.some((j) => i.doctorId === j)
          )
        : doctorResources;
  }

  const eventClickFunc = (info) => {
    if (!myAppointments && !isStaff) {
      if (
        info.event?._def?.groupId !== "recurringGroupId" &&
        info.event?._def?.groupId !== "shiftGroupId"
      ) {
        let dateToSave = {
          start: info.event.startStr,
          end: info.event.endStr,
        };
        if (
          info.event._def.publicId &&
          info.event._def?.extendedProps?.doctorEventType?.groupSession
        ) {
          dateToSave["groupSession"] = true;
          dateToSave["slotsAvailable"] =
            info.event._def?.extendedProps?.slotsAvailable;
          dateToSave["appointmentID"] = info.event._def.publicId;
        }
        dateEventInVar(dateToSave); // save event date to book slot only if its not a recurring event
      }
    } else {
      if (!appointmentCreate) {
        if (info.event?._def?.groupId !== "shiftGroupId") {
          let startStr = info.view.activeStart.toISOString();
          let view = info.view.type;
          let publicId = null;
          if (info.event._def?.extendedProps) {
            publicId = info.event._def.publicId;
          }
          // let calParamUrl="?calendarInitDate="+startStr+"&calendarInitView="+view;

          let calParamUrl =
            "calendarInitDate=" + startStr + "&calendarInitView=" + view;
          let newParams = "?";
          if (window.location.search) {
            newParams = urlActions(
              window.location.href,
              "delete",
              "calendarInitView"
            );
            newParams = urlActions(
              window.location.protocol +
                "//" +
                window.location.host +
                "?" +
                newParams,
              "delete",
              "calendarInitDate"
            );
            if (newParams) {
              newParams += "&" + calParamUrl;
            } else {
              newParams = calParamUrl;
            }
          } else {
            newParams += calParamUrl;
          }

          setTimeout(() => {
            if (publicId) {
              let app_detail_url = isStaff
                ? "/appointment/detail/"
                : "/patientPortal/appointment/detail/";
              let app_url = app_detail_url + publicId;
              if (info.event._def?.extendedProps?.passDateInURL) {
                let urlToPush =
                  app_url +
                  "?" +
                  "passDateInURL=" +
                  info.event._def?.extendedProps?.passDateInURL;
                setTimeout(() => {
                  window.open(urlToPush);
                }, 300);
              } else {
                let urlToPush = app_url;
                setTimeout(() => {
                  window.open(urlToPush);
                }, 300);
              }
            }
          }, 100);
        }
      }
    }
  };

  return (
    <>
      {renderPopup()}
      <div className="calendar-container">
        {displayLoader ? <Preloader positionAbsoluteLoader={true} /> : null}
        <FullCalendar
          customButtons={{
            myCustomToday: {
              text: t("calendar.today"),
              click: function () {
                fullCalendarRef?.current
                  ?.getApi()
                  ?.changeView(
                    isStaff ? "resourceTimeGridDay" : "timeGridDay",
                    getValidDateStringForGraphQL(new Date())
                  );
              },
            },
            resourceTimeGridWeek: {
              text: t("calendar.week"),
              click: function () {
                if (fullCalendarRef.current) {
                  let currentView = fullCalendarRef?.current?.getApi()?.view;
                  let currentWeekSunday = getSunday(
                    new Date(currentView.activeStart)
                  );
                  fullCalendarRef?.current
                    ?.getApi()
                    ?.changeView(
                      "resourceTimeGridWeek",
                      getValidDateStringForGraphQL(currentWeekSunday)
                    );
                }
              },
            },
            prev: {
              click: function () {
                if (fullCalendarRef.current) {
                  let currentView = fullCalendarRef?.current?.getApi()?.view;

                  let currentWeekSunday = getSunday(
                    new Date(currentView.activeStart)
                  );
                  if (currentView.type.toLowerCase().indexOf("week") > -1) {
                    let prevStart = new Date(currentView.currentStart);
                    prevStart.setDate(prevStart.getDate() - 7);
                    currentWeekSunday = getSunday(new Date(prevStart));
                    fullCalendarRef?.current
                      ?.getApi()
                      ?.changeView(
                        currentView.type,
                        getValidDateStringForGraphQL(currentWeekSunday)
                      );
                  } else if (
                    currentView.type.toLowerCase().indexOf("timegridday") > -1
                  ) {
                    let prevStart = new Date(currentView.currentStart);
                    prevStart.setDate(prevStart.getDate() - 1);
                    fullCalendarRef?.current
                      ?.getApi()
                      ?.changeView(
                        currentView.type,
                        getValidDateStringForGraphQL(prevStart)
                      );
                  } else {
                    let prevStart = new Date(currentView.currentStart);
                    prevStart.setMonth(prevStart.getMonth() - 1);
                    prevStart.setDate(1);
                    fullCalendarRef?.current
                      ?.getApi()
                      ?.changeView(
                        currentView.type,
                        getValidDateStringForGraphQL(prevStart)
                      );
                  }
                }
              },
            },
            next: {
              click: function () {
                if (fullCalendarRef.current) {
                  let currentView = fullCalendarRef?.current?.getApi()?.view;

                  let currentWeekSunday = getSunday(
                    new Date(currentView.activeStart)
                  );
                  if (currentView.type.toLowerCase().indexOf("week") > -1) {
                    let nextStart = new Date(currentView.currentStart);
                    nextStart.setDate(nextStart.getDate() + 7);
                    currentWeekSunday = getSunday(new Date(nextStart));
                    fullCalendarRef?.current
                      ?.getApi()
                      ?.changeView(
                        currentView.type,
                        getValidDateStringForGraphQL(currentWeekSunday)
                      );
                  } else if (
                    currentView.type.toLowerCase().indexOf("timegridday") > -1
                  ) {
                    let nextStart = new Date(currentView.currentStart);
                    nextStart.setDate(nextStart.getDate() + 1);
                    fullCalendarRef?.current
                      ?.getApi()
                      ?.changeView(
                        currentView.type,
                        getValidDateStringForGraphQL(nextStart)
                      );
                  } else {
                    let nextStart = new Date(currentView.currentStart);
                    nextStart.setMonth(nextStart.getMonth() + 1);
                    nextStart.setDate(1);
                    fullCalendarRef?.current
                      ?.getApi()
                      ?.changeView(
                        currentView.type,
                        getValidDateStringForGraphQL(nextStart)
                      );
                  }
                }
              },
            },
            timeGridWeek: {
              text: t("calendar.week"),
              click: function () {
                if (fullCalendarRef.current) {
                  let currentView = fullCalendarRef?.current?.getApi()?.view;
                  let currentWeekSunday = getSunday(
                    new Date(currentView.activeStart)
                  );
                  fullCalendarRef?.current
                    ?.getApi()
                    ?.changeView(
                      "timeGridWeek",
                      getValidDateStringForGraphQL(currentWeekSunday)
                    );
                }
              },
            },
            timeGridDay: {
              text: t("calendar.day"),
              click: function () {
                if (fullCalendarRef.current) {
                  let currentView = fullCalendarRef?.current?.getApi()?.view;
                  fullCalendarRef?.current
                    ?.getApi()
                    ?.changeView(
                      "timeGridDay",
                      getValidDateStringForGraphQL(currentView.activeStart)
                    );
                }
              },
            },
            resourceTimeGridDay: {
              text: t("calendar.day"),
              click: function () {
                if (fullCalendarRef.current) {
                  let currentView = fullCalendarRef?.current?.getApi()?.view;
                  fullCalendarRef?.current
                    ?.getApi()
                    ?.changeView(
                      "resourceTimeGridDay",
                      getValidDateStringForGraphQL(currentView.activeStart)
                    );
                }
              },
            },
          }}
          nowIndicator={true}
          locale={i18n.language}
          eventContent={renderEventContent}
          eventTextColor="#000"
          datesSet={handleViewChange}
          titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
          ref={fullCalendarRef}
          schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
          plugins={[
            dayGridPlugin,
            timeGridWeek,
            timeGridPlugin,
            interactionPlugin,
            resourceTimeGridPlugin,
            scrollGridPlugin,
          ]}
          datesAboveResources={true}
          dayHeaders={true}
          slotLabelInterval={{ hours: 1 }}
          // initialView={calendarViewStored !== '' && calendarViewStored !== null ? calendarViewStored : calendarView}
          initialView={
            myAppointments && isStaff && !appointmentCreate
              ? "resourceTimeGridWeek"
              : "timeGridWeek"
          } //TODO: Tabasum. jhovel changed this from resourcetimegridweek to timegridweek. to temporarily fix the issue on patient
          weekends={true}
          moreLinkText={width <= 500 ? "" : "more"}
          editable={false}
          unselectAuto={false}
          selectable={true}
          selectMirror={false}
          slotDuration={
            myAppointments ? "00:15:00" : "00:" + calendarSlotDuration + ":00"
          }
          slotMinTime={slotSt + ":00:00"}
          slotMaxTime={slotEnd + ":00:00"}
          eventDisplay="list-item"
          events={
            availableSlotsArr.length > 0
              ? [
                  ...availableSlotsArr,
                  ...activeDocShiftArr,
                  ...potentialAppointmentSlot,
                ]
              : [...activeDocShiftArr, ...potentialAppointmentSlot]
          }
          dayMaxEvents={amountOfEvents}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: true,
            hour12: true,
          }}
          headerToolbar={{
            left: "prev,next myCustomToday",
            center: "title",
            right:
              myAppointments && isStaff && !appointmentCreate
                ? "dayGridMonth,resourceTimeGridWeek,resourceTimeGridDay"
                : "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          dayMinWidth={myAppointments ? 200 : 100}
          views={{
            resourceTimeGridWeek: {
              type: "resourceTimeGrid",
              duration: { days: 7 },
              buttonText: t("calendar.week"),
            },
            resourceTimeGridDay: {
              buttonText: t("calendar.day"),
            },
            dayGridMonth: {
              buttonText: t("calendar.month"),
            },
            timeGridWeek: {
              type: "timeGridWeek",
              duration: { days: 7 },
              buttonText: t("calendar.week"),
            },
            timeGridDay: {
              buttonText: t("calendar.day"),
            },
            dayGridMonth: {
              buttonText: t("calendar.month"),
            },
          }}
          eventClick={(info) => eventClickFunc(info)}
          select={handleChangeView}
          showNonCurrentDates={false}
          // height={'auto'}
          eventClassNames={(info) => {
            if (
              selectedDateEvent &&
              selectedDateEvent.dateEvent &&
              selectedDateEvent.dateEvent.start &&
              selectedDateEvent.dateEvent.end
            ) {
              let selectedStart = new Date(
                selectedDateEvent.dateEvent.start
              ).toString();
              let infoStart = new Date(info.event.startStr).toString();
              if (infoStart === selectedStart) {
                return "selectedSlot";
              }
            }
            if (info?.event?._def?.groupId === "potentialSlot") {
              return "selectedSlot";
            }
          }}
          selectAllow={handleSelectAllow}
          dateClick={(info) => {
            if (!appointmentCreate) {
              if (
                myAppointments &&
                isStaff &&
                fullCalendarRef.current &&
                info.view.type == "dayGridMonth"
              ) {
                let currentView = fullCalendarRef?.current?.getApi()?.view;
                fullCalendarRef?.current
                  ?.getApi()
                  ?.changeView(
                    "resourceTimeGridDay",
                    getValidDateStringForGraphQL(currentView.activeStart)
                  );
                let dayButton = document.getElementsByClassName(
                  "fc-resourceTimeGridDay-button"
                );
                if (dayButton && dayButton[0]) {
                  dayButton[0].focus();
                }
              }
              if (
                info.date >= new Date() &&
                myAppointments &&
                info.view.type !== "dayGridMonth" &&
                (info.jsEvent.target.classList.contains("fc-bg-event") ||
                  info.jsEvent.target.classList.contains("fc-highlight"))
              ) {
                setcoords([info.jsEvent.pageX, info.jsEvent.pageY]);
                setSelectedDateStr(info.date);
                if (info.resource.extendedProps?.identifier) {
                  setSelectedDocToCreateEvent(
                    info.resource.extendedProps?.identifier
                  );
                }
              }
            }
          }}
          defaultRangeSeparator=" - "
          initialDate={getSunday(new Date())}
          {...otherCalProps}
        />
        {/* <h2> patient calendar</h2> */}
        {(!selectedDoctor || !selectedSession) &&
        !myAppointments &&
        (!referralObjectData || !referralObjectData.referralObj) ? (
          <div style={calendarBodyHeight} className="calendar-overlay">
            <span>
              {t("patientCalendar.selectPractitionerAnd")} <br />{" "}
              {t("patientCalendar.practitionerSession")}
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default withRouter(Calendar);
