import { Row, Col, Button, Table, Dropdown } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Base from "./base.js";
import { Link, withRouter } from "react-router-dom";
import {
  gql,
  useQuery,
  useMutation,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";
import {
  error_options,
  success_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  getPermissionForAction,
  isUserStaff,
  getDate,
  urlActions,
  displayMiddleName,
  getErrorMessage,
  concatAllErrors,
  CopyToClipBoardButton,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import { patientBadgesVar } from "../cache/cache";
import * as Sentry from "@sentry/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import ReportManualPayment from "./ReportManualPayment.js";

const statusChoices = [
  { id: null, label: "------" },
  { id: "draft", label: "Draft" },
  { id: "pending", label: "Pending" },
  { id: "paid", label: "Paid" },
  { id: "pending_approval", label: "Pending approval" },
  { id: "cancelled", label: "Cancelled" },
  { id: "completed", label: "Completed" },
];

function RequestReport(props) {
  const [openSnackbar] = useSnackbar(error_options);
  const [openSnackbarSuccess] = useSnackbar(success_options);
  let patientId = urlActions(window.location.href, "get", "patientId");
  const [modalOpen, setModalOpen] = useState(false);
  const [activeReport, setActiveReport] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hide, setHide] = useState(false);
  const openModal = (item) => {
    setActiveReport(item);
    setModalOpen(true);
  };

  const isStaff = isUserStaff();
  let returnAllSectionPermissions = true;
  const patient_report_permission = getPermissionForAction(
    "patient_report",
    "",
    returnAllSectionPermissions
  );
  const { t } = useTranslation();
  const patientBadgesObject = useReactiveVar(patientBadgesVar);

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;

  const { data } = useQuery(USER_DETAILS);

  var userDetails = data && data.userDetails ? data.userDetails : null;

  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const REQUEST_PATIENT_REPORTS = gql`
    query patientReports($status: String, $hide: Boolean!) {
      patientReports(status: $status, hide: $hide) {
        edges {
          node {
            hide
            id
            status
            reason
            paid
            created
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient {
              firstName
              middleName
              lastName
              id
            }
            reportType {
              id
              name
              price
              description
            }
            languageEn
            doctor {
              id
              firstName
              lastName
            }
            payments {
              edges {
                node {
                  amount
                  sources {
                    edges {
                      node {
                        paymentLink
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const REQUEST_PATIENT_REPORTS_BY_DOC = gql`
    query doctorPatientReports($status: String, $hide: Boolean!) {
      doctorPatientReports(status: $status, hide: $hide) {
        edges {
          node {
            hide
            id
            status
            reason
            created
            paid
            clinicalSummary
            treatmentRecommendation
            notes
            daignosis
            patient {
              firstName
              middleName
              lastName
              id
            }
            reportType {
              id
              name
              price
              description
            }
            languageEn
            doctor {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  `;

  const REQUEST_SINGLE_PATIENT_REPORTS = gql`
  query patientReports($status: String $hide: Boolean) {
    patientReports(patient:"${patientId}" status:$status hide: $hide){
      edges {
        node {
          hide
            id
            status
            reason
            created
            clinicalSummary
            paid
            treatmentRecommendation
            notes
            daignosis
            payments {
              edges {
                  node {
                    amount
                      sources {
                          edges {
                              node {
                                  paymentLink
                              }
                          }
                      }
                  }
              }
            }
            patient{
              firstName
              middleName
              lastName
              id
            }
            reportType{
              id
              name
              price
              description
            }
            languageEn
            doctor{
              id
              firstName
              lastName
            }
        }
      }
    }
  }
`;

  const DELETE_PATIENT_REPORT = gql`
    mutation deletePatientReport($id: ID!) {
      deletePatientReport(id: $id) {
        deleted
      }
    }
  `;

  const REQ_PATIENT_REPORT = patientId
    ? REQUEST_SINGLE_PATIENT_REPORTS
    : REQUEST_PATIENT_REPORTS;
  // FIXME: not sure what this is supposed to achieve, but this is overriding the request report by patient ID
  // For now, I've overriden it AGAIN to get the reports by patient ID.
  const REQ_REPORT =
    isStaff && userDetails?.doctor && userDetails?.doctor?.id && !patientId
      ? REQUEST_PATIENT_REPORTS_BY_DOC
      : REQ_PATIENT_REPORT;

  // delete form instance
  const [deletePatientReport] = useMutation(DELETE_PATIENT_REPORT, {
    onCompleted: ({ deletePatientReport }) => {
      if (
        deletePatientReport?.errors &&
        deletePatientReport?.errors?.length > 0
      ) {
        let error_messages_string = getErrorMessage(
          deletePatientReport?.errors
        );
        openSnackbar(error_messages_string, [SNACK_DURATION]);
      } else if (deletePatientReport.deleted) {
        openSnackbarSuccess("Patient Report Request Deleted");
      }
    },
    refetchQueries: () => getPatientReports({ variables: { hide: hide } }),
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("DELETE_PATIENT_REPORT error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  // GET REQUEST_PATIENT_REPORTS DATA
  const [getPatientReports, { data: patient_reports_obj = null }] =
    useLazyQuery(REQ_REPORT, {
      fetchPolicy: "no-cache",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQ_REPORT error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    });

  const getButtons = () => {
    return (
      <div className="d-flex">
        <Link to={`/patientPortal/report/request/create/${patientId}`}>
          <Button variant="primary" className="back" id="create-rep-req-test">
            {" "}
            <i className="fa fa-plus-circle mr-2" aria-hidden="true"></i>{" "}
            {t("patientrequestReport.createReportRequest")}{" "}
          </Button>
        </Link>
      </div>
    );
  };

  useEffect(() => {
    getPatientReports({ variables: { status: selectedOption, hide: hide } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hide]);

  const deleteReportFunc = async (reportReq) => {
    let val = {
      id: reportReq.node.id,
    };
    if (window.confirm("Delete Report Request?")) {
      await deletePatientReport({ variables: val });
    }
  };

  const filterList = (status) => {
    getPatientReports({ variables: { status: status, hide: hide } });
  };

  var patient_reports_data = null;
  if (
    isStaff &&
    patient_reports_obj &&
    patient_reports_obj.doctorPatientReports
  ) {
    patient_reports_data = patient_reports_obj.doctorPatientReports;
  } else if (patient_reports_obj && patient_reports_obj.patientReports) {
    patient_reports_data = patient_reports_obj.patientReports;
  }

  const REQUEST_PATIENT_BADGES = gql`
    query {
      patientBadges
    }
  `;

  const [getPatientBadges, { data: patientBadges }] = useLazyQuery(
    REQUEST_PATIENT_BADGES,
    {
      fetchPolicy: "network-only",
      onCompleted: ({ patientBadges }) => {
        patientBadgesVar(patientBadges);
      },
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("REQUEST_PATIENT_BADGES error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const POST_PATIENTS_REPORT_REQ_READ = gql`
    mutation patientReportReadView($reports: [ID]!) {
      patientReportReadView(input: { reports: $reports }) {
        updated
      }
    }
  `;

  // EVENT PRINT CODE
  const [postReportReqReadView] = useMutation(POST_PATIENTS_REPORT_REQ_READ, {
    onCompleted: ({ patientReportReadView }) => {
      getPatientBadges();
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("POST_PATIENTS_REPORT_REQ_READ error " + e);
      }
      if (e.message) {
        openSnackbar(e.message, [SNACK_DURATION]);
      } else {
        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      }
    },
  });

  useEffect(() => {
    if (
      patient_reports_data?.edges?.length > 0 &&
      patientBadgesObject?.reports
    ) {
      let reports = [];
      reports = patient_reports_data?.edges.map((report) => {
        return report.node.id;
      });
      postReportReqReadView({ variables: { reports: reports } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient_reports_data]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    filterList(option);
  };

  return (
    <Base
      title={t("patientrequestReport.clinicalReportRequest")}
      rightChild={!isStaff ? getButtons() : null}
      isPatientPortal={!isStaff}
      showHeader={true}
    >
      <Row>
        <Col sm={12} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h6 className="mt-4 textGrey">
              <b> {t("patientrequestReport.requestedClinicalReports")} </b>{" "}
            </h6>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  {selectedOption
                    ? statusChoices?.find((item) => item.id === selectedOption)
                        ?.label
                    : "Status filter"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {statusChoices.map((option, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleOptionSelect(option.id)}
                    >
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basi-2">
                  {hide ? "Hidden reports" : "Active reports"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item key={"hide"} onClick={() => setHide(true)}>
                    Hidden reports
                  </Dropdown.Item>
                  <Dropdown.Item key={"active"} onClick={() => setHide(false)}>
                    Active reports
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {patient_reports_data &&
          patient_reports_data?.edges &&
          patient_reports_data?.edges?.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  {isStaff ? <th>Patient</th> : null}
                  <th>{t("patientrequestReport.submitted")}</th>
                  <th>{t("patientrequestReport.reportType")}</th>
                  <th>{t("patientrequestReport.reportDescription")}</th>
                  <th>{t("patientrequestReport.language")}</th>

                  <th>{t("patientrequestReport.doctor")}</th>
                  <th>Status</th>
                  <th>Payment Link</th>

                  <th>{t("patientrequestReport.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {[...patient_reports_data?.edges]
                  .sort(
                    (a, b) =>
                      new Date(b?.node?.created) - new Date(a?.node?.created)
                  )
                  .map((reportReq, index) => {
                    const paymentLink =
                      reportReq?.node?.payments?.edges[0]?.node?.sources
                        ?.edges[0]?.node?.paymentLink;
                    return (
                      <tr key={index}>
                        {isStaff ? (
                          <td>
                            {" "}
                            <Link
                              className="patient-name"
                              to={
                                (isStaff
                                  ? "/patient/report/detail/"
                                  : "/patientPortal/report/detail/") +
                                reportReq?.node?.id
                              }
                            >
                              {" "}
                              {reportReq?.node?.patient?.firstName}{" "}
                              {displayMiddleName(
                                reportReq?.node?.patient?.middleName
                              )}
                              {reportReq?.node?.patient?.lastName}{" "}
                            </Link>{" "}
                          </td>
                        ) : null}
                        <td className="align-middle patient-capitalize-first-letter">
                          {getDate(new Date(reportReq?.node?.created))}
                        </td>
                        <td> {reportReq?.node?.reportType?.name}</td>
                        <td> {reportReq?.node?.reportType?.description} </td>
                        <td> {reportReq?.node?.languageEn ? "EN" : "AR"}</td>

                        <td>
                          {" "}
                          {reportReq?.node?.doctor?.firstName}{" "}
                          {reportReq?.node?.doctor?.lastName}{" "}
                        </td>
                        <td> {reportReq.node.status} </td>
                        <td>
                          {!reportReq?.node?.paid && (
                            <Col
                              xs={12}
                              className="d-flex align-items-center no-print"
                            >
                              <Button variant="link">
                                <a
                                  href={paymentLink}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="payment-link-icon pl-2"
                                >
                                  <FontAwesomeIcon icon={faLink} />
                                </a>
                              </Button>
                              <CopyToClipBoardButton
                                textValue={paymentLink}
                                variant="link"
                              />
                              <Button
                                variant="link"
                                onClick={() => openModal(reportReq)}
                              >
                                <FontAwesomeIcon icon={faMoneyBill} />
                              </Button>
                            </Col>
                          )}
                        </td>
                        <td>
                          {isStaff && (
                            <div className="block_item_edit d-flex">
                              {(isStaff &&
                                patient_report_permission &&
                                patient_report_permission.includes("edit")) ||
                              !isStaff ? (
                                <Link
                                  to={
                                    (isStaff
                                      ? "/patient/report/request/update/" +
                                        reportReq?.node?.id +
                                        "?doctorFill=true"
                                      : "/patientPortal/report/request/update/") +
                                    reportReq?.node?.id
                                  }
                                >
                                  {" "}
                                  <Button variant="link" className="px-0 ">
                                    <i className="fa fa-edit"></i>
                                  </Button>
                                </Link>
                              ) : null}
                              {(isStaff &&
                                patient_report_permission &&
                                patient_report_permission.includes("delete") &&
                                reportReq?.node?.hide === false) ||
                              !isStaff ? (
                                <Button
                                  variant="link"
                                  className="danger-color"
                                  onClick={() => deleteReportFunc(reportReq)}
                                >
                                  <i className="fa fa-times"></i>
                                </Button>
                              ) : null}
                            </div>
                          )}

                          {reportReq?.node?.status === "COMPLETED" && (
                            <Link
                              to={
                                (isStaff
                                  ? "/patient/report/detail/"
                                  : "/patientPortal/report/detail/") +
                                reportReq?.node?.id
                              }
                            >
                              {" "}
                              {t("patientRecordFiles.view")}{" "}
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            t("patientrequestReport.noClinicalReportsRequested")
          )}
        </Col>
      </Row>
      <ReportManualPayment
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        activeReport={activeReport}
        getPatientReports={getPatientReports}
      />
    </Base>
  );
}

export default withRouter(RequestReport);
