import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CreateEventFormHoc from "../component/AppointmentDetails/CreateEventForm/CreateEventFormHoc";
import CalendarHoc from "../component/AppointmentDetails/Calendar/CalendarHoc";
import Base from "./base.js";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  isUserStaff,
  concatAllErrors,
} from "../Common/helpers";
import { useSnackbar } from "react-simple-snackbar";
import {
  eventsDataVar,
  calendarActiveMonthVar,
  displayLoaderVar,
} from "../cache/cache.js";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

export default function AppointmentCreate() {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [doctorLeavesList, setDoctorLeavesList] = useState(null);
  const [isAppointmentSubmitting, setIsAppointmentSubmitting] = useState(false);
  const [reqPrevNextData, setReqPrevNextData] = useState(null);
  const fullCalendarRef = useRef();
  let refCalendarView =
    fullCalendarRef && fullCalendarRef.current
      ? fullCalendarRef?.current?.getApi()?.view
      : null;
  const [activeDocShiftArr, setActiveDocShiftArr] = useState([]);
  const [openSnackbar] = useSnackbar(error_options);
  const isStaff = isUserStaff();
  const { t } = useTranslation();
  const [variablesToBeFetched, setVariablesToBeFetched] = useState(null);
  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;
  const { data } = useQuery(USER_DETAILS);
  var userDetails = data.userDetails;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  }

  const REQUEST_EVENTS = gql`
    query (
      $doctor_identifier: String
      $start: Date!
      $end: Date!
      $excludeId: String
      $cacheName: String
    ) {
      events(
        doctor_Identifier: $doctor_identifier
        start: $start
        end: $end
        active: true
        excludeId: $excludeId
        cacheName: $cacheName
      ) {
        edges {
          node {
            title
            id
            eventId
            status
            start
            end
            holdWaitingReplacement
            onlineLink
            slotsAvailable
            groupStartDate
            groupEndDate
            groupDays
            multipleScheduleSlots {
              edges {
                node {
                  slotsAvailable
                  start
                }
              }
            }
            mainSlot
            parent {
              id
            }
            doctor {
              id
              firstName
              identifier
              lastName
            }
            doctorEventType {
              duration
              buffer
              groupSession
              id
              color {
                hexCode
                name
                colorId
              }
            }
            patient {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const REQUEST_PLAIN_EVENTS = gql`
    query (
      $doctor_identifier: String
      $start: Date!
      $end: Date!
      $excludeId: String
      $cacheName: String
    ) {
      plainEvents(
        doctor_Identifier: $doctor_identifier
        start: $start
        end: $end
        active: true
        excludeId: $excludeId
        cacheName: $cacheName
      ) {
        edges {
          node {
            start
            end
            id
            eventId
            status
            doctorEventType {
              duration
              buffer
              id
              groupSession
              color {
                hexCode
                name
                colorId
              }
            }
            groupStartDate
            groupEndDate
            groupDays
            mainSlot
            doctor {
              id
              firstName
              identifier
              lastName
            }
          }
        }
      }
    }
  `;

  const REQUEST_SETTINGS = gql`
    query {
      settings
    }
  `;

  var EVENT_REQUEST_QUERY = isStaff ? REQUEST_EVENTS : REQUEST_PLAIN_EVENTS;

  // GET leave detail
  const { data: settings_obj = null } = useQuery(REQUEST_SETTINGS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("REQUEST_SETTINGS error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  useEffect(() => {
    displayLoaderVar(true);
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    return function cleanup() {
      calendarActiveMonthVar(null);
    };
  }, []);

  const shouldPassCache =
    refCalendarView?.type !== "resourceTimeGridDay" &&
    refCalendarView?.type !== "timeGridDay";
  const cacheNameVarToPass = {
    cacheName: isStaff ? "staffbooking" : "patientbooking",
  };

  const [
    GetEvents,
    { error, data: eventDisplay = null, variables, startPolling, stopPolling },
  ] = useLazyQuery(EVENT_REQUEST_QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 16000,
    variables: shouldPassCache ? cacheNameVarToPass : {},
    onCompleted: (data) => {
      var events = [];
      if (isStaff && data && data.events && data.events.edges) {
        events = data.events.edges.map((evt) => {
          return evt.node;
        });
      } else if (
        !isStaff &&
        data &&
        data.plainEvents &&
        data.plainEvents.edges
      ) {
        events = data.plainEvents.edges.map((evt) => {
          return evt.node;
        });
      }
      var eventsToSave = [];
      if (events.length > 0) {
        eventsToSave = events.map((evt) => {
          let val = JSON.parse(JSON.stringify(evt));
          let color = evt?.doctorEventType?.color?.hexCode;
          val["backgroundColor"] = color;
          val["borderColor"] = color;
          return val;
        });
      }
      eventsDataVar(eventsToSave);
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("EVENT_REQUEST_QUERY error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const onFocus = () => {
    if (selectedDoctor && startPolling) {
      startPolling(16000);
    }
  };
  const onBlur = () => {
    if (stopPolling) {
      stopPolling();
    }
  };

  return (
    <Base
      title={t("patientAppointmentCreate.appointmentDetails")}
      showHeader={true}
      isPatientPortal={!isStaff}
    >
      <Row>
        <Col xs={12} sm={12} md={4} className="mb-4">
          <CreateEventFormHoc
            setSelectedDoctor={setSelectedDoctor}
            selectedDoctor={selectedDoctor}
            setDoctorLeavesList={setDoctorLeavesList}
            doctorLeavesList={doctorLeavesList}
            fullCalendarRef={fullCalendarRef}
            setSelectedSession={setSelectedSession}
            selectedSession={selectedSession}
            GetEvents={GetEvents}
            setIsAppointmentSubmitting={setIsAppointmentSubmitting}
            isAppointmentSubmitting={isAppointmentSubmitting}
            settings_obj={settings_obj}
            setActiveDocShiftArr={setActiveDocShiftArr}
            activeDocShiftArr={activeDocShiftArr}
            setReqPrevNextData={setReqPrevNextData}
            variablesToBeFetched={variablesToBeFetched}
            setVariablesToBeFetched={setVariablesToBeFetched}
          />
        </Col>
        <Col xs={12} sm={12} md={8} className="mb-2">
          <CalendarHoc
            selectedDoctor={selectedDoctor}
            doctorLeavesList={doctorLeavesList}
            fullCalendarRef={fullCalendarRef}
            selectedSession={selectedSession}
            GetEvents={GetEvents}
            eventDisplay={eventDisplay}
            error={error}
            appointmentCreate={isStaff}
            myAppointments={isStaff}
            settings_obj={settings_obj}
            appFetchVariables={variables}
            setActiveDocShiftArr={setActiveDocShiftArr}
            activeDocShiftArr={activeDocShiftArr}
            stopPolling={stopPolling}
            startPolling={startPolling}
            userDetails={userDetails}
            variablesToBeFetched={variablesToBeFetched}
            setVariablesToBeFetched={setVariablesToBeFetched}
            reqPrevNextData={reqPrevNextData}
          />
        </Col>
      </Row>
    </Base>
  );
}
