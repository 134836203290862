import React, { useEffect } from "react";

import LoginPage from "./component/LoginPage/LoginPage";
import VerifyAccount from "./Views/verifyAccount";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import AppointmentCreate from "./Views/appointmentCreate";
import Dashboard from "./Views/dashboard";
import PatientPortal from "./Views/PatientPortal/patientPortalMain";
import Patients from "./Views/patients";
import NotFoundError from "./Views/notFoundError";
import MyAppointmentsStaff from "./Views/myAppointmentStaff/my_appointments.js";

import MyAppointmentsPatients from "./Views/myAppointmentPatient/my_appointments.js";
import WaitingList from "./Views/waiting_list";
import Payments from "./Views/payments";
import UnpaidManualPayments from "./Views/unpaid_manual_payments";
import Settings from "./Views/settings";
import HrPage from "./Views/HrPage";
import CreateNewPatient from "./Views/CreateNewPatient/CreateNewPatientHoc";
import ManualPayment from "./Views/manual_payment";
import ChangePassword from "./Views/change_password";
import PatientRecord from "./Views/patient_record";
import PatientAppointments from "./Views/patient_appointments";
import PatientReferrals from "./Views/patient_referrals";
import AppointmentDetail from "./Views/appointment_detail";
import SearchForPatient from "./Views/SearchForPatient/searchForPatientPage";
import EncounterDetails from "./Views/encounterDetail";
import ManageEncounter from "./Views/manageEncounter";
import LabAndRequests from "./Views/labAndRequests";
import ManageIntakeForms from "./Views/manageIntakeForms";
import DisplayIntakeForms from "./Views/DisplayIntakeForms";
import DisplaySurveyForms from "./Views/DisplaySurveyForms";
import ManageCustomerSurveys from "./Views/manageCustomerSurveys";
import ManageWaitingList from "./Views/manageWaitingList";
import AppointmentSettings from "./Views/appointmentSettings";
import LinkGoogleAccount from "./Views/linkGoogleAccount";
import UserManagement from "./Views/userManagement";
import PasswordSettings from "./Views/passwordSettings";
import RegistrationCompleteForm from "./Views/registerationCompleteForm";
import IntakeForm from "./Views/intakeForm";
import LabRequests from "./Views/labRequests";
import LabRequestDetail from "./Views/labRequestDetail";
import LeaveDetail from "./Views/leaveDetail";
import RefundRequestDetail from "./Views/refundRequestDetail";
import PractitionerReport from "./Views/practitionerReport";
import UserLeaves from "./Views/userLeaves";
import ManageLeaveRequests from "./Views/manageLeaveRequests";
import ManageRecurringEvents from "./Views/manageRecurringEvents";
import ManageRefundRequests from "./Views/manageRefundRequests";
import { isUserStaff } from "./Common/helpers.js";
import SnackbarProvider from "react-simple-snackbar";
import VerifyToken from "./verifyToken";
import { useReactiveVar } from "@apollo/client";
import { appLanguageVar } from "./cache/cache";
import SurveyFormsList from "./Views/SurveyFormsList";
import RequestReport from "./Views/requestReport";
import RequestReportDetail from "./Views/requestReportDetail";
import RequestReportCreate from "./Views/requestReportCreate";
import PatientRecordFiles from "./Views/patientRecordFiles";
import PaymentDetailPrintPage from "./Views/PaymentDetailPrintPage";
import UnpaidManualPaymentDetailPrintPage from "./Views/unpaidManualPaymentDetailPrintPage";
import BlocksForApprovementList from "./Views/BlocksForApprovementList";
import ApprovedBlocksList from "./Views/ApprovedBlocksList";
import PatientFollowUps from "./Views/PatientFollowUps";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

function App({ ...props }) {
  const token = localStorage.getItem("token");

  let location = useLocation();

  const appLanguage = useReactiveVar(appLanguageVar);

  useEffect(() => {
    if (appLanguage === "ar") {
      document.body.lang = "ar";
      document.body.dir = "rtl";
    } else {
      document.body.lang = "en";
      document.body.dir = "ltr";
    }
  }, [appLanguage]);

  const StaffOnlyRoute = ({ component: Component, ...rest }) => {
    const is_user_staff = isUserStaff();
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("token") && is_user_staff ? (
            <>
              <Component {...props} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  const PatientOnlyRoute = ({ component: Component, ...rest }) => {
    const is_user_staff = isUserStaff();
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("token") && !is_user_staff ? (
            <>
              <Component {...props} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  const PrivateOnlyRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("token") ? (
            <>
              <Component {...props} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }
      />
    );
  };

  const PublicOnlyRoute = ({ component: Component, ...rest }) => {
    const is_user_staff = isUserStaff();
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("token") ? (
            <Redirect
              to={{
                pathname: is_user_staff
                  ? "/dashboard"
                  : "/patientPortal/dashboard",
              }}
            />
          ) : (
            <>
              <Component {...props} />
            </>
          )
        }
      />
    );
  };

  return (
    <div className={"h100vh"}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          {token ? <VerifyToken /> : null}
          <Switch>
            {/* Routes available for Staff users only */}
            <StaffOnlyRoute
              exact
              path="/patient/record/:id"
              component={PatientRecord}
            />
            <StaffOnlyRoute
              exact
              path="/patient/record/files/:id"
              component={PatientRecordFiles}
            />
            <StaffOnlyRoute
              exact
              path="/patient/appointments/:identifier"
              component={PatientAppointments}
            />
            <StaffOnlyRoute
              exact
              path="/create/appointment"
              component={AppointmentCreate}
            />
            <StaffOnlyRoute
              exact
              path="/appointment/detail/:appointmentId"
              component={AppointmentDetail}
            />
            <StaffOnlyRoute
              exact
              path="/encounter/detail/:encounterId"
              component={EncounterDetails}
            />
            <StaffOnlyRoute exact path="/dashboard" component={Dashboard} />
            <StaffOnlyRoute exact path="/patients" component={Patients} />
            <StaffOnlyRoute
              exact
              path="/patients/referrals/:identifier"
              component={PatientReferrals}
            />
            <StaffOnlyRoute
              exact
              path="/appointments"
              component={MyAppointmentsStaff}
            />
            <StaffOnlyRoute
              exact
              path="/manual/payment"
              component={ManualPayment}
            />
            <StaffOnlyRoute
              exact
              path="/search/patient"
              component={SearchForPatient}
            />
            <StaffOnlyRoute
              exact
              path="/create/patient"
              component={CreateNewPatient}
            />
            <StaffOnlyRoute
              exact
              path="/edit/patient/:identifier"
              component={CreateNewPatient}
            />
            <StaffOnlyRoute
              exact
              path="/waiting/list"
              component={WaitingList}
            />
            <StaffOnlyRoute exact path="/settings" component={Settings} />
            <StaffOnlyRoute
              exact
              path="/manage/encounter"
              component={ManageEncounter}
            />
            <StaffOnlyRoute
              exact
              path="/manage/intake/forms"
              component={ManageIntakeForms}
            />
            <StaffOnlyRoute
              exact
              path="/manage/intake/forms/:identifier"
              component={DisplayIntakeForms}
            />
            <StaffOnlyRoute
              exact
              path="/manage/customer/surveys"
              component={ManageCustomerSurveys}
            />
            <StaffOnlyRoute
              exact
              path="/manage/surveys/forms/:identifier"
              component={DisplaySurveyForms}
            />
            <StaffOnlyRoute
              exact
              path="/manage/waiting/list"
              component={ManageWaitingList}
            />
            <StaffOnlyRoute
              exact
              path="/appointment/settings"
              component={AppointmentSettings}
            />
            <StaffOnlyRoute
              exact
              path="/link/google/account"
              component={LinkGoogleAccount}
            />
            <StaffOnlyRoute
              exact
              path="/user/management"
              component={UserManagement}
            />
            <StaffOnlyRoute
              exact
              path="/password/settings"
              component={PasswordSettings}
            />
            <StaffOnlyRoute
              exact
              path="/labs/requests"
              component={LabAndRequests}
            />
            <StaffOnlyRoute exact path="/accounts" component={Payments} />
            <StaffOnlyRoute
              exact
              path="/unpaid/manual/payments"
              component={UnpaidManualPayments}
            />
            <StaffOnlyRoute
              exact
              path="/accounts/patient/:identifier"
              component={Payments}
            />
            <StaffOnlyRoute
              exact
              path="/accounts/payment/detail/:id"
              component={PaymentDetailPrintPage}
            />
            <StaffOnlyRoute
              exact
              path="/unpaid/manual/payments/detail/:id"
              component={UnpaidManualPaymentDetailPrintPage}
            />
            <StaffOnlyRoute exact path="/hr" component={HrPage} />
            <StaffOnlyRoute
              exact
              path="/manage/leave/requests"
              component={ManageLeaveRequests}
            />
            <StaffOnlyRoute
              exact
              path="/manage/recurring/events"
              component={ManageRecurringEvents}
            />
            <StaffOnlyRoute
              exact
              path="/manage/refund/requests"
              component={ManageRefundRequests}
            />
            <StaffOnlyRoute
              exact
              path="/patient/intakeform/:intake_id"
              component={IntakeForm}
            />
            <StaffOnlyRoute
              exact
              path="/patient/surveyform/:survey_id"
              component={IntakeForm}
            />
            <StaffOnlyRoute
              exact
              path="/patient/labrequests/:identifier"
              component={LabRequests}
            />
            <StaffOnlyRoute
              exact
              path="/patient/labrequests/detail/:id"
              component={LabRequestDetail}
            />
            <StaffOnlyRoute
              exact
              path="/patient/report/request"
              component={RequestReport}
            />
            <StaffOnlyRoute
              exact
              path="/patient/report/detail/:id"
              component={RequestReportDetail}
            />
            <StaffOnlyRoute
              exact
              path="/patient/report/request/update/:id"
              component={RequestReportCreate}
            />
            <StaffOnlyRoute
              exact
              path="/patient/report/request/create/:patientId?"
              component={RequestReportCreate}
            />
            <StaffOnlyRoute
              exact
              path="/leave/request/:id"
              component={LeaveDetail}
            />
            <StaffOnlyRoute
              exact
              path="/refund/request/:id"
              component={RefundRequestDetail}
            />
            <StaffOnlyRoute
              exact
              path="/practitioner/report"
              component={PractitionerReport}
            />
            <StaffOnlyRoute exact path="/leaves" component={UserLeaves} />
            <StaffOnlyRoute
              exact
              path="/patient/blocksforapprovemenet"
              component={BlocksForApprovementList}
            />
            <StaffOnlyRoute
              exact
              path="/patient/approvedblocks"
              component={ApprovedBlocksList}
            />

            <Route exact path="/404-not-found" component={NotFoundError} />

            {/* Routes available for Patient users only */}
            <PatientOnlyRoute
              exact
              path="/patientPortal/labrequests/:identifier"
              component={LabRequests}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/labrequests/detail/:id"
              component={LabRequestDetail}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/files/:id"
              component={PatientRecordFiles}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/referrals/:identifier"
              component={PatientReferrals}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/followups/:identifier"
              component={PatientFollowUps}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/report/request"
              component={RequestReport}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/report/request/create/:patientId?"
              component={RequestReportCreate}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/report/request/update/:id"
              component={RequestReportCreate}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/report/detail/:id"
              component={RequestReportDetail}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/intakeform/:intake_id"
              component={IntakeForm}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/surveyform/:survey_id"
              component={IntakeForm}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/surveyformlist"
              component={SurveyFormsList}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/dashboard"
              component={PatientPortal}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/appointments"
              component={MyAppointmentsPatients}
            />
            <PatientOnlyRoute
              exact
              path="/registration/complete/form"
              component={RegistrationCompleteForm}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/appointment/detail/:appointmentId"
              component={AppointmentDetail}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/create/appointment"
              component={AppointmentCreate}
            />
            <PatientOnlyRoute
              exact
              path="/patientPortal/appointments/:identifier"
              component={PatientAppointments}
            />

            {/* Private Routes available for Public users only */}
            <PrivateOnlyRoute
              path="/change/password"
              component={ChangePassword}
            />
            {/* <PrivateOnlyRoute path='/404-not-found' component={NotFoundError} /> */}

            {/* Public Routes available for Public users only */}
            <PublicOnlyRoute path="/activate/" component={VerifyAccount} />
            <PublicOnlyRoute exact path="/" component={LoginPage} />
            <PublicOnlyRoute path="/password-reset/" component={LoginPage} />
            <PublicOnlyRoute path="/forgot/password/" component={LoginPage} />
            <PublicOnlyRoute path="/register" component={LoginPage} />
            <PublicOnlyRoute path="/otp-token" component={LoginPage} />

            <Route path="*" component={NotFoundError} />
          </Switch>
        </SnackbarProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
