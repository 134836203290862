import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import LogoutButton from "./LogoutButton/LogoutButton";
import { withRouter, Link } from "react-router-dom";
import Logo from "../../assets/images/Mindwell-Logo_white.svg";
import { gql, useQuery, useLazyQuery, useReactiveVar } from "@apollo/client";
import {
  dataPatientInVar,
  appLanguageVar,
  myAppointmentDetailsVar,
  patientDashboardBadgeObjectVar,
  patientBadgesVar,
  onBoardingToFillVar,
  userDetailsVar,
} from "../../cache/cache.js";
import { useTranslation } from "react-i18next";
import {
  isUserStaff,
  getPermissionForAction,
  error_options,
  SNACK_DURATION,
  ERROR_MESSAGE,
  concatAllErrors,
  dateWithDay,
  isSuperUserFunc,
  getPrescriptionDate,
} from "../../Common/helpers";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { useSnackbar } from "react-simple-snackbar";
import Badge from "../../Views/Badge";
import { useQuery as useReactQuery } from "react-query";
import * as Sentry from "@sentry/browser";

const CACHE_TIME_INTERVAL = 5 * 60 * 1000;

const Navigation = (props) => {
  const { location } = props;
  const { pathname } = location;
  const { i18n, t } = useTranslation();
  const appLanguage = useReactiveVar(appLanguageVar);
  const myAppointmentDetails = useReactiveVar(myAppointmentDetailsVar);
  const onBoardingToFill = useReactiveVar(onBoardingToFillVar);
  const patientBadgesObject = useReactiveVar(patientBadgesVar);
  const patientDashboardBadgeObject = useReactiveVar(
    patientDashboardBadgeObjectVar
  );
  const isStaff = isUserStaff();
  var startDate = new Date();
  startDate =
    startDate.getFullYear() +
    "-" +
    String(startDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(startDate.getDate()).padStart(2, "0");

  const [dateStart] = useState(startDate);
  const [badgeForDashboard, setBadgeForDashboard] = useState(0);
  const [badgeForAppointments, setBadgeForAppointments] = useState(0);
  const [badgeForReportRequests, setBadgeForReportRequests] = useState(0);
  const [patientsBadgeForReportRequests, setPatientsBadgeForReportRequests] =
    useState(0);
  const [patientsBadgeForLabRequests, setPatientsBadgeForLabRequests] =
    useState(0);
  const [badgeForReferrals, setBadgeForReferrals] = useState(0);
  const [badgeForPresc, setBadgeForPresc] = useState(0);
  const [badgeForLeaveRequests, setBadgeForLeaveRequests] = useState(0);
  const [openSnackbar] = useSnackbar(error_options);
  var IS_SUPERUSER = isSuperUserFunc();
  const [badgeForStaffDashboard, setBadgeForStaffDashboard] = useState(0);
  const [badgeForStaffApprovals, setBadgeForStaffApprovals] = useState(0);
  const [badgeForStaffApproved, setBadgeForStaffApproved] = useState(0);
  const [
    badgeForPatientReportsForApproval,
    setBadgeForPatientReportsForApproval,
  ] = useState(0);
  const [badgeForRefundRequests, setBadgeForRefundRequests] = useState();
  const [badgeForPendingApproval, setBadgeForPendingApproval] = useState(0);

  var userDetails = useReactiveVar(userDetailsVar);
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const USER_DETAILS = gql`
    query receiveDate {
      userDetails @client
    }
  `;
  const REQUEST_MEDICAL_REPORTS = gql`
    query {
      patientReportsForApproval {
        totalCount
      }
    }
  `;
  const REQUEST_ACCOUNT_DETAILS = gql`
    query {
      me {
        id
        isStaff
        patient {
          firstName
          middleName
          lastName
          id
          idFile
          identifier
          dob
          phone
          email
          civilId
          address
          displayGender
          prescriptionData {
            dynamicFormData
          }
          intakeForms {
            edges {
              node {
                dynamicForm {
                  patientOnboarding
                }
                id
                submitted
              }
            }
          }
          labRequests {
            totalCount
          }
          referrals {
            edges {
              node {
                title
                description
                id
                recommendedDate
                numberLeft
                referredDoctor {
                  firstName
                  lastName
                  id
                  identifier
                  eventType {
                    edges {
                      node {
                        id
                        title {
                          name
                        }
                        duration
                        buffer
                        price
                        eventType {
                          id
                          name
                          color {
                            hexCode
                            name
                            colorId
                          }
                        }
                        eventLocation {
                          id
                          title
                        }
                      }
                    }
                  }
                  recurringEvents {
                    edges {
                      node {
                        startDate
                        startTime
                        endTime
                        title
                        description
                        everyday
                        days
                        dateUntil
                      }
                    }
                  }
                  availability {
                    edges {
                      node {
                        id
                        day
                        shifts {
                          edges {
                            node {
                              startTime
                              endTime
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
                dynamicForms {
                  edges {
                    node {
                      name
                      id
                      formType
                    }
                  }
                }
                referredDoctorSession {
                  id
                  title {
                    name
                  }
                  groupSession
                  duration
                  price
                  eventLocation {
                    title
                  }
                  eventType {
                    name
                  }
                }
                createdAppointment {
                  start
                }
              }
            }
          }
          country {
            code
            name
          }
          governorate {
            id
            name
          }
          area {
            id
            name
          }
          profileComplete
          discount
        }
      }
    }
  `;

  const REQUEST_ALL_EVENTS_FOR_BADGE = gql`
    query ($start: Date) {
      events(
        start: $start
        orderby: "start"
        first: 1
        status: "confirmed"
        active: true
      ) {
        totalCount
        edges {
          node {
            id
            start
            end
            patient {
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const REQUEST_ALL_PATIENT_EVENTS_FOR_BADGE = gql`
    query ($start: Date) {
      patientAppointments(
        start: $start
        orderby: "start"
        first: 1
        status: "confirmed"
        active: true
      ) {
        totalCount
        edges {
          node {
            id
            start
            end
            patient {
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const REQUEST_PATIENT_REPORTS = gql`
    query {
      patientReports {
        totalCount
        edges {
          node {
            status
          }
        }
      }
    }
  `;
  const REQUEST_PATIENT_REPORTS_FOR_BADGE = gql`
    query {
      doctorPatientReports(status: "paid", hide: false) {
        totalCount
      }
    }
  `;

  const REQUEST_FORM_BLOCK_APPROVALS_FOR_BADGE = gql`
    query {
      formBlockApprovals {
        totalCount
      }
    }
  `;

  const REQUEST_APPROVED_BLOCKS = gql`
    query {
      approvedBlockNotifications(first: 1) {
        totalCount
        edges {
          node {
            id
            created
            encounter {
              id
              doctor {
                firstName
                lastName
              }
            }
            patient {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      }
    }
  `;

  const LEAVE_REQUESTS_FOR_BADGE = gql`
    query {
      leaveRequestsForApproval(status: "pending") {
        totalCount
      }
    }
  `;

  const REFUND_REQUESTS = gql`
    query {
      refundRequestWithApproval(first: 3) {
        totalCount
      }
    }
  `;

  const REQUEST_USER_PERMISSION = gql`
    query receiveDate {
      userPermissions @client
    }
  `;

  const REQUEST_FORM_BLOCK_APPROVALS = gql`
    query {
      formBlockApprovals {
        totalCount
        edges {
          node {
            created
            modified
            patient {
              firstName
              middleName
              lastName
              identifier
              id
            }
            encounter {
              id
              date
              doctor {
                firstName
                lastName
              }
            }
            formBlock {
              name
              created
            }
          }
        }
      }
    }
  `;

  const [getRefundRequests] = useLazyQuery(REFUND_REQUESTS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getRefundRequests error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });
  const [getPendingApprovals] = useLazyQuery(REQUEST_FORM_BLOCK_APPROVALS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getPendingApprovals error " + e);
      }
      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getMedicalReports] = useLazyQuery(REQUEST_MEDICAL_REPORTS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getMedicalReports error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getRequestApprovedBlock] = useLazyQuery(REQUEST_APPROVED_BLOCKS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getRequestApprovedBlock error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getFormBlockApprovalsObj] = useLazyQuery(
    REQUEST_FORM_BLOCK_APPROVALS_FOR_BADGE,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("getFormBlockApprovalsObj error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getAccountDetails] = useLazyQuery(REQUEST_ACCOUNT_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.me?.patient) {
        setBadgeForReferrals(data?.me?.patient?.referrals?.edges?.length);
      }
      if (
        data &&
        data.me &&
        data.me.patient &&
        !data.me.isStaff &&
        !data.me.patient.profileComplete
      ) {
        if (pathname !== "/registration/complete/form") {
          props.history.push("/registration/complete/form");
        }
      }
      const ONBOARDING_FORM_TO_FILL =
        data?.me?.patient?.profileComplete && data?.me?.patient?.intakeForms
          ? data?.me?.patient?.intakeForms.edges.find(
              (i) => i.node.dynamicForm.patientOnboarding && !i.node.submitted
            )
          : null;
      if (ONBOARDING_FORM_TO_FILL) {
        onBoardingToFillVar(true);
        // if (pathname!==("/patientPortal/intakeform/"+ONBOARDING_FORM_TO_FILL.node.id) && !isStaff) {
        //   props.history.push("/patientPortal/intakeform/"+ONBOARDING_FORM_TO_FILL.node.id);
        // }
      }
    },
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getAccountDetails error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  let BADGE_EVENTS_ENDPOINT = isStaff
    ? REQUEST_ALL_EVENTS_FOR_BADGE
    : REQUEST_ALL_PATIENT_EVENTS_FOR_BADGE;
  const [getAllEventsForBadge] = useLazyQuery(
    REQUEST_ALL_PATIENT_EVENTS_FOR_BADGE,
    {
      variables: { start: dateStart },
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("getAllEventsForBadge error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getPatientReportsObj] = useLazyQuery(REQUEST_PATIENT_REPORTS, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getPatientReportsObj error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getPatientReportsObjForBadge] = useLazyQuery(
    REQUEST_PATIENT_REPORTS_FOR_BADGE,
    {
      fetchPolicy: "network-only",
      onError: (e) => {
        Sentry.setContext("error", e?.networkError?.result);
        Sentry.setContext("ERROR OBJ ", { errorObj: e });
        Sentry.setContext("ERROR CODE statusCode ", {
          code: e?.networkError?.statusCode,
        });
        if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
          Sentry.captureException("getPatientReportsObjForBadge error " + e);
        }

        let errorMsg = concatAllErrors(e?.graphQLErrors);
        let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
        openSnackbar(msgToDisplay, [SNACK_DURATION]);
      },
    }
  );

  const [getLeaveRequestForBadge] = useLazyQuery(LEAVE_REQUESTS_FOR_BADGE, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getLeaveRequestForBadge error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const [getUserPermissions] = useLazyQuery(REQUEST_USER_PERMISSION, {
    fetchPolicy: "network-only",
    onError: (e) => {
      Sentry.setContext("error", e?.networkError?.result);
      Sentry.setContext("ERROR OBJ ", { errorObj: e });
      Sentry.setContext("ERROR CODE statusCode ", {
        code: e?.networkError?.statusCode,
      });
      if (e?.message?.toLocaleLowerCase()?.indexOf("permission") < 0) {
        Sentry.captureException("getUserPermissions error " + e);
      }

      let errorMsg = concatAllErrors(e?.graphQLErrors);
      let msgToDisplay = errorMsg ? errorMsg : ERROR_MESSAGE;
      openSnackbar(msgToDisplay, [SNACK_DURATION]);
    },
  });

  const fetchMedicalReports = async () => {
    const res = await getMedicalReports();
    return res.data;
  };

  const fetchRefundRequests = async () => {
    const res = await getRefundRequests();
    return res.data;
  };
  const fetchPendingApprovals = async () => {
    const res = await getPendingApprovals();
    return res.data;
  };

  const fetchRequestApprovedBlock = async () => {
    const res = await getRequestApprovedBlock();
    return res.data;
  };

  const fetchFormBlockApprovalsObj = async () => {
    const res = await getFormBlockApprovalsObj();
    return res.data;
  };

  const fetchAccountDetails = async () => {
    const res = await getAccountDetails();
    return res.data;
  };

  const fetchAllEventsForBadge = async () => {
    if (!isStaff) {
      const res = await getAllEventsForBadge();
      return res.data;
    }
  };

  const fetchPatientReportsObj = async () => {
    const res = await getPatientReportsObj();
    return res.data;
  };

  const fetchPatientReportsObjForBadge = async () => {
    const res = await getPatientReportsObjForBadge();
    return res.data;
  };

  const fetchLeaveRequestForBadge = async () => {
    const res = await getLeaveRequestForBadge();
    return res.data;
  };
  const fetchUserPermissions = async () => {
    const res = await getUserPermissions();
    return res.data;
  };

  const useReactQueryOptions = {
    cacheTime: CACHE_TIME_INTERVAL,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    retryOnMount: false,
    staleTime: CACHE_TIME_INTERVAL,
    enabled: !!isStaff,
  };

  const useReactQueryOptionsRefetch = {
    ...useReactQueryOptions,
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: 30 * 1000,
    refetchIntervalInBackground: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  };
  const { data: accountDetails } = useReactQuery(
    "REQUEST_ACCOUNT_DETAILS",
    fetchAccountDetails,
    { ...useReactQueryOptions, cacheTime: 0, enabled: true }
  );
  const { data: medical_reports_obj } = useReactQuery(
    "REQUEST_MEDICAL_REPORTS",
    fetchMedicalReports,
    useReactQueryOptions
  );
  const { data: refundRequests } = useReactQuery(
    "REFUND_REQUESTS",
    fetchRefundRequests,
    useReactQueryOptionsRefetch
  );
  const { data: pendingApprovals } = useReactQuery(
    "PENDING_APPROVALS",
    fetchPendingApprovals,
    useReactQueryOptionsRefetch
  );
  const { data: requestApprovedBlocks } = useReactQuery(
    "REQUEST_APPROVED_BLOCKS",
    fetchRequestApprovedBlock,
    useReactQueryOptions
  );
  const { data: formBlockApprovalsObj } = useReactQuery(
    "REQUEST_FORM_BLOCK_APPROVALS_FOR_BADGE",
    fetchFormBlockApprovalsObj,
    useReactQueryOptions
  );
  const { data: allEventsForBadge } = useReactQuery(
    "REQUEST_ALL_EVENTS_FOR_BADGE",
    fetchAllEventsForBadge,
    useReactQueryOptions
  );
  const { data: patient_reports_obj } = useReactQuery(
    "REQUEST_PATIENT_REPORTS",
    fetchPatientReportsObj,
    useReactQueryOptions
  );
  const { data: patient_reports_obj_for_badge } = useReactQuery(
    "REQUEST_PATIENT_REPORTS_FOR_BADGE",
    fetchPatientReportsObjForBadge,
    useReactQueryOptionsRefetch
  );
  const { data: leave_requests_for_badge } = useReactQuery(
    "LEAVE_REQUESTS_FOR_BADGE",
    fetchLeaveRequestForBadge,
    useReactQueryOptionsRefetch
  );
  const { data: user_permissions } = useReactQuery(
    "REQUEST_USER_PERMISSION",
    fetchUserPermissions,
    useReactQueryOptions
  );

  const DYNAMIC_PRESCRIPTION_DATA =
    accountDetails?.me?.patient?.prescriptionData?.dynamicFormData;

  var sortedPresc = [];
  for (var key in DYNAMIC_PRESCRIPTION_DATA) {
    sortedPresc.push({ prescription: DYNAMIC_PRESCRIPTION_DATA[key] });
  }
  sortedPresc = sortedPresc.sort(function (a, b) {
    let presc_date_a = getPrescriptionDate(a.prescription);
    let presc_date_b = getPrescriptionDate(b.prescription);
    return presc_date_b - presc_date_a;
  });

  const token = localStorage.getItem("user_permissions");

  useEffect(() => {
    if (
      (medical_reports_obj &&
        getPermissionForAction("patient_report", "approve")) ||
      IS_SUPERUSER
    ) {
      setBadgeForPatientReportsForApproval(
        medical_reports_obj?.patientReportsForApproval?.totalCount
      );
    }
  }, [IS_SUPERUSER, medical_reports_obj, token]);

  useEffect(() => {
    if (refundRequests) {
      setBadgeForRefundRequests(
        refundRequests?.refundRequestWithApproval?.totalCount
      );
    }
  }, [refundRequests]);

  useEffect(() => {
    if (pendingApprovals) {
      setBadgeForPendingApproval(
        pendingApprovals?.formBlockApprovals?.totalCount
      );
    }
  }, [pendingApprovals]);

  useEffect(() => {
    if (requestApprovedBlocks) {
      setBadgeForStaffApproved(
        requestApprovedBlocks?.approvedBlockNotifications?.totalCount
      );
    }
  }, [requestApprovedBlocks]);

  useEffect(() => {
    if (
      (formBlockApprovalsObj &&
        getPermissionForAction("patient_report", "approve")) ||
      IS_SUPERUSER
    ) {
      setBadgeForStaffApprovals(
        formBlockApprovalsObj?.formBlockApprovals?.totalCount
      ); // totalCount = 0
    }
  }, [IS_SUPERUSER, formBlockApprovalsObj, token]);

  useEffect(() => {
    if (allEventsForBadge && getPermissionForAction("appointment", "list")) {
      setBadgeForAppointments(allEventsForBadge?.events?.totalCount);
    }
  }, [allEventsForBadge, token]);

  useEffect(() => {
    if (patient_reports_obj) {
      // we show only !PENDING requests in requestReport component,
      // same is here to match numbers in badges
      const badgeResult = patient_reports_obj?.patientReports?.edges?.filter(
        (item) => {
          return item.node.status !== "PENDING";
        }
      );
      setPatientsBadgeForReportRequests(badgeResult?.length);
    }
  }, [patient_reports_obj]);

  useEffect(() => {
    if (
      patient_reports_obj_for_badge &&
      getPermissionForAction("patient_report", "add")
    ) {
      setBadgeForReportRequests(
        patient_reports_obj_for_badge?.doctorPatientReports?.totalCount
      );
    }
  }, [patient_reports_obj_for_badge, token]);

  useEffect(() => {
    if (
      leave_requests_for_badge &&
      getPermissionForAction("leave_request", "approve")
    ) {
      setBadgeForLeaveRequests(
        leave_requests_for_badge?.leaveRequestsForApproval?.totalCount
      );
    }
  }, [leave_requests_for_badge, token]);

  const { data } = useQuery(USER_DETAILS);
  var userDetails = data && data.userDetails ? data.userDetails : null;
  if (userDetails && userDetails.indexOf("username") > -1) {
    userDetails = JSON.parse(userDetails);
  } else {
    userDetails = accountDetails?.me?.patient;
  }

  if (
    accountDetails &&
    accountDetails.me &&
    accountDetails.me.patient &&
    !accountDetails.me.isStaff
  ) {
    dataPatientInVar(accountDetails.me.patient);
  }

  useEffect(() => {
    if (sortedPresc) {
      let dashboardBadge = document.getElementsByClassName(
        "count-badge-patient-dashboard"
      );
      if (dashboardBadge?.length) {
        patientDashboardBadgeObjectVar(dashboardBadge?.length);
      }
    }
  }, [
    badgeForAppointments,
    badgeForReferrals,
    badgeForPresc,
    sortedPresc,
    patientDashboardBadgeObject,
  ]);

  useEffect(() => {
    const total =
      badgeForReportRequests +
      badgeForLeaveRequests +
      badgeForAppointments +
      badgeForPendingApproval +
      badgeForPatientReportsForApproval +
      badgeForRefundRequests;
    setBadgeForStaffDashboard(total);
  }, [
    badgeForReportRequests,
    badgeForLeaveRequests,
    badgeForAppointments,
    badgeForPendingApproval,
    badgeForPatientReportsForApproval,
    patientsBadgeForReportRequests,
    badgeForRefundRequests,
  ]);

  let default_permissions = ["dashboard"];
  let permissions = [
    "patient",
    "appointment",
    "account",
    "manual_payment",
    "waiting_list",
    "change_pass",
    "patient_report",
    "recurring_event",
    "practitioner_report",
    "patient_vital",
    "accept_manual_refund",
    "unpaid_manual_payments",
  ];

  permissions = permissions.filter(
    (i) =>
      getPermissionForAction(
        i,
        "list",
        false,
        user_permissions?.userPermissions
      ) === true
  );
  permissions.push(...default_permissions);
  const has_HR_permission = getPermissionForAction("leave_request", "approve");
  const has_manualPayment_permission = getPermissionForAction(
    "manual_payment",
    "add"
  );
  const has_unpaid_manualPayment_permission = getPermissionForAction(
    "unpaid_manual_payments",
    "list"
  );
  const has_practitioner_report_list_permission = getPermissionForAction(
    "practitioner_transaction",
    "list"
  );
  const has_patient_vital_view_permission = getPermissionForAction(
    "patient_vital",
    "list"
  );
  const has_accept_manual_refund_permission = getPermissionForAction(
    "accept_manual_refund",
    "approve"
  );
  const has_block_calender_permission = getPermissionForAction(
    "can_block_calender",
    "add"
  );

  if (has_manualPayment_permission) {
    permissions.push("manual_payment");
  }
  if (has_unpaid_manualPayment_permission) {
    permissions.push("unpaid_manual_payments");
  }
  if (has_block_calender_permission) {
    permissions.push("can_block_calender");
  }

  if (has_practitioner_report_list_permission) {
    permissions.push("practitioner_report");
  }
  if (has_patient_vital_view_permission) {
    permissions.push("patient_vital");
  }
  if (has_accept_manual_refund_permission) {
    permissions.push("accept_manual_refund");
  }

  if (has_HR_permission) {
    permissions.push("leave_request");
  }

  if (getPermissionForAction("leave_request", "add")) {
    permissions.push("leave_request_view");
  }

  permissions.push("change_pass");

  let navigation_links = [
    {
      key: "dashboard",
      slug: "/dashboard",
      title: "Dashboard",
      badge: badgeForStaffDashboard,
    },
    {
      key: "patient",
      slug: "/patients",
      title: "Patients",
    },
    {
      key: "appointment",
      slug: "/appointments",
      title: "Appointments",
    },
    {
      key: "waiting_list",
      slug: "/waiting/list",
      title: "Waiting List",
    },
    {
      key: "leave_request",
      slug: "/manage/leave/requests",
      title: "Manage Leave Request",
    },
    {
      key: "leave_request_view",
      slug: "/leaves",
      title: "Your Leave Request",
    },
    {
      key: "patient_report",
      slug: "/patient/report/request",
      title: " Report Requests ",
    },
    {
      key: "account",
      slug: "/accounts",
      title: "Transactions",
    },
    {
      key: "practitioner_report",
      slug: "/practitioner/report",
      title: "Practitioner Report",
    },
    {
      key: "change_pass",
      slug: "/change/password",
      title: "Change Password",
    },
    {
      key: "settings",
      slug: "/settings",
      title: "Settings",
    },
  ];
  if (has_manualPayment_permission)
    navigation_links.splice(4, 0, {
      key: "manual_payment",
      slug: "/manual/payment",
      title: "Manual Payment",
    });
  if (has_unpaid_manualPayment_permission)
    navigation_links.splice(5, 0, {
      key: "unpaid_manual_payments",
      slug: "/unpaid/manual/payments",
      title: "Unpaid Manual Payments",
    });

  if (userDetails?.doctor?.id || getPermissionForAction("appointment", "add")) {
    navigation_links.push({
      key: "recurring_event",
      slug: "/manage/recurring/events",
      title: "Block Calendar",
    });
  }
  if (props.isPatientPortal) {
    permissions = [
      "patientDashboard",
      "patientAppointment",
      "change_pass",
      "report_request",
      "lab_request",
      "files",
      "patientsInfo",
    ];
    navigation_links = [
      {
        key: "patientDashboard",
        slug: "/patientPortal/dashboard",
        title: t("patientNavigation.dashboard"),
        badge: patientBadgesObject?.hasOwnProperty("dashboard")
          ? patientBadgesObject?.dashboard
          : patientDashboardBadgeObject,
      },
      {
        key: "patientAppointment",
        slug: "/patientPortal/appointments",
        title: t("patientNavigation.appointments"),
        // badge: badgeForAppointments
        badge: patientBadgesObject?.events,
      },
      {
        key: "report_request",
        slug: `/patientPortal/report/request?patientId=${accountDetails?.me?.patient?.id}`,
        title: t("patientNavigation.reportRequests"),
        // badge: patientsBadgeForReportRequests
        badge: patientBadgesObject?.reports,
      },
      {
        key: "change_pass",
        slug: "/change/password",
        title: t("patientNavigation.changePassword"),
      },
      {
        key: "patientsInfo",
        slug: "/registration/complete/form?profileEdit=true",
        title: t("patientNavigation.patientsInfo"),
      },
    ];

    navigation_links.push(
      {
        key: "lab_request",
        slug: accountDetails?.me?.patient?.identifier
          ? "/patientPortal/labrequests/" + accountDetails.me.patient.identifier
          : "/",
        title: t("reports.labRequests"),
        // badge: accountDetails?.me?.patient?.labRequests?.totalCount? accountDetails?.me?.patient?.labRequests?.totalCount:0
        badge: patientBadgesObject?.labs,
      },
      {
        key: "files",
        slug: accountDetails?.me?.patient?.id
          ? "/patientPortal/files/" + accountDetails.me.patient.id
          : "/",
        title: t("patientNavigation.reportFiles"),
      }
    );
  }

  const toggleNav = (open) => {
    if (open) {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("nav_overlay").classList.remove("d-none");
    } else {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("nav_overlay").classList.add("d-none");
    }
  };

  const renderNavContents = (displayType) => {
    return (
      <>
        <div
          className={
            " w100 px-2 " +
            (accountDetails &&
            accountDetails.me &&
            accountDetails.me.patient &&
            (!accountDetails.me.patient.profileComplete || onBoardingToFill)
              ? " pointerEventsNone "
              : "")
          }
        >
          <div className="d-md-none userdetails px-3 mb-4">
            <EllipsisWithTooltip placement="bottom">
              <h6 className="nav-email-overlay-mobile">
                {userDetails ? userDetails.email : null}
              </h6>
            </EllipsisWithTooltip>
            <Link
              to={
                props.isPatientPortal
                  ? "/patientPortal/dashboard"
                  : "/dashboard"
              }
            >
              <img alt={"Logo"} className="img-fluid navLogo" src={Logo} />
            </Link>
            {/* <h4 className="textPrimary capitalize">
              {userDetails ? userDetails.username : 'User'}
            </h4> */}
            {!isStaff ? (
              <div>
                {appLanguage === "ar" ? (
                  <h6
                    className="language lang-patient-change mt-3 pointer"
                    onClick={() => changeAppLangauge("en")}
                  >
                    {" "}
                    English{" "}
                  </h6>
                ) : (
                  <h6
                    className="language lang-patient-change mt-3  pointer"
                    onClick={() => changeAppLangauge("ar")}
                  >
                    {" "}
                    العربية{" "}
                  </h6>
                )}
              </div>
            ) : null}
          </div>
          {navigation_links.map((link, index) => {
            if (permissions.includes(link.key)) {
              return (
                <Link
                  to={
                    userDetails?.doctor?.doctorId
                      ? link.slug +
                        `?docotorIdentifier=${userDetails?.doctor?.identifier}&doctor=${userDetails?.doctor?.doctorId}`
                      : link.slug
                  }
                  key={index}
                  className={
                    "nav-link-with-badge " + displayType + "-nav-" + link.key
                  }
                >
                  {" "}
                  <Button
                    className="text-left text-uppercase navigation-btn py-1"
                    variant="link"
                  >
                    {link.title}
                  </Button>
                  {/* {link.badge && badgeForDashboard && badgeForReferrals && badgeForAppointments ?  */}
                  {link.badge ? <Badge badge={link.badge} /> : null}
                </Link>
              );
            } else {
              return null;
            }
          })}
        </div>
        {/* <Link to={userDetails?.doctor?.doctorId? '/appointments_ii'+`?docotorIdentifier=${userDetails?.doctor?.identifier}&doctor=${userDetails?.doctor?.doctorId}` :'/appointments_ii' }  className={'nav-link-with-badge ' + displayType+'-nav-appointment'}>
                  <Button className="text-left text-uppercase navigation-btn py-1" variant="link">
                    TEST Calendar
                  </Button>
        </Link> */}
        <LogoutButton id="logout-btn-test" />
      </>
    );
  };

  const changeAppLangauge = (lang) => {
    // setLangChangeLoader(true);
    setTimeout(() => {
      i18n.changeLanguage(lang);
      if (lang === "ar") {
        document.body.lang = "ar";
        document.body.dir = "rtl";
      } else {
        document.body.lang = "en";
        document.body.dir = "ltr";
      }
      localStorage.setItem("appLanguage", lang);
      appLanguageVar(lang);
      setTimeout(() => {
        window.location = window.location.href;
      }, 500);
    }, 200);
  };

  return (
    <div className="appNavigation h100 d-flex flex-column">
      <div className="mobile_nav w100 justify-content-between align-items-center">
        <span onClick={() => toggleNav(true)} className="flex1">
          <i className={"fa fa-bars menu_icon "} aria-hidden="true"></i>{" "}
        </span>
        {/* <Link to="/dashboard" className="d-flex justify-content-center flex1">
          <img alt={'Logo'} className="img-fluid navLogo" src={Logo} />
        </Link> */}
        <h6 className="today_date mb-0 flex1 nav-date">{dateWithDay()}</h6>
        <div
          id="nav_overlay"
          onClick={() => toggleNav()}
          className=" d-none"
        ></div>
      </div>
      <Row className={"flex4 user_details"}>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            {!isStaff ? (
              <div>
                {appLanguage === "ar" ? (
                  <h6
                    className="language lang-patient-change mt-3 text-right pointer"
                    onClick={() => changeAppLangauge("en")}
                  >
                    {" "}
                    English{" "}
                  </h6>
                ) : (
                  <h6
                    className="language lang-patient-change mt-3 text-right  pointer"
                    onClick={() => changeAppLangauge("ar")}
                  >
                    {" "}
                    العربية{" "}
                  </h6>
                )}
              </div>
            ) : null}
            {appLanguage === "ar" ? (
              <h6 className="today_date mt-3 text-left">{dateWithDay()}</h6>
            ) : (
              <h6 className="today_date mt-3 text-right">{dateWithDay()}</h6>
            )}
          </div>
          <div className="text-light mt-2 hide-sm text-center truncate dir-ltr">
            <EllipsisWithTooltip placement="bottom">
              {userDetails && userDetails.email.length > 22
                ? `${userDetails.email.slice(0, 22)}...`
                : userDetails.email || "User"}
            </EllipsisWithTooltip>
          </div>
          <div
            className={
              (accountDetails &&
                accountDetails.me &&
                accountDetails.me.patient &&
                !accountDetails.me.patient.profileComplete) ||
              onBoardingToFill
                ? " pointerEventsNone "
                : ""
            }
          >
            <Link
              to={
                props.isPatientPortal
                  ? "/patientPortal/dashboard"
                  : "/dashboard"
              }
            >
              <img alt={"Logo"} className="img-fluid navLogo" src={Logo} />
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="flex6 mt-3 tabs">{renderNavContents("lg")}</Row>
      <div
        id="mySidenav"
        className={(isStaff ? "" : " patient_nav ") + " sidenav "}
      >
        <div className="closebtn" onClick={() => toggleNav()}>
          <i className={"fa fa-times "} aria-hidden="true"></i>{" "}
        </div>
        {renderNavContents("sm")}
      </div>
    </div>
  );
};

export default withRouter(Navigation);
